import { Input, InputLabel } from "@material-ui/core";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
	${({ theme }) => css`
		height: auto;
	`}
`;

export const Error = styled.div`
	${({ theme }) => css`
		color: red;
	`}
`;

export const Content = styled.div`
	${({ theme }) => css`
		height: auto;
		padding-top: 20px;
		margin-bottom: 50px;
		z-index: 2999;

		.button-open-filter {
			width: 100%;
			align-self: center;
			background-color: ${theme.palette.primary.main};
			color: ${theme.palette.secondary.main};
			text-transform: capitalize;
			position: fixed;
			z-index: 999;
			bottom: 0px;
			right: 0px;
			left: 0px;
		}
	`}
`;

export const LoadText = styled.span`
	${({ theme }) => css`
		font-size: 28px;
		font-weight: 800;
		color: #545454;
		height: 60px;
		display: flex;
		align-items: center;
	`}
`;

export const Container = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const NotFoundTrip = styled.div`
	${({ theme }) => css`
		margin-top: 50px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 24px;
		font-weight: 600;
		color: #545454;
		flex-direction: column;

		@media (max-width: 768px) {
			min-height: calc(100vh - 450px);
			font-size: 18px;
			margin-top: 15px;
		}

		.pesquisa {
			margin-top: 50px;
			height: 50px;
			text-decoration: none;
		}
	`}
`;

export const NotFoundInputTrip = styled(Input)(() => ({
	backgroundColor: "#FFFFFF",
	border: "0.5px solid",
	borderColor: "#828282",
	borderRadius: "10px",
	fontSize: "15px",
	fontWeight: "400",
	width: "auto",
	padding: "3px 10px",
	marginTop: "26px !important",
}));

export const NotFoundInputLabelTrip = styled(InputLabel)(({ theme }) => ({
	fontSize: "20px",
	color: "#5A5A5A",
	fontWeight: "700",
	animation: "none",

	[theme.breakpoints.down("sm")]: {
		fontSize: "16px",
		color: "#5A5A5A",
	},
}));

export const NotFoundContainerFormTrip = styled(Container)(({ theme }) => ({
	width: "100%",
	justifyContent: "space-evenly",
	alignItems: "center",
	display: "flex",
	flexDirection: "row",

	[theme.breakpoints.down("sm")]: {
		marginTop: "10px !important",
		flexDirection: "column",
	},
}));
