import styled from 'styled-components';
import { TextField, Typography as MUITypography } from '@material-ui/core';

export const Modal = styled.div`
  width: auto;

  .inputChilds {
    margin-bottom: 10px;
  }
`;

export const InputChilds = styled(TextField)`
  margin-bottom: 16px;
`;

export const Typography = styled(MUITypography)`
  margin: 16px;
`;
