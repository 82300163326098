import React from 'react';
import Elo from '../../assets/img/elo-logo.png';
import Amex from '../../assets/img/logo-amex.png';
import Master from '../../assets/img/mastercard-logo.png';
import Visa from '../../assets/img/visa-logo.png';

import * as S from './styles';

function CardFlags() {
  return (
    <S.Wrapper>
      <div className='brandeiras-aceitas'>
        <span>Bandeiras aceitas</span>
        <div>
          <img className='visa' alt='brandeiras-aceitas' src={Visa} />
          <img className='master' alt='brandeiras-aceitas' src={Master} />

          <img className='elo' alt='brandeiras-aceitas' src={Elo} />
          <img className='amex' alt='brandeiras-aceitas' src={Amex} />
        </div>
      </div>
    </S.Wrapper>
  );
}

export default CardFlags;
