import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { Dialog as MUIDialog } from '@material-ui/core';

export const Dialog = styled(MUIDialog)`
  ${({ theme }) => css`
    background-color: ${(props) =>
      props.type === 'login' || props.type === 'expired'
        ? 'white'
        : rgba('#000', 0.5)} !important;

    .MuiBackdrop-root {
      background-color: ${(props) =>
        props.type === 'login' || props.type === 'expired'
          ? 'white'
          : rgba('#000', 0.5)} !important;
    }

    .MuiDialog-paper {
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;
    }
    div {
      display: flex;
      justify-content: center;

      img {
        width: 120px;
        margin-bottom: 15px;
      }
    }


    .MuiLinearProgress-root {
      background-color: ${rgba('#fff', 0.7)};
      border-radius: 2px;

      .MuiLinearProgress-bar {
        background-color: ${theme.palette.primary.main};
      }
    }
  `}
`;
