/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import * as S from './styles';

import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';

import ThemeButton from '../../ThemeButton';
import { useHistory } from 'react-router';
import { useGlobal } from '../../../contexts/globalContext';
import { addFeeByDestinoFerias } from '../../../utils/addFeeByDestinoFerias';

const HotelMap = ({ filters, searchState, stays, loading, buscaAtual }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCHf7H8HE4xGv3wRS8vF5om3cZaa7d9_Vc', // Add your API key
  });

  const { signed, user } = useGlobal();

  const [staysFiltered, setStaysFiltered] = useState([]);
  const [coinAlias, setCoinAlias] = useState('Pontos');
		const [shortenCoinAlias, setShortenCoinAlias] = useState('Pts');

		let history = useHistory();

		const filterByHotelName = (stays) => {
			return filters?.hotelName
				? stays?.filter((s) =>
						s.basicPropertyInfo?.hotelRef?.hotelName
							?.toLowerCase()
							.includes(filters.hotelName.toLowerCase())
				  )
				: stays;
		};
		const filterByStars = (stays) => {
			return filters?.stars.length > 0
				? stays?.filter(
						(s) => filters.stars.indexOf(s.basicPropertyInfo?.award?.rating) !== -1
				  )
				: stays;
		};
		const filterByDailyPrice = (stays) => {
			return filters?.dailyPrice
				? stays?.filter(
						(s) =>
							s.price.amountAfterTax >= filters?.dailyPrice[0] &&
							s.price.amountAfterTax <= filters?.dailyPrice[1]
				  )
				: stays;
		};
		const returnArrayOfValuesFilteredTrue = (field) => {
			return stays?.filter((stay) =>
				stay.roomStays.roomTypes.find((roomType) =>
					roomType.roomRates.find(
						(roomRate) => roomRate.ratePlans?.mealsIncluded?.[field] === true
					)
				)
			);
		};
		const returnArrayOfValuesFilteredFalse = (field) => {
			return stays?.filter((stay) =>
				stay.roomStays.roomTypes.find((roomType) =>
					roomType.roomRates.find(
						(roomRate) => roomRate.ratePlans?.mealsIncluded?.[field] === false
					)
				)
			);
		};
		const mealsIncludedFilterBreakfast = (stays) => {
			return filters?.breakfast
				? returnArrayOfValuesFilteredTrue('breakfast')
				: stays;
		};
		const mealsIncludedFilterLunch = (stays) => {
			return filters?.lunch ? returnArrayOfValuesFilteredTrue('lunch') : stays;
		};
		const mealsIncludedFilterDinner = (stays) => {
			return filters?.dinner ? returnArrayOfValuesFilteredTrue('dinner') : stays;
		};
		const noBreakfast = (stays) => {
			return filters?.noBreakfast
				? returnArrayOfValuesFilteredFalse('breakfast')
				: stays;
		};
		useEffect(() => {
			setStaysFiltered(stays);
		}, [stays]);
		useEffect(() => {
			setStaysFiltered(
				filterByDailyPrice(
					noBreakfast(
						mealsIncludedFilterLunch(
							mealsIncludedFilterDinner(
								mealsIncludedFilterBreakfast(filterByStars(filterByHotelName(stays)))
							)
						)
					)
				)
			);
		}, [filters]);

		const getValuesByUser = (val) => {
			let value = signed
				? val * user.catalog.catalogCurrencyConfig.conversionRate
				: val;

			if (user.campaign) value = addFeeByDestinoFerias(value, buscaAtual);

			return signed ? Math.ceil(Math.round(value)) : value;
		};

		const markersss = staysFiltered
			? staysFiltered.map((hotel, index) => ({
					id: index,
					name: hotel.basicPropertyInfo.hotelRef.hotelName,
					position: {
						lat: Number(hotel.basicPropertyInfo.position.latitude),
						lng: Number(hotel.basicPropertyInfo.position.longitude),
					},
					value: getValuesByUser(hotel.price.amountAfterTax),
					hotel: hotel,
			  }))
			: [];

		const [activeMarker, setActiveMarker] = useState(null);

		const handleActiveMarker = (marker) => {
			if (marker === activeMarker) {
				return;
			}
			setActiveMarker(marker);
		};

		const handleOnLoad = (map) => {
			const bounds = new window.google.maps.LatLngBounds();
			markersss.forEach(({ position }) => bounds.extend(position));
			map.fitBounds(bounds);
		};

		useEffect(() => {
			setCoinAlias(user?.campaign?.campaignConfig?.coinAlias || 'Pontos');
			setShortenCoinAlias(
				user?.campaign?.campaignConfig?.shortenCoinAlias || 'Pts'
			);
		}, [
			user?.campaign?.campaignConfig?.coinAlias,
			user?.campaign?.campaignConfig?.shortenCoinAlias,
		]);

		return (
			<S.Wrapper>
				<Grid container>
					<Grid item xs={12} md={12} lg={10}>
						{isLoaded && markersss.length > 0 ? (
							<GoogleMap
								onLoad={handleOnLoad}
								onClick={() => setActiveMarker(null)}
								mapContainerStyle={{ width: '100%', height: '800px' }}
							>
								{markersss.map(({ id, name, position, value, hotel }) => (
									<Marker
										key={id}
										icon={{ url: '#' }}
										label={{
											text: `${Math.ceil(Math.round(value))} ${shortenCoinAlias}`,
											color: 'white',
											className: 'marker-label',
										}}
										position={position}
										onClick={() => handleActiveMarker(id)}
									>
										{activeMarker === id ? (
											<InfoWindow onCloseClick={() => setActiveMarker(null)}>
												<S.RenderedMarker>
													<span className='bolder'>{name}</span>
													<span>{hotel.basicPropertyInfo.address.addressLine}</span>
													<span className='bolder'>
														{Math.ceil(Math.round(value))} {coinAlias}
													</span>

													<ThemeButton
														onClick={() => {
															sessionStorage.setItem('@hotelSelected', JSON.stringify(hotel));
															history.push('/busca/hotel-details');
														}}
														className='button-select'
													>
														Selecionar
													</ThemeButton>
												</S.RenderedMarker>
											</InfoWindow>
										) : null}
									</Marker>
								))}
							</GoogleMap>
						) : null}
					</Grid>
				</Grid>
			</S.Wrapper>
		);
};

export default HotelMap;
