import api from '../services/api';
import getHeaders from '../utils/returnHeaders';

const getFlights = async (data) => {
  const header = getHeaders();

  return api.post(`/flight`, JSON.stringify(data), header);
};

export default getFlights;
