import { rgba } from "polished";
import styled, { css } from "styled-components";

export const BlankCard = styled.div`
  ${({ theme }) => css`
    height: auto;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    padding: 12px;
    padding-top: 9px;
    padding-bottom: 27px;
    margin-bottom: 40px;

    .title {
      padding-bottom: 8px;
      padding-left: 5px;
      border-bottom: 1px solid ${rgba("#707070", 0.21)};
      margin-bottom: 20px;
    }

    .title2 {
      margin-bottom: 10px;
    }
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    font: normal normal bold 15px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-size: 16px;
    height: 24px;

    .subtitle {
      display: flex;
      justify-content: center;
      margin-left: 20px;
      font-size: 12px;
      align-items: center;
    }

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
    }

    img {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }
  `}
`;

export const BusSummaryCardDetails = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    .bus-infos {
      display: flex;

      ${theme.breakpoints.down("sm")} {
        width: 100%;
      }
      .bus-step {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        ${theme.breakpoints.down("sm")} {
          flex-wrap: wrap;
          width: 100%;
        }

        .depart-hour {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;
          max-width: 220px;

          .plus-day {
            color: red;
            font-size: 18px;
          }

          ${theme.breakpoints.down("sm")} {
            justify-content: space-between;
            max-width: unset;
            width: 100%;
          }

          span {
            font-size: 23px;
            font-weight: 200;
            margin: 0 10px;
            display: flex;
            width: 100%;

            ${theme.breakpoints.down("xs")} {
              font-size: 15px;
              margin: 0;
            }
          }

          .stations {
            font-family: "Poppins";
            font-size: 15px;
            letter-spacing: 0;
            font-weight: 400;
            word-wrap: normal;

            ${theme.breakpoints.down("xs")} {
              font-size: 12px;
            }
          }
        }
      }

      .company {
        padding: 0 7px;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: "Poppins";
        font-size: 10px;

        img {
          margin-bottom: 5px;
          background-color: #ededed;
          border-radius: 50px;
          width: 30px;
          height: 30px;
          object-fit: contain;
          padding: 3px;
        }
      }
    }

    .bus-details {
      border-left: 1px solid ${rgba("#707070", 0.21)};
      font-family: "Poppins";
      margin-right: 30px;
      font-size: 11px;
      padding: 7px;
      padding-left: 25px;

      ${theme.breakpoints.down("sm")} {
        width: 50%;
        border-left: none;
        margin-top: 20px;
      }

      ${theme.breakpoints.down("xs")} {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-left: 7px;
        flex-wrap: wrap;
        margin-right: 0;
      }

      .duration {
        white-space: nowrap;
        align-items: center;
        display: flex;

        .label {
          opacity: 0.37;
          margin-right: 5px;
        }

        ${theme.breakpoints.down("xs")} {
          margin-top: 10px;
          width: 100%;
          justify-content: center;
          margin-bottom: 15px;
        }
      }
      .facilities {
        padding-top: 10px;
        display: flex;
        width: 150px;
        justify-content: space-between;
        white-space: nowrap;
        font-size: 10px;

        ${theme.breakpoints.down("xs")} {
          padding-top: 0;
        }

        img {
          width: 13px;
        }

        span {
          padding-left: 5px;
          text-align: center;
        }
      }
    }

    .alterar {
      border-left: 1px solid ${rgba("#707070", 0.21)};
      font-family: "Poppins";
      margin-right: 30px;
      font-size: 11px;
      padding: 7px;
      padding-left: 30px;
      color: #159ce1;
      display: flex;
      align-items: center;
      text-decoration: none;

      ${theme.breakpoints.down("md")} {
        border-left: none;
        justify-content: center;
        margin-top: 20px;
        margin-right: 0px;

        width: 100%;
        &:hover {
          background-color: #f3f3f3;
        }
      }

      ${theme.breakpoints.down("sm")} {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-right: 0px;
      }

      ${theme.breakpoints.down("xs")} {
        width: 100%;
      }
    }
  `}
`;

export const BusSummaryCardCategory = styled.div`
  display: flex;
  padding-bottom: 8px;
  padding-left: 5px;
  border-bottom: 1px solid ${rgba("#707070", 0.21)};
  margin-bottom: 20px;
  font-family: "Poppins";

  img {
    width: 23px;
    margin-right: 15px;
  }

  .category {
    width: 100%;
    font-size: 10px;
  }

  .category-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .category-details {
    color: #2271cf;
    display: flex;
    justify-content: left;
    cursor: pointer;

    span {
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
`;
