/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import { Grid, Typography } from '@material-ui/core';

import * as S from './styles';
import VitrineRepository from '../../../repositories/vitrineRepository';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import cleanStorageBeforeSearch from '../../../utils/cleanStorageBeforeSearch';
import { useHistory } from 'react-router';
import { addDays, addMonths, format } from 'date-fns';
import { pt } from 'react-date-range/dist/locale';

// import { Skeleton } from '@material-ui/lab';

const MaisBuscados = ({ user }) => {
  const vitrineRepository = new VitrineRepository();

  let history = useHistory();

  const [loading, setLoading] = useState(false);

  const [images, setImages] = useState([]);

  const [maisBuscados, setMaisBuscados] = useState([]);
  const [maisBuscadosUsuario, setMaisBuscadosUsuario] = useState([]);
  const [userDetails, setUserDetails] = useState();

  useEffect(() => {
    setLoading(true);
    getMaisBuscados();
  }, []);

  useEffect(() => {
    getMaisBuscadosUsuario();
  }, [userDetails]);

  useEffect(() => {
    setUserDetails(user);
  }, [user]);

  useEffect(() => {
    maisBuscados.map((m) => {
      getImageByIata(m[0]);
    });
    setLoading(false);
  }, [maisBuscados]);

  const getMaisBuscados = async () => {
    const mb = await vitrineRepository.getMoreSearched();
    setMaisBuscados(mb);
  };

  const getMaisBuscadosUsuario = async () => {
    const mbu = await vitrineRepository.getMoreSearchedByUser(userDetails);
    setMaisBuscadosUsuario(mbu);
  };

  const getImageByIata = async (iata) => {
    vitrineRepository
      .getStorageImages(iata)
      .then((res) =>
        setImages((oldState) => [...oldState, { iata, url: res }])
      );
  };

  const setDatas = (datas) => {
    let weekDayStartDate = format(datas.startDate, 'EEEE dd MMMM yyyy', {
      locale: pt,
    });
    let weekDayEndDate = format(datas.endDate, 'EEEE dd MMMM yyyy', {
      locale: pt,
    });
    let startDate = datas.startDate;
    let endDate = datas.endDate;
    let dataIda = format(datas.startDate, 'dd/MM/yyyy');
    let dataVolta = format(datas.endDate, 'dd/MM/yyyy');
    let abreviada = `${format(datas.startDate, 'dd/MM')} - ${format(
      datas.endDate,
      'dd/MM'
    )}`;

    return {
      dataIda,
      dataVolta,
      abreviada,
      weekDayStartDate,
      weekDayEndDate,
      startDate,
      endDate,
    };
  };

  const initialDate = addMonths(new Date(), 1);
  const finalDate = addDays(initialDate, 7);

  return (
    <S.Wrapper>
      <Typography variant='h5'>
        <b>Destinos mais procurados</b>
      </Typography>

      {!loading ? (
        <Grid container spacing={2}>
          {maisBuscados.map((mb) => {
            const data = mb[1][0];
            return (
              <Grid item xs={12} sm={3}>
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    cleanStorageBeforeSearch();
                    sessionStorage.setItem(
                      'flySearch',
                      JSON.stringify({
                        origem: {
                          countryName:
                            maisBuscadosUsuario?.origin?.omnCountryName,
                          countryIso: maisBuscadosUsuario?.origin?.country,
                          cityName: maisBuscadosUsuario?.origin?.city,
                          stateName: maisBuscadosUsuario?.origin?.omnStateName,
                          iata: maisBuscadosUsuario.origin.iata,
                          airportName: maisBuscadosUsuario?.origin?.name,
                          description: `${maisBuscadosUsuario?.origin?.name} (${maisBuscadosUsuario.origin.iata})`,
                        },
                        destino: {
                          countryName: data?.destination?.omnCountryName,
                          countryIso: data?.destination?.country,
                          cityName: data?.destination?.city,
                          stateName: data?.destination?.omnStateName,
                          iata: mb[0],
                          airportName: data?.destination?.name,
                          description: `${data?.destination?.name} (${mb[0]})`,
                        },
                        datas: setDatas({
                          startDate: initialDate,
                          endDate: finalDate,
                        }),
                        adultos: 1,
                        criancas: 0,
                        bebes: 0,
                        idavolta: true,
                        tarifasBagagem: false,
                        apenasVoosDiretos: false,
                      })
                    );
                    sessionStorage.setItem('buscaAtual', 'Aéreo');
                    history.push('/busca/resultado-pesquisa');
                  }}
                  alt={`Foto de ${mb[0]}`}
                  src={images.find((x) => x.iata === mb[0])?.url}
                />
                <strong>
                  {!data?.destination?.city
                    ? ''
                    : data?.destination?.city?.toUpperCase()}
                </strong>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {Array(4).map((a) => (
            <Grid item xs={12} sm={3}>
              <Skeleton variant='rect' width={'100%'} height={200} />
            </Grid>
          ))}
        </Grid>
      )}

      {/* {campaignId && (
        <>
          {images.length === 3 && (
            <Typography variant='h6'>Experiências em Destaque</Typography>
          )}

          {loading && <Skeleton variant='rect' width={400} height={30} />}
          <div>
            <Grid container spacing={2}>
              {images.length === 3 &&
                images
                  .sort((a, b) => a.name - b.name)
                  .map((img, index) => {
                    return (
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <img
                          // className={`index-${img.name}`}
                          src={img.url}
                          alt='Publicidade'
                        />
                      </Grid>
                    );
                  })}
              {loading && (
                <>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Skeleton variant='rect' width={'100%'} height={200} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Skeleton variant='rect' width={'100%'} height={200} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Skeleton variant='rect' width={'100%'} height={200} />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </>
      )} */}
    </S.Wrapper>
  );
};

export default MaisBuscados;
