import React from 'react';
import * as S from './styles';

import Passengers from '../../../assets/icons/passengers.svg';
import CarDoors from '../../../assets/icons/carDoors.svg';
import CarBaggage from '../../../assets/icons/carBaggages.svg';
import CarGear from '../../../assets/icons/carGear.svg';
import CarAc from '../../../assets/icons/carAC.svg';
import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';

// const CarSummaryCard = ({ car }) => {
const CarSummaryCard = ({ car, searchState }) => {
  return (
    <div>
      {car && (
        <S.Wrapper>
          <S.CardContent container>
            <div className='title title2'>
              <S.Title>
                Periodo
                <span className='subtitle'>
                  {car.pickupDateTime !== undefined &&
                    car.pickupDateTime !== 'Invalid Date' &&
                    format(parseISO(car.pickupDateTime), 'dd/MM/yyyy', {
                      locale: pt,
                    })}
                  {` à `}
                  {car.dropoffDateTime !== undefined &&
                    car.dropoffDateTime !== 'Invalid Date' &&
                    format(parseISO(car.dropoffDateTime), 'dd/MM/yyyy', {
                      locale: pt,
                    })}
                </span>
              </S.Title>
            </div>
            <S.CarDetails item xs={12}>
              <img
                className='car-picture'
                src={car.urlCarImg}
                alt='Foto do carro'
              />
              <div className='car-details1'>
                <img
                  className='company-logo'
                  src={car.urlLogoCompany}
                  alt='Locadora logotipo'
                />
                <span className='car-type'> {car.categoryName}</span>
                <span className='car-model'>{car.model}</span>
              </div>
              <div className='car-details2'>
                <div>
                  <div>
                    {car.capacity && (
                      <>
                        <img src={Passengers} alt='Passageiros' />
                        <span>{car.capacity}</span>
                      </>
                    )}
                  </div>
                  <div>
                    {car.numberDoors && (
                      <>
                        <img src={CarDoors} alt='Numero de portas' />
                        <span>{car.numberDoors}</span>
                      </>
                    )}
                  </div>
                  <div>
                    {car && (
                      <>
                        <img src={CarBaggage} alt='Numero de malas' />
                        <span>{'???'}</span>
                      </>
                    )}
                  </div>

                  <div>
                    <>
                      <img src={CarGear} alt='Cambio' />
                      <span>{car.automaticTransmission ? 'Auto' : 'Man'}</span>
                    </>
                  </div>

                  {car.airConditioning && (
                    <div>
                      <>
                        <img src={CarAc} alt='Ar condicionado' />
                        <span>AC</span>
                      </>
                    </div>
                  )}
                </div>
              </div>
            </S.CarDetails>
            <S.BottomDetails item xs={12}>
              <div className='place-details'>
                <div>
                  <span className='hour'>
                    Retirada {format(parseISO(car.pickupDateTime), 'HH:mm')}
                  </span>
                  <span className='place'>{car.pickupAddress}</span>
                </div>
                <div>
                  <span className='hour'>
                    Devolução {format(parseISO(car.dropoffDateTime), 'HH:mm')}
                  </span>
                  <span className='place'>
                    {searchState.mesmoLocal
                      ? car.pickupAddress
                      : car.dropoffAddress}
                  </span>
                </div>
              </div>
              <div className='reservation-details'>
                <div>
                  {/* <span>{car.km === 0 ? 'Km Ilimitado' : car.km + ' Kms'}</span>
                <span>{car.freeCancellation && 'Cancelamento grátis'}</span> */}
                </div>
              </div>
            </S.BottomDetails>
          </S.CardContent>
        </S.Wrapper>
      )}
    </div>
  );
};

export default CarSummaryCard;
