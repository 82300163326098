import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;

    &.select-option,
    &.baggage {
      align-items: center;
      justify-content: center;
    }

    span {
      width: 100%;
      text-align: center;
    }

    .baggage {
      display: flex;
      justify-content: center;
      width: 100%;

      svg {
        transform: scale(0.5);
      }

      .hand-baggage {
        width: 17px;
        margin: 0px !important;
      }

      .other-baggage {
        padding-left: 7px;
        width: 19px;
        margin: 0px !important;
      }
    }
  `}
`;

export const BaggageType = styled.span`
  font-size: 9px;
`;
