import { Grid } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Wrapper = styled.footer`
  ${({ theme }) => css`
    padding-top: 50px;
    width: 100%;
    height: 500px;
    background-color: #263238;
    font-family: 'Roboto';
  `}
`;

export const GridCentralized = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 35px;
`;

export const FooterTitle = styled.div`
  font-family: 'Roboto';
  display: flex;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  height: 17px;
  opacity: 90%;
`;

export const FooterList = styled.div`
  font-family: 'Poppins';
  margin-top: 0;
  /* margin-bottom: 12px; */
  font-size: 16px;
  color: #fff !important;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  span,
  a,
  svg {
    text-decoration: none;
    /* width: 100%; */
    opacity: 80%;
    color: #fff !important;
    :hover {
      opacity: 1;
      cursor: pointer;
    }
  }
  svg {
    width: unset;
    margin-right: 15px;
  }
`;
export const CompanyName = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 10px;
  opacity: 0.7;
  font-family: 'Poppins';
`;
