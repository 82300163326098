import React, { useState } from 'react';

import * as S from './styles';

import { withRouter } from 'react-router-dom';
import Gif from '../../assets/img/loader.gif';
import { Typography } from '@material-ui/core';

function SessionExpiration() {
  const [segundos, setSegundos] = useState(5);
  sessionStorage.clear();
  localStorage.clear();

  setInterval(() => {
    if (segundos <= 0) {
      window.location.href = process.env.REACT_APP_PATH;
    }
    setSegundos(segundos - 1);
  }, 1000);
  return (
    <S.Wrapper>
      <img src={Gif} alt='Compra concluida' />
      <br />
      <br />
      <Typography variant='h3'>Sua sessão expirou</Typography>
      <br />
      <br />
      <Typography variant='h6'>
        Você será redirecionado ao site de origem em {segundos} segundos{' '}
      </Typography>
    </S.Wrapper>
  );
}

export default withRouter(SessionExpiration);
