import api from '../services/api';
import getHeaders from '../utils/returnHeaders';

const getExperiences = async (cityId, startDate, endDate) => {
  const header = getHeaders();
  return api.get(
    `/experience?cityId=${cityId}&startDate=${startDate}&endDate=${endDate}`,
    header
  );
};

export { getExperiences };
