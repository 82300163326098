import React from 'react';
import * as S from './styles';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import {
  parseISO,
  format,
  differenceInCalendarDays,
} from 'date-fns';
import getCompanyLogos from '../../../../mock/companyLogos';
import FlightStep from '../FlightStep/';
import { pt } from 'date-fns/locale';
import { calcDuration } from '../../../SearchContent/FlightList/FlightCard/FlightSegmentOption';

const FlightSegmentOption = ({
  flight,
  sourceid,
  validatingcarrier,
  sourcecode,
}) => {
  const theme = useTheme();
  const downOfXs = useMediaQuery(theme.breakpoints.down('xs'));

  const diffDays = differenceInCalendarDays(
    new Date(flight?.ArrivalDateTime),
    new Date(flight?.DepartureDateTime)
  );

  return (
    <S.FlightSegmentOption
      className={`${flight.Flights.length > 1 ? 'not-unique' : ''}`}
    >
      {downOfXs ? (
        <Grid container>
          <S.GridItemFlex item xs={12}>
            {flight.DepartureDateTime !== undefined &&
              flight.DepartureDateTime !== 'Invalid Date' &&
              format(parseISO(flight.DepartureDateTime), 'dd MMMM', {
                locale: pt,
              })}
          </S.GridItemFlex>
          <S.GridItemFlex item xs={2}>
            <div className='company-logo'>
              <img src={getCompanyLogos(validatingcarrier)} alt='cia' />
            </div>
            <span className='company-name'>{sourcecode && sourcecode}</span>
            <span className='flight-number'>{`${validatingcarrier} ${flight.Flights[0].FlightNumber}`}</span>
          </S.GridItemFlex>
          <S.GridItemFlex item xs={2}>
            <span className='departure-date'>
              {format(parseISO(flight.DepartureDateTime), 'HH:mm')}
            </span>
          </S.GridItemFlex>
          <S.GridItemFlex item xs={6}>
            <FlightStep steps={flight.Flights} />
          </S.GridItemFlex>
          <S.GridItemFlex className='select-option' item xs={2}>
            <span className='arrival-date'>
              {format(parseISO(flight.ArrivalDateTime), 'HH:mm')}
              <span className='plus-day'>
                {diffDays > 0 ? `+${diffDays}` : ''}
              </span>
            </span>
          </S.GridItemFlex>
        </Grid>
      ) : (
        <Grid container>
          <S.GridItemFlex item xs={12}>
            {flight.DepartureDateTime !== undefined &&
              flight.DepartureDateTime !== 'Invalid Date' &&
              format(parseISO(flight.DepartureDateTime), 'dd MMMM', {
                locale: pt,
              })}
          </S.GridItemFlex>
          <S.GridItemFlex item lg={1} md={1} sm={1} xs={1}>
            <div className='company-logo'>
              <img src={getCompanyLogos(validatingcarrier)} alt='cia' />
            </div>
          </S.GridItemFlex>
          <S.GridItemFlex item lg={1} md={1} sm={1} xs={1}>
            <span className='company-name'> {sourcecode && sourcecode}</span>
            <span className='flight-number'>{`${validatingcarrier} ${flight.Flights[0].FlightNumber}`}</span>
          </S.GridItemFlex>
          <S.GridItemFlex item lg={2} md={2} sm={2} xs={2}>
            <span className='departure-date'>
              {format(parseISO(flight.DepartureDateTime), 'HH:mm')}
            </span>
          </S.GridItemFlex>
          <S.GridItemFlex item lg={4} md={4} sm={4} xs={4}>
            <span className='duracao'>
            {calcDuration(flight.totalDuration)}
            </span>
            <FlightStep steps={flight.Flights} />
          </S.GridItemFlex>
          <S.GridItemFlex item lg={2} md={2} sm={2} xs={2}>
            <span className='arrival-date'>
              {format(parseISO(flight.ArrivalDateTime), 'HH:mm')}
              <span className='plus-day'>
                {diffDays > 0 ? `+${diffDays}` : ''}
              </span>
            </span>
          </S.GridItemFlex>
        </Grid>
      )}
    </S.FlightSegmentOption>
  );
};

export default FlightSegmentOption;