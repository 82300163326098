import { Grid } from '@material-ui/core';
import React from 'react';
import ExperienceCard from './ExperienceCard';
import * as S from './styles';

const ExperienceList = ({
  filters,
  searchState,
  experiences,
  loading,
  buscaAtual,
}) => {
  return (
    <div>
      <S.Wrapper>
        <Grid container>
          <Grid item xs={12} md={12} lg={10}>
            <Grid container>
              {experiences &&
                experiences.length > 0 &&
                experiences.map((exp, index) => (
                  <Grid item xs={12} key={index}>
                    <ExperienceCard
                      experience={exp}
                      title={exp.name}
                      description={exp.description}
                      valueDiscounted={exp.originalPrice}
                      value={exp.price}
                      id={exp.id}
                      buscaAtual={buscaAtual}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </S.Wrapper>
    </div>
  );
};

export default ExperienceList;
