import Seat from '../../../assets/icons/seat.svg';
// import Share from '../../../assets/icons/Share.svg';
// import Wifi from '../../../assets/icons/Wifi.svg';
// import Video from '../../../assets/icons/Video.svg';
// import AirplaneModelIcon from '../../../assets/icons/AirplaneModelIcon.svg';
import { Link } from 'react-router-dom';

import * as S from './styles';
import {
  addMilliseconds,
  differenceInCalendarDays,
  format,
  parseISO,
} from 'date-fns';
import { pt } from 'date-fns/locale';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';

import AirportsRepository from '../../../repositories/airportsRepository';
import FareDetails from '../../FareDetails';
import FlightStep from './FlightStep';
import Baggage from '../../Baggage';

const FlightSummaryCard = ({
  title,
  category,
  flightCode,
  departureDate,
  arrivalDate,
  companyLogo,
  departureAirport,
  arrivalAirport,
  fareFamily,
  company,
  steps,
  baggage,
}) => {
  const theme = useTheme();
  const downOfSm = useMediaQuery(theme.breakpoints.down('sm'));

  const airportsRepository = new AirportsRepository();

  const [departureName, setDepartureName] = useState();
  const [arrivalName, setArrivalName] = useState();

  const [verTarifa, setVerTarifa] = useState(false);

  const openTarifaDetails = (open) => setVerTarifa(open);

  useEffect(() => {
    departureAirport &&
      airportsRepository
        .getAirportsByIata(departureAirport)
        .then((snapshot) => {
          const res = snapshot.docs.map((i) => i.data());
          setDepartureName(`${departureAirport} - ${res[0]?.name}`);
        });

    arrivalAirport &&
      airportsRepository.getAirportsByIata(arrivalAirport).then((snapshot) => {
        const res = snapshot.docs.map((i) => i.data());
        setArrivalName(`${arrivalAirport} - ${res[0]?.name}`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrivalAirport, departureAirport]);

  const diffDays = differenceInCalendarDays(
    new Date(arrivalDate),
    new Date(departureDate)
  );

  return (
    <>
      <FareDetails
        open={verTarifa}
        cia={company}
        onClose={openTarifaDetails}
        farecode={fareFamily?.farefamily}
      />
      <S.BlankCard>
        <div className='title title2'>
          <S.Title>
            {title}
            <span className='subtitle'>
              {departureDate !== undefined &&
                departureDate !== 'Invalid Date' &&
                format(parseISO(departureDate), 'EEEE dd MMMM yyyy', {
                  locale: pt,
                })}
            </span>
          </S.Title>
        </div>

        <S.FlightSummaryCardCategory>
          <img className='' src={Seat} alt='icon' />
          <div className='category-container'>
            <span className='category'>Categoria: {category.name}</span>
          </div>
          <Baggage baggage={baggage} />
        </S.FlightSummaryCardCategory>
        <S.FlightSummaryCardDetails>
          <div className='flight-infos'>
            <div className='company'>
              <img src={companyLogo} alt='company-logo' />
              <span>{flightCode}</span>
            </div>
            <div className='flight-step'>
              <div className='depart-hour'>
                <span>
                  {departureDate !== undefined &&
                    departureDate !== 'Invalid Date' &&
                    format(parseISO(departureDate), 'HH:mm', {
                      locale: pt,
                    })}
                </span>
                <span className='airports'>{departureName}</span>
              </div>
              {!downOfSm && steps && <FlightStep steps={steps} />}

              <div className='depart-hour'>
                <span>
                  {arrivalDate !== undefined &&
                    arrivalDate !== 'Invalid Date' &&
                    format(parseISO(arrivalDate), 'HH:mm', {
                      locale: pt,
                    })}
                  <span className='plus-day'>
                    {diffDays > 0 ? `+${diffDays}` : ''}
                  </span>
                </span>

                <span className='airports'>{arrivalName}</span>
              </div>
            </div>
          </div>
          <div className='flight-details'>
            <span className='duration'>
              <span className='label'>Duração: </span>{' '}
              {departureDate !== undefined &&
                arrivalDate !== undefined &&
                departureDate !== 'Invalid Date' &&
                arrivalDate !== 'Invalid Date' &&
                format(
                  addMilliseconds(
                    new Date(0, 0),
                    parseISO(arrivalDate).getTime() -
                      parseISO(departureDate).getTime()
                  ),
                  'h:mm',
                  {
                    locale: pt,
                  }
                ).replace(':', 'h')}
              m
            </span>
            {/* <div className='facilities'>
              {airplaneFacilities.wifi && <img src={Wifi} alt='wifi' />}
              {airplaneFacilities.share && <img src={Share} alt='share' />}
              {airplaneFacilities.video && <img src={Video} alt='video' />}
              {airplaneModel && (
                <>
                  <img src={AirplaneModelIcon} alt='airplane-model' />
                  <span>{airplaneModel}</span>
                </>
              )}
            </div> */}
          </div>
          <Link className='alterar' to='/busca/resultado-pesquisa'>
            Alterar
          </Link>
        </S.FlightSummaryCardDetails>
      </S.BlankCard>
    </>
  );
};

export default FlightSummaryCard;
