import { Dialog } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const MuiDialog = styled(Dialog)`
  ${({ theme }) => css`
    .MuiPaper-root {
      background-color: transparent;
      box-shadow: none;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 100px;
        margin-bottom: 30px;
      }

      span {
        color: ${theme.palette.secondary.main};
        font-size: 28px;
      }
    }
  `}
`;
