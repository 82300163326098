import React, { useEffect } from "react";
import { BusContainer, SeatButton } from "../styles";
import Legend from "./../Legend";
import * as S from "./../styles";
import BusModel from "./../../../../assets/icons/BusModel.svg";
import { useGlobal } from "../../../../contexts/globalContext";
import BusLoader from "./Seats/BusLottie";

const Bus = ({
  selectedSeats,
  setSelectedSeats,
  seatMap,
  searchState,
  loading,
}) => {
  const handleSeatClick = (number) => {
    if (selectedSeats.includes(number)) {
      setSelectedSeats(selectedSeats.filter((seat) => seat !== number));
    } else {
      if (selectedSeats.length < searchState.passageiros) {
        setSelectedSeats([...selectedSeats, number]);
      }
    }
  };

  const seatSize = 30;
  const { setLoading } = useGlobal();
  const getSeatStyle = (x, y, seatSize) => {
    const newX = y;
    const newY = -x;

    var offsetX = 85;
    var offsetY = 80;
    const spacingX = seatSize + 10;
    const spacingY = seatSize + 10;

    return {
      bottom: `${offsetY + newX * spacingY}px`,
      left: `${offsetX - newY * spacingX}px`,
    };
  };

  useEffect(() => {
    setLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return loading ? (
    <>
      <S.Header>
        <div>
          <span>Selecione os assentos: </span>
        </div>
        <div className="count">
          <span>
            {selectedSeats.length} de {searchState.passageiros}{" "}
            {searchState.passageiros === 1
              ? "assento selecionado."
              : "assentos selecionados."}
          </span>
          <span>
            {selectedSeats.length === 0 && (
              <>
                Selecione {searchState.passageiros}{" "}
                {searchState.passageiros === 1 ? "assento." : "assentos."}
              </>
            )}
          </span>
        </div>
      </S.Header>
      <S.Container>
        <S.LoadText>
          Aguarde enquanto carregamos os assentos disponíveis.
        </S.LoadText>
        <BusLoader />
      </S.Container>
      <Legend />
    </>
  ) : (
    <>
      <S.Header>
        <div>
          <span>Selecione os assentos: </span>
        </div>
        <div className="count">
          <span>
            {selectedSeats.length} de {searchState.passageiros}{" "}
            {searchState.passageiros === 1
              ? "assento selecionado."
              : "assentos selecionados."}
          </span>
          <span>
            {selectedSeats.length === 0 && (
              <>
                Selecione {searchState.passageiros}{" "}
                {searchState.passageiros === 1 ? "assento." : "assentos."}
              </>
            )}
          </span>
        </div>
      </S.Header>
      <BusContainer BusModel={BusModel}>
        {seatMap.map((seat) => (
          <SeatButton
            key={seat.number}
            available={seat.available}
            selected={selectedSeats.includes(seat.number)}
            style={getSeatStyle(seat.position.x, seat.position.y, seatSize)}
            size={seatSize}
            onClick={() => handleSeatClick(seat.number)}
          >
            {seat.available ? seat.number : ""}
          </SeatButton>
        ))}
      </BusContainer>
      <Legend />
    </>
  );
};

export default Bus;
