import { Grid } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { isDiferentiatedBrands } from '../../../../themes/themes';
import { getBrandStorage } from '../../../../utils/getBrand';

const brand = getBrandStorage();

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: auto;
    width: 100%;
    background-color: ${isDiferentiatedBrands(brand)
      ? theme.palette.secondary.white
      : '#FFF'};
    border-radius: 6px !important;
    margin-top: 15px;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    display: flex;
    font-family: 'Poppins';
  `}
`;

export const CardContent = styled(Grid)`
  ${({ theme }) => css`
    display: flex;
  `}
`;

export const ExperienceDetails = styled(Grid)`
  ${({ theme }) => css`
    display: flex;
    ${theme.breakpoints.down('xs')} {
      flex-wrap: wrap;
    }

    .carousel-container {
      width: 30%;
      height: 220px !important;
      object-fit: fill;
      border-top-left-radius: 6px;

      ${theme.breakpoints.down('xs')} {
        width: 100%;
        border-top-right-radius: 6px;
      }
    }

    .content {
      margin-left: 15px;
      margin-top: 15px;
      margin-right: 15px;
      border-bottom: 1px solid #e6e6e6;
      width: 70%;

      ${theme.breakpoints.down('xs')} {
        margin-left: 0px;
        padding-left: 10px;
        padding-right: 10px;
      }

      .experience-details {
        display: flex;
        width: 100%;

        .experience-name {
          font-size: 16px;
          font-weight: 600;
          padding-right: 50px;
        }
      }
    }
  `}
`;

export const BottomDetails = styled(Grid)`
  ${({ theme }) => css`
    margin: 10px;
    display: flex;
    justify-content: flex-end;

    div {
      display: flex;
      align-items: center;

      ${theme.breakpoints.down('xs')} {
        flex-wrap: wrap;
        width: 100%;
        justify-content: flex-end;
      }

      .old-value {
        color: #000;
        opacity: 65%;
        font-size: 12px;
        ${theme.breakpoints.down('xs')} {
          display: flex;
          justify-content: flex-end !important;
          width: 100%;
        }

        .value {
          text-decoration: line-through;
        }
      }

      .final-value {
        font-size: 23px;
        font-weight: 600;
        margin-left: 4px;

        ${theme.breakpoints.down('xs')} {
          display: flex;
          justify-content: flex-end !important;
          width: 100%;
        }
      }

      .discount {
        background-color: #fff0d1;
        margin-left: 10px;
        margin-right: 15px;
        border-radius: 2px;
        font-weight: 700;
        font-size: 10px;
        color: #e7a413;
        padding: 2px;
      }
    }
  `}
`;
