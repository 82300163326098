import * as React from "react";

const SvgBus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 29.568"
    {...props}
  >
    <path
      data-name="BusPrimary"
      d="M20.0655 13.4814H2.7764C2.27263 13.4814 1.85795 13.0483 1.85795 12.5559C1.85795 12.4738 1.87307 12.381 1.88981 12.3064L2.80988 5.88163C2.88817 5.46425 3.26397 5.38218 3.71375 5.38218H19.1308C19.5773 5.38218 19.9564 5.45723 20.0336 5.87299L20.9537 12.415C20.9677 12.4911 20.9861 12.5359 20.9861 12.618C20.9855 13.1104 20.5687 13.4814 20.0655 13.4814ZM19.3738 20.6869C18.4003 20.6869 17.6109 19.9208 17.6109 18.9656C17.6109 18.0169 18.4003 17.2518 19.3738 17.2518C20.3408 17.2518 21.1318 18.0169 21.1318 18.9656C21.1318 19.9208 20.3408 20.6869 19.3738 20.6869ZM3.44485 20.6869C2.47835 20.6869 1.68841 19.9208 1.68841 18.9656C1.68841 18.0169 2.47835 17.2518 3.44485 17.2518C4.42107 17.2518 5.20939 18.0169 5.20939 18.9656C5.20939 19.9208 4.42107 20.6869 3.44485 20.6869ZM6.47935 1.60256H17.2783V3.22239H6.47935C5.39946 3.22239 5.39946 1.60256 6.47935 1.60256ZM21.7069 4.08091C21.3916 2.56204 20.4704 1.95028 18.9677 1.33906C17.4678 0.730006 13.9916 0 11.3772 0C8.75144 0 5.25745 0.730546 3.75586 1.33906C2.25373 1.95082 1.3477 2.56204 1.03508 4.08091L0 12.5359V24.2803H1.61984V25.3602C1.61984 27.5199 4.31956 27.5199 4.31956 25.3602V24.2803H17.8182V25.3602C17.8182 27.5199 21.0579 27.5199 21.0579 25.3602V24.2803H22.6777V12.5359L21.7069 4.08091Z"
      fill="none"
    />
  </svg>
);

export default SvgBus;
