import * as React from 'react';

const SvgBedPrimary = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 25 25'
    {...props}
  >
    <path
      data-name='Path 150'
      d='M6.559 22.11a2.913 2.913 0 0 1-.41 1.23c-.41.41-.615.41-1.23.41H1.64a2.913 2.913 0 0 1-1.23-.41C0 23.135 0 22.52 0 22.11v-9.428a1.861 1.861 0 0 1 .615-1.435l1.23-.615a26.651 26.651 0 0 1 10.248-1.64c4.714 0 8.2.615 10.658 1.64.41.2.615.41 1.025.615a1.547 1.547 0 0 1 .82 1.435v9.428a2.913 2.913 0 0 1-.41 1.23c-.2.41-.615.41-1.23.41h-3.28a2.913 2.913 0 0 1-1.23-.41c-.41-.2-.41-.615-.41-1.23v-1.64H6.559Zm.615-16.6a8.5 8.5 0 0 0-3.279.615C2.869 6.739 2.05 7.149 1.64 7.968V4.279a1.239 1.239 0 0 1 .615-1.23C4.3 1.615 7.583 1 12.3 1h2.869a13.779 13.779 0 0 1 3.689.615A11.146 11.146 0 0 1 22.34 3.05a1.771 1.771 0 0 1 .615 1.23v3.688A4.962 4.962 0 0 0 20.7 6.329a8.5 8.5 0 0 0-3.279-.615 6.546 6.546 0 0 0-2.869.615A5.763 5.763 0 0 0 12.3 7.354a11.114 11.114 0 0 0-2.255-1.23 6.546 6.546 0 0 0-2.872-.615Z'
      fill='#fd665e'
    />
  </svg>
);

export default SvgBedPrimary;
