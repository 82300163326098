import * as React from "react";

const LuggageIcon = (props) => {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
    d="M36.6663 31.6667H3.33301V35H36.6663V31.6667ZM6.66634 25C6.66634 25.8333 6.99967 26.6667 7.66634 27.3333C8.33301 28 9.16634 28.3333 9.99967 28.3333V10C9.16634 10 8.33301 10.3333 7.66634 11C6.99967 11.6667 6.66634 12.5 6.66634 13.3333V25ZM22.4997 10H17.4997C17.4997 9.33333 17.6663 8.66667 18.1663 8.16667C18.6663 7.66667 19.1663 7.5 19.9997 7.5C20.6663 7.5 21.333 7.66667 21.833 8.16667C22.1663 8.66667 22.4997 9.33333 22.4997 10ZM11.6663 10V28.3333H28.333V10H24.9997C24.9997 8.66667 24.4997 7.5 23.4997 6.5C21.9997 5 21.333 5 19.9997 5C18.6663 5 17.4997 5.5 16.4997 6.5C15.4997 7.5 14.9997 8.66667 14.9997 10H11.6663ZM29.9997 28.3333C30.833 28.3333 31.6663 28 32.333 27.3333C32.9997 26.6667 33.333 25.8333 33.333 25V13.3333C33.333 12.5 32.9997 11.6667 32.333 11C31.6663 10.3333 30.833 10 29.9997 10V28.3333Z"
    fill="black" />
    </svg>
  );
};

export default LuggageIcon;
