import styled, { css } from "styled-components";
import { getCampaignColors } from "../../utils/getCampaignColors";

const campaignColors = getCampaignColors();

export const Wrapper = styled.div`
	${({ theme }) => css`
		height: auto;
		font-family: "Poppins";
		margin-top: 50px;
		margin-bottom: 50px;

		.MuiInputBase-input {
			color: #424242;
		}
		label.Mui-focused {
			color: #424242;
		}
		.MuiInput-underline:after {
			border-bottom-color: #424242;
			color: #424242;
		}

		.MuiFormLabel-root {
			opacity: 1;
			color: #424242;
		}

		.MuiSelect-icon {
			color: #424242;
		}

		.MuiOutlinedInput-root {
			& fieldset {
				border-color: #424242;
				color: #424242;
			}

			&:hover fieldset {
				border-color: #424242;
				color: #424242;
				border-width: 2px;
			}

			&.Mui-focused fieldset {
				border-color: #424242;
				color: #424242;
			}
		}
	`}
`;

export const FilterTitle = styled.span`
	margin-bottom: 10px;
	font-weight: 600;
	font-size: 14px;
`;

export const FilterGroup = styled.div`
	${({ theme }) => css`
		margin-bottom: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.MuiFormControl-root {
			margin-bottom: 10px !important;
		}

		.Mui-checked {
			color: ${campaignColors.searchColor ? campaignColors.searchColor : theme.palette.primary.main};
		}
	`}
`;
