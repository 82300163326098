import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const BlankCard = styled.div`
  ${({ theme }) => css`
    height: auto;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    padding: 12px;
    padding-top: 9px;
    margin-bottom: 40px;
  `}
`;

export const CardHeader = styled.div`
  ${({ theme }) => css`
    padding-bottom: 8px;
    padding-left: 5px;
    border-bottom: 1px solid ${rgba('#707070', 0.21)};
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    ${theme.breakpoints.down('xs')} {
      flex-wrap: wrap;

      div {
        display: flex;
        width: 100%;

        flex-wrap: wrap;
        justify-content: center;

        span {
          text-align: center;
          width: 100%;
        }
      }
      span {
        width: 100%;
      }
    }
  `}
`;

export const StatusContainer = styled.div`
  display: flex;
`;

export const Status = styled.span`
  ${({ theme }) => css`
    color: #fff;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0 10px;
    border-radius: 15px;
    font-family: 'Poppins';
    font-size: 10px;
    font-weight: bold;
    margin-left: 10px;

    ${theme.breakpoints.down('xs')} {
      width: 100%;
      justify-content: center;
      display: flex;
      height: 20px;
    }

    &.pendente {
      background-color: #97992f;
    }

    &.emitido {
      background-color: #4485cf;
    }

    &.expirado {
      background-color: #b50076;
    }

    &.finalizado {
      background-color: #01b956;
    }

    &.cancelado {
      background-color: #db422e;
    }
  `}
`;

export const BottomInfos = styled.div`
  ${({ theme }) => css`
    display: flex;
    border-top: 1px solid ${rgba('#707070', 0.21)};
    margin-top: 10px;
    padding-top: 10px;

    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
  `}
`;

export const ExpirationDate = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  color: red;
  padding-top: 15px;
`;

export const ValorTotal = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    ${theme.breakpoints.down('xs')} {
      margin-top: 20px;
      justify-content: center;
    }
  `}
`;

export const Travellers = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 70%;
  `}
`;

export const TravellerName = styled.span`
  ${({ theme }) => css`
    font-weight: 300;
    padding-top: 3px;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    text-align: left;
    font: normal normal bold 15px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;

    .subtitle {
      font-size: 11px;
      font-weight: 400;
      margin-left: 10px;
    }

    .subtitle-locator {
      font-size: 12px;
      font-weight: 500;
    }
  `}
`;

export const Subtitle = styled.div`
  ${({ theme }) => css`
    text-align: left;
    font: normal normal bold 10px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  `}
`;

export const FlightSummaryCardDetails = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
  `}
`;
