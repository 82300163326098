import { db, storage } from "../services/firebase";

import { addMonths } from "date-fns";
import groupBy from "../utils/groupBy";
import getHeaders from "../utils/returnHeaders";
import api from "../services/api";

export default class VitrineRepository {
	getMoreSearched = async () => {
		const initialDate = addMonths(new Date(), -6).getTime();
		var searchesRef = db.collection('searches');

		return searchesRef
			.where('date', '>=', initialDate)
			.orderBy('date', 'desc')
			.get()
			.then((snapshot) => {
				const res = snapshot.docs.map((i) => i.data());
				const groupedByDestinaion = Object.entries(groupBy(res, 'destinationIata'))
					.sort((a, b) => b[1].length - a[1].length)
					.slice(0, 4);
				return groupedByDestinaion;
			});
	};

	getMoreSearchedByUser = async (user) => {
		const initialDate = addMonths(new Date(), -6).getTime();
		var searchesRef = db.collection('searches');

		// const userDetails = JSON.parse(sessiconStorage.getItem('@App:user'));

		if (user?.id) {
			const res = await searchesRef
				.where('userId', '==', user?.id)
				.where('date', '>=', initialDate)
				.orderBy('date', 'desc')
				.get()
				.then((snapshot) => {
					const res = snapshot.docs.map((i) => i.data());
					const groupedByOrigin = Object.entries(groupBy(res, 'originIata'))
						.sort((a, b) => b[1].length - a[1].length)
						.slice(0, 1);
					return groupedByOrigin[0][1][0];
				})
				.catch((erro) => console.error('erro', erro));

			return res;
		} else {
			return 'nada';
		}
	};

	getStorageImages = async (iata) => {
		var storageRef = storage.ref();

		var stg = storageRef.child(`destination-images/${iata}.png`);

		return stg
			.getDownloadURL()
			.then((url) => url)
			.catch(
				() => 'https://via.placeholder.com/200x200.png?text=download%20error'
			);
	};

	getVitrineIcone = async (idVitrine, idImagem) => {
		var storageRef = storage.ref();

		var stg = storageRef.child(`showcase/${idVitrine}/${idImagem}`);

		return stg
			.getDownloadURL()
			.then((url) => url)
			.catch(
				() => 'https://via.placeholder.com/200x200.png?text=download%20error'
			);
	};

	getShowCase = () => {
		const header = getHeaders();
		return api.get(`/showcase`, header);
	};
}
