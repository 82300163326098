import React from 'react';
import * as S from './styles';

import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';

import GradeIcon from '@material-ui/icons/Grade';
import PlaceIcon from '@material-ui/icons/Place';
// import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';

// const CarSummaryCard = ({ car }) => {
const HotelSummaryCard = ({ hotel }) => {
  const stars =
    hotel &&
    Array(Math.round(hotel.hotel.basicPropertyInfo?.award?.rating))
      .fill()
      .map((_, idx) => hotel.hotel.basicPropertyInfo?.award?.rating + idx);

  return (
    <div>
      <S.Wrapper>
        <S.CardContent container>
          <div className='title title2'>
            <S.Title>
              Periodo
              <span className='subtitle'>
                {' '}
                {hotel?.hotel?.availability.start !== undefined &&
                  hotel.hotel?.availability.start !== 'Invalid Date' &&
                  format(
                    parseISO(hotel.hotel?.availability.start),
                    'dd/MM/yyyy',
                    {
                      locale: pt,
                    }
                  )}
                {` à `}
                {hotel?.hotel?.availability.end !== undefined &&
                  hotel.hotel?.availability.end !== 'Invalid Date' &&
                  format(
                    parseISO(hotel.hotel?.availability.end),
                    'dd/MM/yyyy',
                    {
                      locale: pt,
                    }
                  )}
              </span>
            </S.Title>
          </div>
          <S.HotelDetails item xs={12}>
            <img
              src={hotel?.hotel.basicPropertyInfo.imageURL}
              alt='imagem do hotel'
            />
            <div className='content'>
              <div className='stars'>
                {stars?.map((star, index) => (
                  <GradeIcon fontSize='small' key={index} />
                ))}
              </div>
              <div className='hotel-details'>
                <span className='hotel-name'>
                  {hotel?.hotel.basicPropertyInfo.hotelRef.hotelName}
                </span>
                <div>
                  {/* <span className='hotel-rate'>{7.9}</span> */}
                  <span className='hotel-rate-level'>
                    {/* {'Bom'} ({120}) */}
                  </span>
                </div>
              </div>
              <div className='location'>
                <span>
                  <PlaceIcon fontSize='small' />
                  {hotel?.hotel.basicPropertyInfo.address.addressLine}
                </span>
                {/* <span>
                  <DirectionsWalkIcon fontSize='small' />
                  {0.46}km de distância até o centro da cidade
                </span> */}
              </div>
            </div>
          </S.HotelDetails>
        </S.CardContent>
      </S.Wrapper>
    </div>
  );
};

export default HotelSummaryCard;
