import * as S from './styles';
import CurrencyFormat from 'react-currency-format';
import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';

import ThemeButton from '../../ThemeButton';
import { useGlobal } from '../../../contexts/globalContext';
import { addFeeByDestinoFerias } from '../../../utils/addFeeByDestinoFerias';

const RoomRate = ({ rate, onChangeRate, rateSelected, buscaAtual }) => {
	const { user, signed } = useGlobal();

	const [shortenCoinAlias, setShortenCoinAlias] = useState('Pts');

	const getValuesByUser = (val) => {
		let value = signed
			? val * user.catalog.catalogCurrencyConfig.conversionRate
			: val;

		if (user.campaign) value = addFeeByDestinoFerias(value, buscaAtual);

		return signed ? Math.ceil(Math.round(value)) : value;
	};

	useEffect(() => {
		setShortenCoinAlias(
			user?.campaign?.campaignConfig?.shortenCoinAlias || 'Pts'
		);
	}, [user?.campaign?.campaignConfig?.shortenCoinAlias]);

	return (
		<S.Wrapper
			className={`${
				rateSelected?.ratePlanID === rate?.ratePlanID &&
				rateSelected?.[
					rateSelected.total.currencyCode === 'BRL'
						? 'total'
						: 'origTotal' || 'total'
				].amountAfterTax ===
					rate?.[
						rate.total.currencyCode === 'BRL' ? 'total' : 'origTotal' || 'total'
					].amountAfterTax
					? 'selected'
					: ''
			}`}
		>
			<Grid container spacing={1}>
				<Grid item xs={12} md={8}>
					<S.RatesContainer>
						{/* <div>
              <p>ratePlanID: {rate?.ratePlanID}</p>
              <p>amountAfterTax: {rate?.[rate.total.currencyCode === 'BRL' ? 'total' : 'origTotal' || 'total'.amountAfterTax}</p>
              <p>SELECTED ratePlanID: {rateSelected?.ratePlanID}</p>
              <p>
                SELECTED amountAfterTax: {rateSelected?.[rateSelected.total.currencyCode === 'BRL' ? 'total' : 'origTotal' || 'total'].amountAfterTax}
              </p>
            </div> */}

						{/* <S.LinkToTooltip>
              {rate?.ratePlans.cancelPenalties[0]?.penaltyDescription.name}
            </S.LinkToTooltip>
            <p>
              {
                rate?.ratePlans.cancelPenalties[0]?.penaltyDescription
                  .description
              }
            </p> */}
						{/* {rate?.ratePlans.mealsIncluded?.breakfast && (
              <>
                <S.LinkToTooltip>
                  {rate?.ratePlans.mealsIncluded.name}
                </S.LinkToTooltip>
                <p>{rate?.ratePlans.mealsIncluded.description}</p>
              </>
            )} */}

						{rate?.ratePlans.mealPlanInfo.mealPlanDescription}

						{rate?.ratePlans.cancelPenalties.map((penalty, index) => (
							<S.CancelPenalties key={index} className={`index-${index}`}>
								{penalty?.penaltyDescription?.description}
							</S.CancelPenalties>
						))}
					</S.RatesContainer>
				</Grid>

				<Grid item xs={12} md={4}>
					<S.ButtonContainer>
						<S.Value>
							{signed && !user?.guest && !user?.campaign?.disablePointActivity ? (
								<span>
									{getValuesByUser(
										rate?.[
											rate.total.currencyCode === 'BRL' ? 'total' : 'origTotal' || 'total'
										].amountAfterTax
									)}{' '}
									{shortenCoinAlias}
								</span>
							) : (
								<CurrencyFormat
									value={
										rate?.[
											rate.total.currencyCode === 'BRL' ? 'total' : 'origTotal' || 'total'
										].amountAfterTax
									}
									displayType={'text'}
									decimalSeparator=','
									decimalScale={2}
									prefix={'R$ '}
								/>
							)}
						</S.Value>
						<ThemeButton
							className='select-hotel-rate'
							onClick={() => onChangeRate(rate)}
						>
							Selecionar tarifa
						</ThemeButton>
					</S.ButtonContainer>
				</Grid>
			</Grid>
		</S.Wrapper>
	);
};

export default RoomRate;
