import { storage } from "../services/firebase";
import { getSessionStorage } from "./getSessionStorage";

export const getCampaignColors = () => {
	const campaignIdFromEnv = process.env.REACT_APP_CAMPAIGN_ID;

	let campaignConfig = getSessionStorage('@App:campaignConfig');
	let imagesStorage = getSessionStorage('@App:imagesStorage');
	const userDetails = getSessionStorage('@App:user');

	if (campaignConfig && campaignIdFromEnv) {
		if (campaignConfig.campaignId?.toString() !== campaignIdFromEnv?.toString()) {
			sessionStorage.removeItem("@App:campaignConfig");
			sessionStorage.removeItem("@App:imagesStorage");
			campaignConfig = null;
			imagesStorage = null;
		}
	}

	if (!campaignConfig) {
		campaignConfig = userDetails ? { ...userDetails.campaign.campaignConfigLayout } : {};
	}

	if (!imagesStorage) imagesStorage = {};

	return { ...campaignConfig, ...imagesStorage };
};

export const getImagesStorage = async (campaignCode) => {
	var storageRef = storage.ref();
	var stgHome = storageRef.child(`campaign/${campaignCode}/home/${campaignCode}.png`);

	var stgLogo = storageRef.child(`campaign/${campaignCode}/logo/${campaignCode}.png`);

	var stgLogoSecundaria = storageRef.child(`campaign/${campaignCode}/logo/${campaignCode}-secundaria.png`);

	var stgLogoMobile = storageRef.child(`campaign/${campaignCode}/logoMobile/${campaignCode}.png`);

	var stgHomeDefault = storageRef.child(`campaign/0/home/0.png`);

	var stgLogoDefault = storageRef.child(`campaign/0/logo/0.png`);
	var stgLogoSecundariaDefault = storageRef.child(`campaign/0/logo/0-secundaria.png`);

	var stgLogoMobileDefault = storageRef.child(`campaign/0/logoMobile/0.png`);

	const homeDefault = await stgHomeDefault
		.getDownloadURL()
		.then((url) => url)
		.catch(() => "");
	const logoDefault = await stgLogoDefault
		.getDownloadURL()
		.then((url) => url)
		.catch(() => "");
	const logoSecundariaDefault = await stgLogoSecundariaDefault
		.getDownloadURL()
		.then((url) => url)
		.catch(() => "");
	const logoMobileDefault = await stgLogoMobileDefault
		.getDownloadURL()
		.then((url) => url)
		.catch(() => "");

	const home = await stgHome
		.getDownloadURL()
		.then((url) => url)
		.catch(() => "");
	const logo = await stgLogo
		.getDownloadURL()
		.then((url) => url)
		.catch(() => "");
	const logoSecundaria = await stgLogoSecundaria
		.getDownloadURL()
		.then((url) => url)
		.catch(() => "");
	const logoMobile = await stgLogoMobile
		.getDownloadURL()
		.then((url) => url)
		.catch(() => "");

	const images = {
		home,
		logo,
		logoMobile,
		homeDefault,
		logoDefault,
		logoMobileDefault,
		logoSecundaria,
		logoSecundariaDefault,
	};

	return images;
};
