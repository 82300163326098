import React from "react";
import * as S from "../CancellationPolicyModal/styles.js";
import { Modal, Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const CancellationPolicy = ({ data, onClose }) => {
    return (
        <>
            <Modal
                open={true}
                onClose={onClose}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <S.ModalBox>
                    <IconButton
                        onClick={onClose}
                        style={{ position: "absolute", right: 20, top: 20 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">
                        Política de cancelamento
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        {data?.name ||
                            "Nome da atividade não disponível"}
                    </Typography>
                    <S.Table>
                        <thead>
                            <tr>
                                <th>Início</th>
                                <th>Fim</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {data?.cancellationStartDate?.date
                                        ? `${data.cancellationStartDate.date} às ${data.cancellationStartDate.time}`
                                        : "Início não disponível"}
                                </td>
                                <td>
                                    {data?.cancellationEndDate?.date
                                        ? `${data.cancellationEndDate.date} às ${data.cancellationEndDate.time}`
                                        : "Término não disponível"}
                                </td>
                                <td>
                                    {data?.currencySymbol}
                                    {data?.cancellationPrice ||
                                        "Preço não disponível"}
                                </td>
                            </tr>
                        </tbody>
                    </S.Table>
                </S.ModalBox>
            </Modal>
        </>
    );
};

export default CancellationPolicy;
