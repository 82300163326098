import axios from 'axios';

const configAPI = {
  baseURL: `${process.env.REACT_APP_API}`,
  headers: {
    'content-type': 'application/json',
  },
};

const api = axios.create(configAPI);
api.defaults.timeout = 300000;

export default api;
