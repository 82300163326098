import { getSessionStorage } from "./getSessionStorage";

const getHeaders = () => {
	const campaignId = getSessionStorage("campaign");
	const userAuth = getSessionStorage('auth');

	let header = {
		headers: {
			Authorization: userAuth?.access_token ? userAuth?.access_token : userAuth?.accessToken,
			"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
		},
	};

	if (campaignId !== null) header.headers = Object.assign({ ...header.headers, Campaign: campaignId });

	return header;
};

export default getHeaders;
