import deburr from "lodash/deburr";
import getHeaders from "../utils/returnHeaders";
import api from "../services/api";

export default class CitiesRepository {
  getCities = (term) => {
    const header = getHeaders();
    return api.get(
      `/experience/hotel-cities?name=${deburr(term).toLowerCase()}`,
      header,
    );
  };

  getHotelCities = async (term) => {
    const header = getHeaders();
    return api.get(
      `/hotel/locations?name=${deburr(term).toLowerCase()}`,
      header,
    );
  };
}
