import React from 'react';
import { withRouter, Route } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

import MyTravels from '../../components/MyTravels';

import FooterPorto from '../../components/FooterPorto'
import { getBrandStorage } from '../../utils/getBrand';

function Account() {
  return (
    <div style={{ backgroundColor: '#f2f2f2' }}>
      <Header />
      <Route path='/conta/minhas-viagens' exact component={MyTravels} />
      {getBrandStorage() === 'portoplus' ? <FooterPorto /> : <Footer />}
    </div>
  );
}

export default withRouter(Account);
