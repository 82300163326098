import * as S from './styles';

function Separator({ className, margin, thin }) {
  return (
    <S.Separator margin={margin} thin={thin}>
      <div className={className} />
    </S.Separator>
  );
}

export default Separator;
