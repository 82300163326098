import styled, { css } from 'styled-components';
import { isDiferentiatedBrands } from '../../../../themes/themes';
import { getBrandStorage } from '../../../../utils/getBrand';

const brand = getBrandStorage();
export const Wrapper = styled.div`
	${({ theme }) => css`
		font-family: 'Poppins' !important;
		display: flex;
		color: black;
		background-color: ${isDiferentiatedBrands(brand)
			? theme.palette.secondary.white
			: '#FFF'};
		height: 100%;
		width: auto;
		border: 1px solid #e3e3e3;

		table {
			border-collapse: collapse;
			margin: 5px;
			margin-left: 50px;
			margin-right: 50px;

			.column-title {
				font-size: 12px;
				color: #848484;
				font-weight: Medium;
				text-align: left;
				width: 120px;
			}

			.column-value {
				font-size: 12px;
				color: #848484;
				font-weight: Bold;

				span {
					font-size: 11px;
					font-weight: normal;
				}
			}

			td {
				border-bottom: 1px solid #dddddd;
				text-align: center;
				width: 120px;
			}

			td,
			th {
				height: 15px;
			}

			.last {
				border: none;
			}
		}
	`}
`;

export const ImageLogo = styled.img`
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0;
`;

// export const Grid = styled(MuiGrid)`
//   ${({ theme }) => css`
//     display: flex;
//     color: black;
//     background-color: ${theme.palette.secondary.main};
//     height: 100px;
//     width: auto;
//     /* min-width: 50%;
//     max-width: 50%; */
//     border: 1px solid #e3e3e3;
//     border-radius: 5px;
//     padding-left: 15px;
//     padding-right: 15px;
//   `}
// `;

// export const GridContent = styled(MuiGrid)`
//   .grid-item {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     align-content: space-between;
//     justify-content: center;
//     padding-top: 15px;
//     padding-bottom: 15px;
//     font-family: 'Poppins' !important;
//     font-size: 14px;
//     color: #848484;

//     img {
//       width: 25px;
//       height: 25px;
//     }

//     span {
//       display: flex;
//       justify-content: center;
//       width: 100%;

//       span {
//         display: flex;
//         margin: 0px;
//         padding: 0px;
//         width: auto;
//         align-items: flex-end;
//         font-weight: 600;
//       }

//       & > *:first-child {
//         font-size: 9px;
//         font-weight: 300;
//         padding-bottom: 1px;
//         padding-right: 2px;
//       }
//     }

//     & > *:last-child {
//       border-top: 1px ${rgba('#707070', 0.17)} solid;
//       padding-top: 3px;
//     }
//   }
//   & > *:first-child {
//     padding-top: 40px;

//     span {
//       display: flex;
//       justify-content: flex-start;
//     }
//   }
// `;
