import { Box, LinearProgress, withStyles } from "@material-ui/core";

import { useGlobal } from "../../contexts/globalContext";
import { getCampaignColors } from "../../utils/getCampaignColors";

const campaignColors = getCampaignColors();

const BorderLinearProgress = withStyles((theme) => ({
	root: {
		height: 2,
		borderRadius: 5,
	},
	colorPrimary: {
		backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
	},
	bar: {
		borderRadius: 5,
		backgroundColor: campaignColors.searchColor ? campaignColors.searchColor : theme.palette.primary.main,
	},
}))(LinearProgress);

const Loading = () => {
	const { state } = useGlobal();

	return (
		state.loading && (
			<Box sx={{ width: "100%" }}>
				<BorderLinearProgress />
			</Box>
		)
	);
};

export default Loading;
