import { InputBase as MuiTextField } from "@material-ui/core";
import { DateRange } from "react-date-range";

import styled, { css } from "styled-components";
import { getCampaignColors } from "../../utils/getCampaignColors";

const campaignColors = getCampaignColors();

export const TextField = styled(MuiTextField)`
	${({ theme }) => css`
		border: 1px ${theme.palette.secondary.main};
		.MuiInputBase-root {
			color: ${theme.palette.secondary.main};
		}
	`}
`;

export const DateRangePicker = styled(DateRange)`
	${({ theme }) => css`
		border: 1px ${theme.palette.secondary.main};
		.MuiInputBase-root {
			color: ${theme.palette.secondary.main};
		}

		${theme.breakpoints.down("sm")} {
			zoom: 77%;
		}

		.rdrDateDisplayItemActive {
			border-color: ${campaignColors.searchColor ? campaignColors.searchColor : theme.palette.primary.main};
		}

		.rdrDayToday .rdrDayNumber span:after {
			background: ${campaignColors.searchColor ? campaignColors.searchColor : theme.palette.primary.main};
		}

		.rdrSelected,
		.rdrInRange,
		.rdrStartEdge,
		.rdrEndEdge {
			background: ${campaignColors.searchColor ? campaignColors.searchColor : theme.palette.primary.main};
		}

		.rdrDayStartPreview,
		.rdrDayInPreview,
		.rdrDayEndPreview {
			border: 0px solid ${campaignColors.searchColor ? campaignColors.searchColor : theme.palette.primary.main};
		}

		.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
			color: ${campaignColors.searchColor ? campaignColors.searchColor : theme.palette.primary.main};
		}

		.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
			border: 1px solid ${campaignColors.searchColor ? campaignColors.searchColor : theme.palette.primary.main};
		}
	`}
`;
