import { Grid } from '@material-ui/core';
import { Container } from '@material-ui/core';
// import { Facebook, Instagram, WhatsApp } from '@material-ui/icons';
import React from 'react';
import * as S from './styles';

const FooterPorto = () => {
  return (
    <S.Wrapper>
      <Container>
        <Grid container>
          <S.GridCentralized item xs={12}>
            {/* <S.FooterTitle>Links úteis</S.FooterTitle> */}
            <S.FooterList>
              <a href='https://portoplus.webpremios.digital/regulation'>
                <span>Regulamento</span>
              </a>
              <a href='https://portoplus.webpremios.digital/privacy'>
                <span>Política de Privacidade</span>
              </a>
              <a href='https://portoplus.webpremios.digital/cookies-regulation'>
                <span>Política de Cookies</span>
              </a>
              <a href='https://portoplus.webpremios.digital/terms'>
                <span>Termos de Uso</span>
              </a>
              <a href='https://portoplus.webpremios.digital/redirect/faq'>
                <span>Ajuda</span>
              </a>
              <a href='https://portoplus.webpremios.digital/redirect/contact'>
                <span>Fale Conosco</span>
              </a>
            </S.FooterList>
          </S.GridCentralized>{' '}
          {/* <S.GridCentralized item xs={12} sm={6} md={3}>
            <S.FooterTitle>Links Uteis</S.FooterTitle>
            <S.FooterList>
              <span>Blog</span>
              <span>Institucional</span>
              <span></span>
              <span></span>
            </S.FooterList>
          </S.GridCentralized>{' '} */}
          {/* <S.GridCentralized item xs={12} sm={6} md={3}>
            <S.FooterTitle>Atendimento</S.FooterTitle>
            <S.FooterList>
              <span>Fale conosco</span>
            </S.FooterList>
          </S.GridCentralized>{' '} */}
          {/* <S.GridCentralized item xs={12} sm={6} md={3}>
            <S.FooterTitle>Social</S.FooterTitle>
            <S.FooterList>
              <WhatsApp fontSize='large' />
              <Facebook fontSize='large' />
              <Instagram fontSize='large' />
            </S.FooterList>
          </S.GridCentralized> */}
        </Grid>
        <S.CompanyName>
          Destino Férias © 2021 - Todos os direitos reservados - release 60
        </S.CompanyName>
      </Container>
    </S.Wrapper>
  );
};

export default FooterPorto;
