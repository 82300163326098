import React, { useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from '@material-ui/core/DialogContent';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import { Grid, Typography } from '@material-ui/core';
import FormInput from '../FormInput';
import { auth } from '../../services/firebase';
import { useGlobal } from '../../contexts/globalContext';

import UserRepository from '../../repositories/userRepository';
import Loader from '../Loader';

import ThemeButton from '../../components/ThemeButton';
import { validEmail, validCPF } from '../../utils/validator';

function RegisterModal({
	open,
	onClose,
	booking,
	paymentData,
	type,
	travelVoucherUser,
}) {
	const [error, setError] = useState('');
	const [openLoader, setOpenLoader] = useState(false);
	const [formData, setFormData] = useState({});
	const [isRegister, setIsRegister] = useState(true);
	const [recoverPass, setRecoverPass] = useState(false);
	const [userName, setUserName] = useState('');
	const [pass, setPass] = useState('');

	const { user, loginFirebase, showAlert } = useGlobal();
	const history = useHistory();

	const userRepository = new UserRepository();

	const validIfFormIsFilled = () => {
		const { name, lastName, cpf, cellphone, email, password } = formData;

		if (name && lastName && cpf && cellphone && email && password) {
			setError('');
			return true;
		}
		return false;
	};

	const handleSubmit = async () => {
		setOpenLoader(true);
		if (isRegister) {
			await handleSubmitRegister();
		}
		if (!isRegister) {
			await handleSubmitLogin();
		}
	};

	const handleSubmitRegister = async () => {
		const { name, lastName, cpf, cellphone, email, password } = formData;
		const data = {
			name,
			lastName,
			cpf,
			cellphone,
			email,
			password,
			campaign: user.campaign,
			customer: user.campaign.customer,
			referenceUserId: user.id,
			travelVoucher: travelVoucherUser,
		};

		if (!validIfFormIsFilled()) {
			setError('Campo obrigatório não preenchido, revise o cadastro.');
			setOpenLoader(false);
			return;
		}
		if (!validEmail(formData.email)) {
			setError('Por favor, digite um e-mail válido');
			setOpenLoader(false);
			return;
		}
		if (!validCPF(formData.cpf)) {
			setError('Por favor, digite um CPF válido');
			setOpenLoader(false);
			return;
		}

		const response = await userRepository.createNewUser(data);
		if (!response.data.error) {
			await loginFirebase(email, password).then(() => {
				// onClose(false);
				showAlert('success', 'Cadastro realizado com sucesso!');
				history.push('/busca/resumo');
			});
		}

		if (response.data.error) {
			setOpenLoader(false);
			setError(response.data.error);
		}
	};

	const handleSubmitLogin = async () => {
		setError('');
		try {
			if (recoverPass) {
				if (userName !== '') {
					auth
						.sendPasswordResetEmail(userName)
						.then(() => {
							showAlert(
								'success',
								'Enviamos um e-mail para você atualizar sua senha, verifique!'
							);
						})
						.catch((e) => {
							setOpenLoader(false);
							setError('Por favor, digite um e-mail valido');
						});
				} else {
					setOpenLoader(false);
					setError('Por favor, digite um e-mail valido');
				}
			} else {
				if (userName !== '' && pass !== '') {
					await loginFirebase(userName, pass).then((result) => {
						if (!result?.success) {
							setError(result.error);
							setOpenLoader(false);
						} else {
							// setOpenLoader(false);
							// onClose(false);
							history.push('/busca/resumo');
						}
					});
				} else {
					setError('Por favor, digite um usuário e senha');
					setOpenLoader(false);
				}
			}
		} catch (e) {
			setError(e);
			setOpenLoader(false);
		}
	};

	const handleChangeInputs = (e) => {
		setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	return (
		<S.Modal>
			<Loader
				openLoader={openLoader}
				isBooking={false}
				isRegister={isRegister}
				isLogin={!isRegister}
			/>
			<Dialog
				open={open}
				// onClose={() => onClose(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<S.CustomDialogActions>
					<S.TitleWrapper>
						<S.Title
							className={isRegister ? 'active' : 'inactive'}
							onClick={() => setIsRegister(true)}
						>
							Registrar-me
						</S.Title>
						<S.Title
							className={isRegister ? 'inactive' : 'active'}
							onClick={() => setIsRegister(false)}
						>
							Login
						</S.Title>
					</S.TitleWrapper>
				</S.CustomDialogActions>

				<DialogContent dividers>
					{!isRegister && (
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography>
									Para continuar com a reserva, precisamos que você efetue o seu login ou
									caso não tenha um,{' '}
									<span
										style={{ cursor: 'pointer', fontWeight: 'bold' }}
										onClick={() => setIsRegister(true)}
									>
										registre-se
									</span>
									.
								</Typography>
							</Grid>
							<FormInput
								margin='normal'
								required
								fullWidth
								label='Digite seu E-mail'
								autoFocus
								value={userName}
								onChange={(e) => setUserName(e.target.value)}
								onKeyPress={(e) => {
									if (recoverPass && e.key === 'Enter') {
										handleSubmitLogin();
									}
								}}
							/>

							{!recoverPass && (
								<FormInput
									margin='normal'
									required
									fullWidth
									label='Senha'
									type='password'
									value={pass}
									onChange={(e) => setPass(e.target.value)}
									onKeyPress={(e) => {
										if (e.key === 'Enter') {
											handleSubmitLogin();
										}
									}}
								/>
							)}
							<Grid container>
								<Grid item xs={12}>
									<S.ForgotPass
										onClick={() => {
											setError('');
											setRecoverPass(!recoverPass);
										}}
									>
										{recoverPass ? 'Voltar' : 'Esqueceu sua senha?'}
									</S.ForgotPass>
								</Grid>
							</Grid>
						</Grid>
					)}

					{isRegister && (
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography>
									Para continuar com a reserva, precisamos que você se registre ou caso
									já tenha um registro, efetue o{' '}
									<span
										style={{ cursor: 'pointer', fontWeight: 'bold' }}
										onClick={() => setIsRegister(false)}
									>
										login
									</span>
									.
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<FormInput label='Nome *' onChange={handleChangeInputs} name='name' />
							</Grid>

							<Grid item xs={12}>
								<FormInput
									label='Sobrenome *'
									onChange={handleChangeInputs}
									name='lastName'
								/>
							</Grid>

							<Grid item xs={12}>
								<FormInput
									label='CPF *'
									onChange={handleChangeInputs}
									name='cpf'
									mask='999.999.999-99'
								/>
							</Grid>

							<Grid item xs={12}>
								<FormInput
									label='Celular *'
									onChange={handleChangeInputs}
									name='cellphone'
									mask='(99) 99999-9999'
								/>
							</Grid>

							<Grid item xs={12}>
								<FormInput
									label='E-mail *'
									onChange={handleChangeInputs}
									name='email'
									type='email'
								/>
							</Grid>

							<Grid item xs={12}>
								<FormInput
									label='Senha *'
									onChange={handleChangeInputs}
									name='password'
									type='password'
								/>
							</Grid>
						</Grid>
					)}

					{error && (
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant='span' color='error'>
									{error}
								</Typography>
							</Grid>
						</Grid>
					)}
				</DialogContent>
				<DialogActions>
					<ThemeButton
						onClick={() => {
							onClose(false);
						}}
						color='primary'
						className='close'
					>
						Cancelar
					</ThemeButton>

					<ThemeButton onClick={handleSubmit} color='primary' className='payment'>
						Confirmar
					</ThemeButton>
				</DialogActions>
			</Dialog>
		</S.Modal>
	);
}

export default RegisterModal;
