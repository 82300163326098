import * as S from './styles';

import { useState } from 'react';

import ReprocessarPagamento from '../../ReprocessarPagamento';
import BookingsRepository from '../../../repositories/bookingsRepository';
import { useGlobal } from '../../../contexts/globalContext';
import GradeIcon from '@material-ui/icons/Grade';
import PlaceIcon from '@material-ui/icons/Place';
import Loader from '../../Loader';
import { useHistory } from 'react-router-dom';
import Auth2faModal from '../../Auth2FaModal';

import { ThemeButton } from '../../ThemeButton/styles';

import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';

const HotelTravelsCard = ({ booking = [], departureDate, arrivalDate, coinAlias}) => {
	let history = useHistory();

	const bookingsRepository = new BookingsRepository();
	const { showAlert } = useGlobal();

	const [openDialogReprocessar, setOpenDialogReprocessar] = useState(false);
	const [openLoader, setOpenLoader] = useState(false);
	const [openModal2fa, setOpenModal2fa] = useState(false);

	const closeModal2fa = () => setOpenModal2fa(false);

	const onChangeOpenDialogReprocessar = (open) => setOpenDialogReprocessar(open);

	const getStatusTranslate = (status) => {
		switch (status) {
			case 1:
				return 'pendente';
			case 2:
				return 'emitido';
			case 3:
				return 'expirado';
			case 4:
				return 'finalizado';
			case 5:
				return 'cancelado';
			default:
				break;
		}
	};

	const reprocessar = () => {
		// onChangeOpenDialogReprocessar(true);

		if (
			(booking.paymentTransaction === null ||
				booking.paymentTransaction === undefined) &&
			booking.cashTotalValue > 0
		) {
			onChangeOpenDialogReprocessar(true);
		} else {
			setOpenLoader(true);
			bookingsRepository
				.reprocessarPagamento(booking.bookingCode, {})
				.then((res) => {
					setOpenLoader(false);
					sessionStorage.setItem('@bookingCompleted', JSON.stringify(res));
					switch (res.status) {
						case 200:
							history.push('/busca/finalizado');
							break;
						case 201:
							if (res.data.auth2fa) {
								if (res.data.redirectAuth2fa !== null) {
									setOpenLoader(true);
									window.location.replace(res.data.redirectAuth2fa);
								} else {
									setOpenModal2fa(true);
								}
							} else {
								history.push('/busca/finalizado');
							}
							break;
						default:
							break;
					}
				})
				.catch((err) => {
					setOpenLoader(false);
					switch (err?.response?.status) {
						case 400:
							showAlert('Há algo de errado nos seus dados, verifique!');
							break;
						case 401:
							history.push('/session-expires');
							break;
						case 409:
							sessionStorage.setItem(
								'@bookingError',
								JSON.stringify(err.response.data.error)
							);
							sessionStorage.setItem(
								'reprocessar',
								JSON.stringify({ reprocessar: true })
							);
							history.push('/busca/booking-error');
							break;
						case 504:
							sessionStorage.setItem(
								'@bookingError',
								JSON.stringify('Tempo expirou, tente novamente!')
							);
							sessionStorage.setItem(
								'reprocessar',
								JSON.stringify({ reprocessar: true })
							);
							history.push('/busca/booking-error');
							break;
						default:
							showAlert('error', `Compra não processada, por favor, tente novamente!`);
							break;
					}
				});
		}
	};

	// const cancelarReserva = (locator) => {
	//   bookingsRepository
	//     .cancelBooking(locator)
	//     .then((res) => {
	//       showAlert('success', 'Reserva cancelada');
	//       window.location.reload();
	//     })
	//     .catch((err) => showAlert('error', 'Houve algum erro, tente novamente!'));
	// };

	const reenviarVoucher = (locator) => {
		bookingsRepository
			.reenviarVoucher(locator)
			.then((res) =>
				showAlert('success', 'Voucher reenviado, verifique seu e-mail!')
			)
			.catch((err) => showAlert('error', 'Houve algum erro, tente novamente!'));
	};

	const starsRatingInteger = Math.round(
		booking?.HotelReservationsType
			? booking?.HotelReservationsType?.HotelReservations[0].RoomStaysType
					.RoomStays[0].BasicPropertyInfo.Award.Rating
			: booking?.hotelBookingResponse?.HotelReservationsType?.HotelReservations[0]
					.RoomStaysType.RoomStays[0].BasicPropertyInfo.Award.Rating
	);

	const stars = Array(isNaN(starsRatingInteger) ? 0 : starsRatingInteger)
		.fill()
		.map((_, idx) =>
			booking?.HotelReservationsType
				? booking?.HotelReservationsType?.HotelReservations[0].RoomStaysType
						.RoomStays[0].BasicPropertyInfo.Award.Rating + idx
				: booking?.hotelBookingResponse?.HotelReservationsType?.HotelReservations[0]
						.RoomStaysType.RoomStays[0].BasicPropertyInfo.Award.Rating + idx
		);

	//   return booking !== [] ? ( isso retorna true toda vez por comparar referencia e não valor
	return (
		<S.BlankCard>
			<ReprocessarPagamento
				open={openDialogReprocessar}
				onClose={onChangeOpenDialogReprocessar}
				booking={booking}
			/>
			<Loader openLoader={openLoader} />
			<Auth2faModal
				open={openModal2fa}
				onClose={closeModal2fa}
				booking={{ data: booking }}
				type='reprocessar'
			/>
			<S.CardHeader>
				<S.Title>
					Código da reserva: {booking?.bookingCode}
					<span className='subtitle'>
						Data da reserva:{' '}
						{booking?.bookingDateStr !== undefined &&
							booking?.bookingDateStr !== 'Invalid Date' &&
							format(parseISO(booking?.bookingDateStr), 'dd/MM/yyyy', {
								locale: pt,
							})}
					</span>
				</S.Title>
				{booking?.statusId && (
					<S.StatusContainer>
						{booking?.statusId === 1 && (
							<ThemeButton className='reprocessar' onClick={reprocessar}>
								Reprocessar Pagamento
							</ThemeButton>
						)}
						{/* {booking?.statusId === 1 &&
              booking?.purchaseId !== null &&
              booking?.purchaseConfirmed === false && (
                <ThemeButton
                  className='reprocessar'
                  onClick={() => cancelarReserva(booking?.bookingCode)}
                >
                  Cancelar reserva
                </ThemeButton>
              )} */}
						{booking?.statusId === 2 && (
							<ThemeButton
								className='reprocessar'
								onClick={() => reenviarVoucher(booking?.bookingCode)}
							>
								Reenviar Voucher
							</ThemeButton>
						)}

						<S.Status className={`status ${getStatusTranslate(booking.statusId)}`}>
							{getStatusTranslate(booking.statusId)}
						</S.Status>
					</S.StatusContainer>
				)}
			</S.CardHeader>
			<S.Subtitle>Detalhes do hotel</S.Subtitle>

			<S.FlightSummaryCardDetails>
				<div>
					<img
						src={
							booking?.HotelReservationsType
								? booking?.HotelReservationsType?.HotelReservations[0]?.RoomStaysType
										.RoomStays[0].BasicPropertyInfo.ImageURL
								: booking?.hotelBookingResponse?.HotelReservationsType
										?.HotelReservations[0]?.RoomStaysType.RoomStays[0].BasicPropertyInfo
										.ImageURL
						}
						alt='imagem do hotel'
					/>
				</div>
				<S.HotelDetails item xs={12}>
					<div className='content'>
						<div className='stars'>
							{stars.map((star, index) => (
								<GradeIcon fontSize='small' key={index} />
							))}
						</div>
						<div className='hotel-details'>
							<span className='hotel-name'>
								{booking?.HotelReservationsType
									? booking?.HotelReservationsType?.HotelReservations[0]?.RoomStaysType
											?.RoomStays[0]?.BasicPropertyInfo.HotelRef.HotelName
									: booking?.hotelBookingResponse?.HotelReservationsType
											?.HotelReservations[0]?.RoomStaysType?.RoomStays[0]
											?.BasicPropertyInfo.HotelRef.HotelName}
							</span>
							<div>
								{/* <span className='hotel-rate'>{7.9}</span> */}
								<span className='hotel-rate-level'>{/* {'Bom'} ({120}) */}</span>
							</div>
						</div>
						<div className='location'>
							<span>
								<PlaceIcon fontSize='small' />
								{booking?.HotelReservationsType
									? booking?.HotelReservationsType?.HotelReservations[0].RoomStaysType
											.RoomStays[0].BasicPropertyInfo.Address.AddressLine
									: booking.hotelBookingResponse?.HotelReservationsType
											?.HotelReservations[0].RoomStaysType.RoomStays[0].BasicPropertyInfo
											.Address.AddressLine}
							</span>
						</div>
					</div>
					<div className='roomDetails'>
						<span>
							<b>
								Quarto:{' '}
								{booking?.HotelReservationsType
									? booking?.HotelReservationsType?.HotelReservations[0].RoomStaysType
											.RoomStays[0].RoomTypes[0].RoomName
									: booking.hotelBookingResponse?.HotelReservationsType
											?.HotelReservations[0].RoomStaysType.RoomStays[0].RoomTypes[0]
											.RoomName}
							</b>
						</span>
						<span>
							Descrição:{' '}
							{booking?.HotelReservationsType
								? booking?.HotelReservationsType?.HotelReservations[0].RoomStaysType
										.RoomStays[0].RoomTypes[0]?.RoomDescription?.Description
								: booking.hotelBookingResponse?.HotelReservationsType
										?.HotelReservations[0].RoomStaysType.RoomStays[0].RoomTypes[0]
										?.RoomDescription?.Description}
						</span>
						<span className='cancel-policies'>
							<b>Politica de cancelamento: </b>
							{booking?.HotelReservationsType
								? booking?.HotelReservationsType?.HotelReservations[0].RoomStaysType
										.RoomStays[0].RatePlans[0].CancelPenalties[0].PenaltyDescription
										.Description
								: booking.hotelBookingResponse?.HotelReservationsType
										?.HotelReservations[0].RoomStaysType.RoomStays[0].RatePlans[0]
										.CancelPenalties[0].PenaltyDescription.Description}
						</span>
					</div>
				</S.HotelDetails>
			</S.FlightSummaryCardDetails>

			<S.BottomInfos>
				{booking.passengers && (
					<S.Travellers>
						<S.Subtitle>VIAJANTES</S.Subtitle>
						{booking.passengers.map((passenger) => (
							<S.TravellerName>{`${passenger.name} ${passenger.lastName}`}</S.TravellerName>
						))}
					</S.Travellers>
				)}
				{!booking.pointsTotalValue && !booking.cashTotalValue && (
					<S.ValorTotal>
						Total: R${' '}
						{booking.BookingBySource[0].TotalFare.Total.toLocaleString('pt-BR')}
					</S.ValorTotal>
				)}
				{booking.pointsTotalValue ? (
					<S.ValorTotal>
						Valor em {coinAlias}: {booking.pointsTotalValue}
					</S.ValorTotal>
				) : (
					''
				)}
				{booking.cashTotalValue ? (
					<S.ValorTotal>
						{' '}
						Valor em R$: {booking.cashTotalValue.toLocaleString('pt-BR')}
					</S.ValorTotal>
				) : (
					''
				)}
			</S.BottomInfos>
			{booking.bookingExpirationDate && (
				<S.ExpirationDate>
					{booking.purchaseId !== null && booking.purchaseId !== undefined && (
						<S.Title>
							<span className='subtitle-locator'>
								Locator: {booking.locatorProvider}
							</span>
						</S.Title>
					)}
					{booking.statusId === 1 && (
						<span>
							Esta reserva expira em:{' '}
							{booking?.bookingExpirationDate !== undefined &&
								booking?.bookingExpirationDate !== 'Invalid Date' &&
								format(parseISO(booking?.bookingExpirationDate), 'dd/MM/yyyy HH:mm', {
									locale: pt,
								})}
						</span>
					)}
				</S.ExpirationDate>
			)}
		</S.BlankCard>
	);
};

export default HotelTravelsCard;
