import * as S from "./styles";
import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";
import BusCard from "../../SearchContent/BusList/BusCard";
import BusTraveling from "./../../../assets/icons/BusTraveling.svg";
import BusTravelingBack from "./../../../assets/icons/BusTravelingBack.svg";
import { ThemeButton } from "../../ThemeButton/styles";
import Bus from "../BusSummarySeats/Bus";

const BusSummaryCard = ({
  goingRoute,
  returnRoute,
  openServiceTermsAndPrivacyPolicyBusModal,
  selectedGoingSeats,
  setSelectedGoingSeats,
  selectedReturnSeats,
  setSelectedReturnSeats,
  searchState,
  result,
  seatMapIda,
  seatMapVolta,
  loading
}) => {
  return (
    <>
      {goingRoute && (
        <S.BlankCard>
          <div className="title title2">
            <S.Title>
              <div className="header">
                <img alt="bus going" src={BusTraveling} />
                Ida
                <span className="subtitle">
                  {goingRoute.departure !== undefined &&
                    goingRoute.departure !== "Invalid Date" &&
                    format(
                      parseISO(goingRoute.departure),
                      "EEEE dd MMMM yyyy",
                      {
                        locale: pt,
                      }
                    )}
                </span>
              </div>
              <ThemeButton
                onClick={() => {
                  openServiceTermsAndPrivacyPolicyBusModal(true);
                }}
                className="button-select"
              >
                Regras
              </ThemeButton>
            </S.Title>
          </div>
          <BusCard
          busRoute={goingRoute}
          result={result}
          />
          <Bus
            selectedSeats={selectedGoingSeats}
            setSelectedSeats={setSelectedGoingSeats}
            seatMap={seatMapIda}
            searchState={searchState}
            loading={loading}
          />
        </S.BlankCard>
      )}
      {returnRoute && returnRoute.key && (
        <S.BlankCard>
          <div className="title title2">
            <S.Title>
              <div className="header">
                <img alt="bus going" src={BusTravelingBack} />
                Volta
                <span className="subtitle">
                  {returnRoute.departure !== undefined &&
                    returnRoute.departure !== "Invalid Date" &&
                    format(
                      parseISO(returnRoute.departure),
                      "EEEE dd MMMM yyyy",
                      {
                        locale: pt,
                      }
                    )}
                </span>
              </div>
              <ThemeButton
                onClick={() => {
                  openServiceTermsAndPrivacyPolicyBusModal(true);
                }}
                className="button-select"
              >
                Regras
              </ThemeButton>
            </S.Title>
          </div>
          <BusCard
          busRoute={returnRoute}
          result={result}
          />
          <Bus
            selectedSeats={selectedReturnSeats}
            setSelectedSeats={setSelectedReturnSeats}
            seatMap={seatMapVolta}
            searchState={searchState}
            loading={loading}
          />
        </S.BlankCard>
      )}
    </>
  );
};

export default BusSummaryCard;
