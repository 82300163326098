import { useEffect, useState } from "react";
import * as S from "./styles";
import CheckboxFilter from "../../CheckboxFilter";
import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import { getSessionStorage } from "../../../utils/getSessionStorage";

const EntertainmentFilter = ({ changeFilter, loading }) => {
    const [checked, setChecked] = useState(false);
    const [locationsOptions, setLocationsOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [weekDaysOptions, setWeekDaysOptions] = useState([]);
    const [filteredActivities, setFilteredActivities] = useState({});

    const [state, setState] = useState({
        locations: [],
        category: [],
        weekDays: [],
    });

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const changeCheckboxLocations = (obj) => {
        let locations = state.locations;
        if (obj.checked) {
            locations.push(obj.label);
        } else {
            locations = locations.filter((l) => l !== obj.label);
        }
        setState({ ...state, locations });
    };

    const changeCheckboxCategory = (obj) => {
        let category = state.category;
        if (obj.checked) {
            category.push(obj.label);
        } else {
            category = category.filter((c) => c !== obj.label);
        }
        setState({ ...state, category });
    };

    const changeCheckboxWeekDays = (obj) => {
        let weekDays = state.weekDays;
        if (obj.checked) {
            weekDays.push(obj.label);
        } else {
            weekDays = weekDays.filter((w) => w !== obj.label);
        }
        setState({ ...state, weekDays });
    };

    useEffect(() => {
        const entertainment = getSessionStorage("entertainmentSearch");
        setFilteredActivities(entertainment);
        changeFilter(state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        let locationsOptions = new Set();
        let categoryOptions = new Set();
        let weekDaysOptions = new Set();

        const days = [
            "Domingo",
            "Segunda-feira",
            "Terça-feira",
            "Quarta-feira",
            "Quinta-feira",
            "Sexta-feira",
            "Sábado",
        ];

        if (filteredActivities && filteredActivities.activities) {
            filteredActivities.activities.forEach((activity) => {
                if (activity.locationFull) {
                    const [city, state] = activity.locationFull.split(", ");
                    if (city && state) {
                        locationsOptions.add(`${city} - ${state}`);
                    } else if (city) {
                        locationsOptions.add(city);
                    }
                }

                if (activity.activityId.startsWith("4")) {
                    categoryOptions.add("Transfer");
                } else if (activity.activityId.startsWith("5")) {
                    categoryOptions.add("Ingresso");
                } else if (activity.activityId.startsWith("6")) {
                    categoryOptions.add("Passeio");
                }

                if (activity.weekDaysList) {
                    const uniqueDays = new Set(
                        activity.weekDaysList
                            .split(";")
                            .filter((day) => day !== "")
                            .map((day) => parseInt(day, 10))
                    );

                    uniqueDays.forEach((day) => {
                        weekDaysOptions.add(days[day]);
                    });
                }
            });
        }

        const orderedWeekDaysOptions = [...weekDaysOptions].sort(
            (a, b) => days.indexOf(a) - days.indexOf(b)
        );

        setLocationsOptions([...locationsOptions]);
        setCategoryOptions([...categoryOptions]);
        setWeekDaysOptions(orderedWeekDaysOptions);

        if (state.weekDays.length > 0) {
            const filteredActivitiesByDays =
                filteredActivities.activities.filter((activity) => {
                    const activityDays = new Set(
                        activity.weekDaysList
                            .split(";")
                            .filter((day) => day !== "")
                            .map((day) => parseInt(day, 10))
                    );

                    return state.weekDays.some((selectedDay) =>
                        activityDays.has(days.indexOf(selectedDay))
                    );
                });

            changeFilter({ ...state, activities: filteredActivitiesByDays });
        } else {
            changeFilter({
                ...state,
                activities: filteredActivities.activities,
            });
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredActivities, state.weekDays]);

    return loading ? (
        <> </>
    ) : (
        <S.Wrapper>
            <div className="filter-group">
                <FormGroup>
                    <FormControlLabel
                        labelPlacement="start"
                        label={<S.FilterTitle>Combo promocional</S.FilterTitle>}
                        control={
                            <Switch
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{ "aria-label": "controlled" }}
                                color="default"
                            />
                        }
                    />
                </FormGroup>
            </div>
            <div className="filter-group">
                {locationsOptions.length > 0 && (
                    <S.FilterTitle>Localização</S.FilterTitle>
                )}
                {locationsOptions.map((label, index) => (
                    <CheckboxFilter
                        key={index}
                        label={label}
                        onChange={(obj) => changeCheckboxLocations(obj)}
                    />
                ))}
            </div>
            <div className="filter-group">
                {categoryOptions.length > 0 && (
                    <S.FilterTitle>Categoria</S.FilterTitle>
                )}
                {categoryOptions.map((label, index) => (
                    <CheckboxFilter
                        key={index}
                        label={label}
                        onChange={(obj) => changeCheckboxCategory(obj)}
                    />
                ))}
            </div>
            <div className="filter-group">
                {weekDaysOptions.length > 0 && (
                    <S.FilterTitle>Dias da semana</S.FilterTitle>
                )}
                {weekDaysOptions.map((label, index) => (
                    <CheckboxFilter
                        key={index}
                        label={label}
                        onChange={(obj) => changeCheckboxWeekDays(obj)}
                    />
                ))}
            </div>
        </S.Wrapper>
    );
};

export default EntertainmentFilter;
