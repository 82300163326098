import * as S from './styles';
import { Grid } from '@material-ui/core';
import React from 'react';

import ThemeButton from '../../ThemeButton';
import { useGlobal } from '../../../contexts/globalContext';
import { addFeeByDestinoFerias } from '../../../utils/addFeeByDestinoFerias';

const RoomRateOld = ({ rate, onChangeRate, rateSelected, buscaAtual }) => {
	const { user, signed } = useGlobal();

	const getValuesByUser = (val) => {
		let value = signed
			? val * user.catalog.catalogCurrencyConfig.conversionRate
			: val;

		if (user.campaign) value = addFeeByDestinoFerias(value, buscaAtual);

		return signed ? Math.ceil(Math.round(value)) : value;
	};
	return (
		<S.Wrapper
			className={`${
				rateSelected?.ratePlanID === rate?.ratePlanID &&
				rateSelected?.total.amountAfterTax === rate?.total.amountAfterTax
					? 'selected'
					: ''
			}`}
		>
			<Grid container spacing={1}>
				<Grid item xs={12} md={8}>
					<S.RatesContainer>
						{/* <div>
              <p>ratePlanID: {rate?.ratePlanID}</p>
              <p>amountAfterTax: {rate?.total.amountAfterTax}</p>
              <p>SELECTED ratePlanID: {rateSelected?.ratePlanID}</p>
              <p>
                SELECTED amountAfterTax: {rateSelected?.total.amountAfterTax}
              </p>
            </div> */}
						<S.LinkToTooltip>
							{rate?.ratePlans.cancelPenalties[0].penaltyDescription.name}
						</S.LinkToTooltip>
						<p>{rate?.ratePlans.cancelPenalties[0].penaltyDescription.description}</p>
						{rate?.ratePlans.mealsIncluded?.breakfast && (
							<>
								<S.LinkToTooltip>{rate?.ratePlans.mealsIncluded.name}</S.LinkToTooltip>
								<p>{rate?.ratePlans.mealsIncluded.description}</p>
							</>
						)}
					</S.RatesContainer>
				</Grid>

				<Grid item xs={12} md={4}>
					<S.ButtonContainer>
						<S.Value>
							{signed && !user?.guest && !user?.campaign?.disablePointActivity ? (
								<span>{getValuesByUser(rate?.total.amountAfterTax)} pts</span>
							) : (
								<span className='bold total'>
									{rate?.total.amountAfterTax.toLocaleString('pt-BR', {
										style: 'currency',
										currency: 'BRL',
									})}
								</span>
							)}
						</S.Value>
						<ThemeButton
							className='select-hotel-rate'
							onClick={() => onChangeRate(rate)}
						>
							Selecionar tarifa
						</ThemeButton>
					</S.ButtonContainer>
				</Grid>
			</Grid>
		</S.Wrapper>
	);
};

export default RoomRateOld;
