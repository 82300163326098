import { Grid } from "@material-ui/core";
import { rgba } from "polished";
import styled, { css } from "styled-components";

export const BusSegmentOption = styled.div`
  ${({ theme }) => css`
    height: 80px;
    font-family: "Poppins";
    margin-left: 5px;
    display: flex;
    align-items: center;
    max-width: 600px;
    width: 100%;
    
    .not-unique {
      border-bottom: 1px solid ${rgba("#707070", 0.17)};
    }

    .company-logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 25px;
        height: 25px;
      }
    }
    .bus-number {
      background-color: #e3e8ef;
      font-size: 8px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
    }

    .duracao {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-size: 9px;
      color: #023566;
    }

    .company-name {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-weight: 00;
      width: 100px;
    }

    .departure-date {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      font-size: 18px;
      color: #023566;
      padding-left: 14px;

      ${theme.breakpoints.down("sm")} {
        font-size: 14px;
      }
    }

    .arrival-date {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 18px;
      color: #023566;
      ${theme.breakpoints.down("sm")} {
        font-size: 14px;
      }
    }
  `}
`;

export const GridItemFlex = styled(Grid)`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;

    &.select-option {
      align-items: center;
      justify-content: center;
    }

    .plus-day {
      color: red !important;
      font-size: 14px;
    }

    .baggage {
      display: flex;

      svg {
        transform: scale(0.5);
      }

      .hand-baggage {
        width: 17px;
      }

      .other-baggage {
        padding-left: 7px;
        width: 19px;
      }
    }
  `}
`;
