import styled from 'styled-components';

export const Wrapper = styled.div`
  .brandeiras-aceitas {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    div {
      display: flex;
      align-items: center;
      width: 160px;
      justify-content: space-between;

      .visa {
        width: 50px;
      }
      .master {
        width: 40px;
      }
      .elo {
        width: 28px;
      }
      .amex {
        width: 35px;
      }
    }
  }
`;
