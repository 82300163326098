import { Button, IconButton, Typography, Divider as MuiDivider, Toolbar as MuiToolbar } from "@material-ui/core";
import styled, { css } from "styled-components";
import BgImage from "../../assets/img/bg_4.png";
import { isDiferentiatedBrands } from "../../themes/themes";
import { getBrandStorage } from "../../utils/getBrand";
import { getCampaignColors } from "../../utils/getCampaignColors";

const brand = getBrandStorage();
const campaignColors = getCampaignColors();

export const WrapperHome = styled.div`
	${({ theme }) => css`
		width: 100%;
		height: 400px;
		/* background-image:  */
		background-image: ${isDiferentiatedBrands(brand)
			? "none"
			: campaignColors?.home !== ""
			? `url(${campaignColors.home})`
			: campaignColors?.homeColor
			? "none"
			: `url(${BgImage})`};
		background-position: top;
		background-size: cover;
		background-repeat: no-repeat;
		display: flex;
		background-color: ${campaignColors.home ? "transparent" : campaignColors.homeColor};
		color: ${theme.palette.secondary.main};
		${theme.breakpoints.down("sm")} {
			height: 75px;
			background-color: ${isDiferentiatedBrands(brand)
				? theme.palette.secondary.white
				: campaignColors.headerColor
				? campaignColors.headerColor
				: "#FFF"};
			display: flex;
			color: ${isDiferentiatedBrands(brand) ? theme.palette.secondary.black : theme.palette.secondary.main};
			opacity: 1;
			background-image: none;
		}
	`}
`;

export const WrapperHomeBanner = styled.div`
	background-image: url(${(props) => props.bgImg});
	${({ theme }) => css`
		width: 100%;
		height: 530px;
		/* background-image:  */
		background-position: top;
		background-size: cover;
		background-repeat: no-repeat;
		display: flex;
		background-color: ${campaignColors.homeColor
			? campaignColors.homeColor
			: theme.palette.primary.main};
		color: ${theme.palette.secondary.main};
		${theme.breakpoints.down('sm')} {
			height: 75px;
			background-color: ${isDiferentiatedBrands(brand)
				? theme.palette.secondary.white
				: campaignColors.headerColor
				? campaignColors.headerColor
				: theme.palette.primary.main};
			display: flex;
			color: ${isDiferentiatedBrands(brand)
				? theme.palette.secondary.black
				: theme.palette.secondary.main};
			opacity: 1;
			background-image: none;
		}
	`}
`;

export const Wrapper = styled.div`
	${({ theme }) => css`
		width: 100%;
		height: ${isDiferentiatedBrands(brand) ? '65px' : '55px'};
		background-color: ${isDiferentiatedBrands(brand)
			? theme.palette.secondary.white
			: campaignColors.headerColor
			? campaignColors.headerColor
			: theme.palette.primary.main};
		display: flex;
		color: ${isDiferentiatedBrands(brand)
			? theme.palette.secondary.black
			: theme.palette.secondary.main};

		${theme.breakpoints.down('sm')} {
			height: ${isDiferentiatedBrands(brand) ? '65px' : '55px'};
			background-color: ${isDiferentiatedBrands(brand)
				? theme.palette.secondary.white
				: campaignColors.headerColor
				? campaignColors.headerColor
				: theme.palette.primary.main};
			opacity: 1;
		}

		${theme.breakpoints.down('sm')} {
			height: 55px;
		}
	`}
`;

export const Logo = styled.div`
	${({ theme }) => css`
		flex-grow: 1;
		width: 250px;
		font-family: "Poppins";
		font-weight: 600;
		color: ${theme.palette.secondary.main};
		text-align: left;

		img {
			/* margin-top: ${isDiferentiatedBrands(brand) ? "-3px" : "-20px"}; */
			width: 180px;
			${theme.breakpoints.down("sm")} {
				width: 130px;
			}
		}
	`}
`;

export const LogoMaterialHome = styled.div`
	${({ theme }) => css`
		flex-grow: 1;
		width: 70px;
		font-family: "Poppins";
		font-weight: 600;
		color: ${theme.palette.secondary.main};
		text-align: left;

		img {
			${theme.breakpoints.down("sm")} {
				width: 130px;
			}
			margin-top: 15px;
			width: ${isDiferentiatedBrands(brand) ? "170px" : "200px"};
		}
	`}
`;

export const LogoMaterial = styled.div`
	${({ theme }) => css`
		flex-grow: 1;
		width: 70px;
		font-family: "Poppins";
		font-weight: 600;
		color: ${theme.palette.secondary.main};
		text-align: left;

		img {
			margin-top: -3px;
			width: 170px;
			${theme.breakpoints.down("sm")} {
				width: 130px;
			}
		}
	`}
`;

export const MenuButtonDesktopHome = styled(Button)`
	${({ theme }) => css`
		text-transform: capitalize;
		font-family: 'Poppins';
		font-weight: 300;
		font-size: 15;
		color: ${isDiferentiatedBrands(brand)
			? theme.palette.secondary.black
			: campaignColors?.homeTextColor
			? campaignColors?.homeTextColor
			: theme.palette.secondary.text};

		margin-left: '5px';
		margin-top: -20px;
		// filter: ${campaignColors?.homeColor === '#17e9ddff' &&
		!campaignColors?.home === ''
			? 'invert(1)'
			: 'invert(0)'};
	`}
`;

export const MenuButtonDesktop = styled(Button)`
	${({ theme }) => css`
		text-transform: capitalize;
		font-family: 'Poppins';
		font-weight: 300;
		font-size: 15;
		color: ${isDiferentiatedBrands(brand)
			? theme.palette.secondary.black
			: campaignColors?.homeTextColor
			? campaignColors?.homeTextColor
			: theme.palette.secondary.text};

		margin-left: '5px';
		margin-top: -13px;
		// filter: ${campaignColors?.homeColor === '#17e9ddff' &&
		!campaignColors?.home === ''
			? 'invert(1)'
			: 'invert(0)'};
	`}
`;

export const MenuButtonMobile = styled(IconButton)`
	${({ theme }) => css`
  color:  ${theme.palette.primary.main}
  margin-top: -20px;
  `}
`;

export const Toolbar = styled(MuiToolbar)`
	${({ theme }) => css`
		color: ${isDiferentiatedBrands(brand)
			? theme.palette.secondary.black
			: theme.palette.secondary.main};

		display: 'flex';
		background-color: 'transparent';
		justify-content: 'space-between';

		.inactive {
			opacity: 77%;
		}
	`}
`;

export const DrawerContainer = styled.div`
	width: 170px;
`;

export const Divider = styled(MuiDivider)`
	${({ theme }) => css`
		opacity: 35%;
		width: 1px;
		height: 40px;
		background-color: ${isDiferentiatedBrands(brand)
			? theme.palette.secondary.black
			: '#FFF'};
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 20px;
	`}
`;

export const MobileDivider = styled(MuiDivider)`
	width: '100%';
`;

export const HeaderText = styled(Typography)`
	${({ theme }) => css`
		color: ${isDiferentiatedBrands(brand)
			? theme.palette.secondary.black
			: campaignColors?.homeTextColor
			? campaignColors?.homeTextColor
			: theme.palette.secondary.main};
		display: flex;
		justify-content: center;
		font-family: 'Poppins';
		flex-wrap: wrap;
		width: 100%;
		padding-left: 20%;
		padding-right: 30%;
		padding-bottom: 100px;
		// filter: ${campaignColors?.homeColor === '#17e9ddff' &&
		!campaignColors?.home === ''
			? 'invert(1)'
			: 'invert(0)'};

		${theme.breakpoints.down('md')} {
			padding-right: 20%;
		}
	`}
`;

export const HeaderTextLink = styled('a')`
	${({ theme }) => css`
		text-decoration: none !important;
	`}
`;

export const HeaderBanner = styled.img`
	${({ theme }) => css`
		display: flex;
		justify-content: center;
		height: 380px;
		width: 100%;
		object-fit: cover !important;
	`}
`;

export const UserSignedInfo = styled.div`
	${({ theme }) => css`
		filter: ${campaignColors?.homeColor === '#17e9ddff' &&
		!campaignColors?.home === ''
			? 'invert(1)'
			: 'invert(0)'};
		margin-top: -15px;
		font-family: 'Poppins';
		cursor: pointer;
		color: ${isDiferentiatedBrands(brand)
			? theme.palette.secondary.black
			: campaignColors?.homeTextColor
			? campaignColors?.homeTextColor
			: theme.palette.secondary.text};

		:hover {
			opacity: 0.7;
		}

		.HeaderUserSigned {
			font-weight: regular;
			font-size: 15px;
		}

		.HeaderSaldoPontos {
			margin-top: -5px;
			align-items: flex-end;
			display: flex;

			label {
				font-weight: bold;
				font-size: 11px;
				margin-right: 5px;
				margin-bottom: 4px;
			}

			span {
				font-size: 20px;
				opacity: 77%;
			}

			.pontos {
				font-size: 10px;
				margin-left: 5px;
				margin-bottom: 4px;
			}
		}
	`}
`;
