import styled, { css } from 'styled-components';

export const Separator = styled.div`
  ${({ theme }) => css`
    width: 100%;

    div.dashed {
      border-top: 1px dashed #707070;
    }

    div.solid {
      border-top: 1px solid #707070;
    }

    div.thin {
      opacity: 0.25;
    }

    div.extra-thin {
      opacity: 0.15;
    }

    div {
      margin-left: ${(props) => (props.margin ? props.margin + 'px' : 0)};
      margin-right: ${(props) => (props.margin ? props.margin + 'px' : 0)};
    }
  `}
`;
