import * as React from "react";

const PolicyIcon = (props) => {
  return (
    <svg {...props} width={22} height={21} viewBox="0 0 22 21" fill="none">
    <path d="M21.8301 4.71692C21.7891 4.55372 21.7243 4.39728 21.6089 4.28187L17.5075 0.180223C17.3921 0.0648047 17.2356 0 17.0724 0H7.63867C6.62095 0 5.79297 0.827982 5.79297 1.8457V9.96015C3.02827 10.2672 0.871094 12.6177 0.871094 15.4629C0.871094 18.5161 3.35504 21 6.4082 21H19.9434C20.9611 21 21.7891 20.172 21.8301 19.1543V4.71692ZM17.6875 4.10156V2.10053L19.0242 3.43727L19.6885 4.10156H17.6875ZM6.4082 19.7695C4.03352 19.7695 2.10156 17.8376 2.10156 15.4629C2.10156 13.0882 4.03352 11.1562 6.4082 11.1562C8.78288 11.1562 10.7148 13.0882 10.7148 15.4629C10.7148 17.8376 8.78288 19.7695 6.4082 19.7695ZM20.5586 19.1543C20.5586 19.4935 20.2826 19.7695 19.9434 19.7695H9.88424C10.9901 18.8752 11.7459 17.5646 11.911 16.0781H18.3027C18.6425 16.0781 18.918 15.8027 18.918 15.4629C18.918 15.1231 18.6425 14.8477 18.3027 14.8477H11.9109C11.8106 13.9445 11.4924 13.1062 11.0099 12.3867H18.3027C18.6425 12.3867 18.918 12.1113 18.918 11.7715C18.918 11.4317 18.6425 11.1562 18.3027 11.1562H9.88424C9.08357 10.5088 8.09961 10.0797 7.02344 9.96015V1.8457C7.02344 1.50646 7.29943 1.23047 7.63867 1.23047H16.457V4.7168C16.457 5.05657 16.7325 5.33203 17.0723 5.33203H20.5586V19.1543Z" fill="#7F7F7F"/>
    </svg>
  );
};

export default PolicyIcon;
