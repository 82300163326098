import styled, { css } from "styled-components";
import { isDiferentiatedBrands } from "../../../../themes/themes";
import { getBrandStorage } from "../../../../utils/getBrand";
import { Grid } from "@material-ui/core";

const brand = getBrandStorage();

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 230px;
    height: 300px;
    max-height: -webkit-fill-available;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${isDiferentiatedBrands(brand)
      ? theme.palette.secondary.white
      : "#FFF"};
    border-radius: 6px !important;
    box-shadow: 0px 1px 4px #15223214;
    opacity: 1;
    margin-top: 15px;
    padding-bottom: 15px;
    font-family: "Poppins";
    padding: 4px;

    ${theme.breakpoints.down("xs")} {
      flex-wrap: wrap;
    }

    .label-ida {
      font-weight: 400;
      font-size: 20px;
      text-align: center;
    }

    .label-ida-e-volta {
      font-weight: 400;
      font-size: 20px;
      text-align: center;
    }

    .total-points {
      font-weight: 700;
      font-size: 35px;
      text-align: center;
    }

    .label-total,
    .label-points {
      font-weight: 400;
      font-size: 18px;
      text-align: center;
    }

    .single-points {
      font-weight: 700;
      font-size: 25px;
      text-align: center;
    }
  `}
`;

export const CardContent = styled.div`
  ${({ theme }) => css`
    height: 100%;
    font-family: "Poppins";
    color: #023566;
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    max-height: 80%;
  `}
`;

export const GridItemFlex = styled(Grid)`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: center;
  `}
`;
