import { Grid } from "@material-ui/core";
import styled, { css } from "styled-components";
import { Calendar } from "react-date-range";

export const Wrapper = styled.div`
    ${({ theme }) => css`
        background-color: #f2f2f2;
        font-family: "Poppins";
        margin-bottom: 50px;
        width: 100% !important;

        .spacing {
            margin-top: 50px;
        }

        .calendar {
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            background-color: #fff;
            font-family: "Poppins";
            width: 100%;
            height: 550px;
            border-radius: 4px;
            box-shadow: 0px 1px 4px #15223214;

            .top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .price {
                    color: ${theme.palette.primary.main};
                    font-size: 22px;
                    font-weight: 700;
                    height: 26px;
                    line-height: 26px;
                }

                .label1 {
                    font-size: 15px;
                    font-weight: 600;
                    height: 22px;
                    line-height: 22px;
                    white-space: nowrap;
                }

                .label2 {
                    color: #333;
                    font-size: 12px;
                    font-weight: 500;
                    height: 22px;
                    line-height: 22px;
                    white-space: nowrap;
                }
            }

            .mid {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                justify-content: flex-end;
                position: relative;

                .separator {
                    border-bottom-style: dashed;
                    border-bottom-width: 1px;
                    border-color: #666666;
                    height: 28px;
                    width: 90px;
                    left: 290px;
                    position: absolute;

                    .cancelation {
                        color: #555555;
                        font-size: 12px;
                        font-weight: 600;
                        height: 15px;
                        left: 1px;
                        top: 10px;
                        letter-spacing: 0.08px;
                        line-height: normal;
                        position: absolute;
                        white-space: nowrap;
                        cursor: pointer;

                        .label3 {
                            opacity: 67%;
                            color: #555;
                            font-size: 12px;
                            font-weight: 500;
                            height: 22px;
                            line-height: 22px;
                            white-space: nowrap;
                        }

                        .tooltiptext {
                            width: 200px;
                            visibility: hidden;
                            background-color: #f9f9f9;
                            color: #333;
                            text-align: left;
                            border-radius: 8px;
                            padding: 10px;
                            position: absolute;
                            z-index: 1;
                            bottom: 150%;
                            left: 50%;
                            transform: translateX(-50%);
                            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                            opacity: 0;
                            transition: opacity 0.3s ease;
                            font-size: 0.9rem;
                            line-height: 1.5;
                            word-wrap: break-word;
                            white-space: normal;
                        }

                        .tooltiptext h3 {
                            font-size: 14px;
                            margin: 0 0 5px 0;
                            white-space: normal;
                        }

                        .tooltiptext p {
                            font-size: 12px;
                            margin: 0;
                            white-space: normal;
                        }

                        .tooltiptext i {
                            content: "";
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            transform: translateX(-50%);
                            border-width: 10px;
                            border-style: solid;
                            border-color: #f9f9f9 transparent transparent
                                transparent;
                        }

                        &:hover .tooltiptext {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }

            .quantity-top {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                position: relative;
                left: 10px;
                margin-bottom: 8px;

                .label1 {
                    color: #333;
                    font-size: 14px;
                    font-weight: 600;
                    height: 22px;
                    line-height: 22px;
                    white-space: nowrap;
                }

                .label2 {
                    color: ${theme.palette.primary.main};
                    font-size: 12px;
                    font-weight: 400;
                    height: 22px;
                    line-height: 22px;
                    white-space: nowrap;
                }
            }

            .bottom {
                display: flex;
                flex-direction: column;
                width: 100%;

                .passenger-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 8px;

                    .label {
                        color: #333;
                        font-size: 14px;
                        font-weight: 600;
                        height: 22px;
                        line-height: 22px;
                        white-space: nowrap;
                    }

                    .counter {
                        color: #333;
                        font-size: 16px;
                        font-weight: 700;
                        height: 22px;
                        line-height: 22px;
                        white-space: nowrap;
                        margin-left: 10px;
                    }
                }
            }
        }

        .about {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;
            margin-right: 50px;

            .text {
                font-size: 14px;
                font-weight: 300;
                line-height: 22px;
                color: #797979;
                margin-right: 50px;
            }

            .tags {
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin-right: 50px;
                height: 200px;
                width: 100%;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-color: #d7d7d7;

                img {
                    width: 45px;
                    height: 45px;
                    margin-right: 20px;
                }

                .left,
                .right {
                    display: flex;
                    flex-direction: column;
                    width: 48%;
                }

                .left div,
                .right div {
                    display: flex;
                    align-items: space-around;
                    margin-top: 7px;
                    margin-bottom: 7px;

                    .info {
                        flex-direction: column;
                        align-items: flex-start;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 0px;
                        margin-bottom: 0px;

                        .top {
                            color: ${theme.palette.primary.main};
                            font-size: 14px;
                            font-weight: 500;
                            height: 18px;
                            line-height: 22px;
                            white-space: nowrap;
                            letter-spacing: 0.1px;
                        }

                        .bottom {
                            color: #555;
                            font-size: 13px;
                            font-weight: 400;
                            height: 22px;
                            line-height: 22px;
                            white-space: wrap;
                            letter-spacing: 0px;
                        }
                    }
                }

                .left div span,
                .right div span {
                    display: inline-block;
                }
            }
        }

        .highlights {
            margin-bottom: 20px;
            margin-right: 50px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-bottom: 1px solid;
            border-color: #d7d7d7;

            .title {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
            }

            .highlights-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                width: 100%;
                margin-bottom: 20px;
            }

            .highlight-item {
                display: flex;
                align-items: center;
            }

            .highlight-item img {
                margin-right: 10px;
            }

            .highlight-item p {
                width: auto;
                margin: 0;
            }
        }

        .included {
            margin-bottom: 20px;
            margin-right: 50px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .title {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
            }

            .description {
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                color: #555;
                height: 100%;
            }

            .list-container {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;
            }

            .includes,
            .excludes {
                width: 48%;
            }

            .item {
                display: flex;
                align-items: flex-start;
                margin-bottom: 10px;
            }

            .item img {
                margin-right: 10px;
                width: 20px;
                height: 20px;
            }
        }

        .box {
            background-color: #333;
            padding: 20px;
            border-radius: 8px;
            color: #fff;
            margin-bottom: 20px;
            margin-right: 50px;

            .title {
                font-size: 16px;
                font-weight: 400;
                color: #aaa;
                height: 28px;
                line-height: 28px;
                letter-spacing: 0.5px;
            }

            .highlight {
                color: ${theme.palette.primary.main};
            }

            .description {
                font-size: 13px;
                font-weight: 300;
                margin-bottom: 20px;
                color: #aaa;
                height: 44px;
                line-height: 22px;
                letter-spacing: 0px;
            }

            .items {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    width: 100%;

    img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
        flex-shrink: 0;
    }

    .item {
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 20px;
        flex-grow: 1;
        white-space: pre-wrap; 
        word-wrap: break-word;
    }
}
        }
    `}
`;

export const Title = styled.span`
    ${({ theme }) => css`
        font-size: 28px;
        font-weight: 800;
        color: #545454;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        margin-top: 50px;
    `}
`;

export const Price = styled.div`
    width: 98%;
    height: 70px;
    background: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

export const Quantity = styled.div`
    width: 98%;
    height: auto;
    background: #fff;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .top {
        display: flex;
        align-items: center;
        padding: 0 10px;
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
    }
`;

export const DateCalendar = styled(Calendar)`
    ${({ theme }) => css`
        width: 98%;
        background: #fff;
        border: 1px solid #d5d5d5;
        border-radius: 4px;

        .MuiInputBase-root {
            color: ${theme.palette.primary.main};
        }

        .rdrMonth,
        .rdrMonthsVertical {
            position: relative;
            background: #fff;
            width: 100%;
        }

        ${theme.breakpoints.down("sm")} {
            zoom: 77%;
        }

        .rdrSelected,
        .rdrStartEdge,
        .rdrEndEdge {
            background-color: ${theme.palette.primary.main} !important;
            color: #fff !important;
            font-weight: 700 !important;
            font-size: 22px !important;
            border-radius: 0px !important;
            border: 4px solid ${theme.palette.primary.main} !important;
        }

        .rdrDayNumber span {
            color: #333 !important;
            font-weight: 400;
            font-size: 16px;
        }

        .rdrDayDisabled .rdrDayNumber span {
            color: #d3d3d3 !important;
            font-weight: 400;
            font-size: 16px;
        }

        .rdrDayStartPreview,
        .rdrDayInPreview,
        .rdrDayEndPreview {
            border: none;
            background: none;
        }

        .rdrDayHovered .rdrDayNumber span {
            background: none;
            border-radius: 0px;
        }
    `}
`;

export const GridFlexible = styled(Grid)``;

export const AboutContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 10px;

    span {
        font-size: 20px;
        font-weight: 600;
        height: 28px;
        line-height: 28px;
        color: #333;
    }
`;

export const AvailabilityBox = styled.div`
    ${({ theme }) => css`
        max-height: 400px;
        height: 100%;
        font-family: "Poppins";
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);

        .tour-info {
            width: 60%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-right: 1px solid #e0e0e0;
        }

        .tour-type {
            text-align: left;
            justify-content: center;
            padding-bottom: 20px;
        }

        .tour-type-name {
            display: block;
            font-size: 16px;
            font-weight: 600;
            color: ${theme.palette.primary.main};
            margin-bottom: 5px;
        }

        .tour-max-passengers {
            display: block;
            font-size: 14px;
            color: #828282;
        }

        .return-info {
            text-align: left;
            padding-top: 10px;
            padding-bottom: 10px;
            border-top: 1px solid #e0e0e0;

            h3 {
                text-align: center;
                font-size: 16px;
                font-weight: 600;
                color: #333;
                justify-content: center;
            }

            .dates {
                display: flex;
                align-items: center;
                justify-content: center;

                .date-arrival,
                .date-return {
                    flex: 1;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                    .MuiInputBase-fullWidth,
                    .MuiFormControl-fullWidth {
                        width: 150px;    
                        height: 24px;
                        font-size: 14px;
                        font-family: "Poppins";
                        color: #828282;
                    }

                    .MuiOutlinedInput-adornedStart {
                        padding-left: 4px;
                    }

                    .MuiInputAdornment-positionStart {
                        margin-right: 12px;
                        height: 16px;

                        img {
                            height: inherit;
                        }
                    }

                    .MuiOutlinedInput-input {
                        padding: 0;
                    }
                }
            }

            .tour-times {
                margin-top: 10px;
                padding-top: 10px;
                align-items: center;
                display: flex;
                justify-content: space-evenly;
    
                .tour-time-button {
                    background-color: #ffc107;
                    color: white;
                    border: none;
                    padding: 10px 20px;
                    border-radius: 5px;
                    cursor: pointer;
                }
    
                .tour-time-button.selected {
                    background-color: #ffa000;
                    font-weight: bold;
                }
    
                .tour-time-button:hover {
                    background-color: #ffb300;
                }
            }
        }

        .tour-date {
            padding-top: 20px;
            border-top: 1px solid #e0e0e0;
        }

        .tour-date-label {
            display: block;
            font-size: 16px;
            font-weight: 600;
            color: #333;
        }

        .tour-date-value {
            font-size: 14px;
            color: #828282;
        }

        .luggage-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 10px;

            span {
                font-size: 14px;
                color: #828282;
            }
        }

        .price-and-cancellation {
            width: 40%;
            height: 100%;
            text-align: right;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .price-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding-bottom: 10px;
            padding-left: 20px;
            border-bottom: solid 1px #e0e0e0;
        }

        .price-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-right: 20px;
            justify-content: center;
            border-right: solid 1px #e0e0e0;
        }

        .price-label {
            font-size: 14px;
            color: #828282;
            margin-bottom: 5px;
        }

        .price-value {
            font-size: 20px;
            font-weight: 600;
            color: ${theme.palette.primary.main};
        }

        .passenger-summary {
            padding-left: 20px;
            font-size: 14px;
            color: #828282;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            span {
                display: block;
                margin-bottom: 5px;
            }
        }
        
        .price-summary {
            display: flex;
            flex-direction: row;      
            align-items: center;      
        }

        .select-button {
            background-color: ${theme.palette.primary.main};
            color: #fff;
            border: none;
            padding: 10px 20px;
            font-size: 14px;
            font-weight: 600;
            border-radius: 4px;
            cursor: pointer;
            margin-top: 20px;
            margin-bottom: 20px;
            transition: background-color 0.3s ease;
        }

        .select-button:hover {
            background-color: ${theme.palette.primary.main};
        }

        .cancellation-policy {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            cursor: pointer;
        }

        .policy-icon {
            margin-right: 5px;
        }

        .policy-label {
            font-size: 14px;
            color: #828282;
        }

        .no-availability {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: stretch;
            flex-direction: column;
            background-color: #f7f7f7;
            height: 150px;
            padding: 20px;
            border-radius: 8px;
            text-align: center;
            color: #999;
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }
    `}
`;

export const Container = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    padding-bottom: 40px;
`;
