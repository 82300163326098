import React from 'react';

import { withRouter } from 'react-router-dom';
import SearchContent from '../../components/SearchContent';

function Result({ type, searchState, buscaAtual }) {
  return (
    <div style={{ backgroundColor: '#f2f2f2' }}>
      <SearchContent searchState={searchState} buscaAtual={buscaAtual} />
    </div>
  );
}

export default withRouter(Result);
