import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { getBrandStorage } from '../../../utils/getBrand';
import { isDiferentiatedBrands } from '../../../themes/themes';
import { Typography } from '@material-ui/core/';
const brand = getBrandStorage();
export const Wrapper = styled.div`
	${({ theme }) => css`
    width: 100%;
    color: #ffffff;
    font-family: 'Poppins';


    .submit1 {
      width: 100% !important;
      height: 100% !important;
    }

    .idavolta-bus-control {
      margin-bottom: 30px;
      margin-top: 5px;
      ${theme.breakpoints.down('xs')} {
        display: flex;
        justify-content: space-evenly;
      }
    }

    .MuiSvgIcon-root {
      fill: white;
    }

    .origem-destino-bus-control {
      display: flex;
      justify-content: space-between;

      .label-input-info {
        padding: 0;
        margin: 0;
        padding-bottom: 5px;
        margin-top: -23px;
        font-size: 12px;
      }

      ${theme.breakpoints.up('md')} {
        .search-divider {
          border-left 1px solid ${rgba(
                    isDiferentiatedBrands(brand)
                      ? theme.palette.secondary.white
                      : theme.palette.secondary.main,
                    0.4
                  )}
        }
      }
      
      .MuiGrid-container {
        display: flex;
        justify-content: space-evenly;
      }

      a {
      text-decoration: none;
      }
    }
  `}
`;
export const CustomTypography = styled(Typography)`
	${({ theme }) => css`
		color: ${theme.palette.secondary.main};
	`}
`;