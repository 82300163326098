import React from "react";
import { Helmet } from "react-helmet";

export default function PortalHead() {
	const title = process.env.REACT_APP_PORTAL_TITLE ?? "Destino Férias";
	const description =
		process.env.REACT_APP_PORTAL_DESCRIPTION ??
		"Plataforma on-line de viagens e experiências para programas de fidelidade e campanhas de incentivo.";
	const type = "website";

	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />

			<meta property="og:type" content={type} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />

			<meta name="twitter:card" content={type} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
		</Helmet>
	);
}
