import React from 'react';
import * as S from './styles';

import { useGlobal } from '../../contexts/globalContext';
import { LinearProgress, Typography } from '@material-ui/core';

function GlobalLoader({ loading, loadingType }) {
  const { globalLoader} = useGlobal()
  return (
    <S.Dialog
      type={'expired'}
      open={globalLoader}
      // onClose={() => setLoading(false)}
    >
      <div>
        {/* <img src={getLogos('desktop')} alt='logo' /> */}
        Carregando...
      </div>

      <LinearProgress color='primary'/>

      {loadingType === 'expired' && (
        <Typography variant='h7' marginTop={2} textAlign='center'>
          Sua sessão expirou
        </Typography>
      )}
    </S.Dialog>
  );
}

export default GlobalLoader;
