import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: 50px 0px;

    div {
      margin-top: 10px;

      img {
        width: 100%;
        height: 200px;
        object-fit: contain;
      }
      /* .index-1,
      .index-3 {
        object-fit: cover;
        width: 38%;
        height: 250px;
      }

      .index-2 {
        object-fit: cover;
        width: 20%;
        height: 250px;
      }

      ${theme.breakpoints.down('lg')} {
      }
      ${theme.breakpoints.down('md')} {
      }
      ${theme.breakpoints.down('sm')} {
      }
      ${theme.breakpoints.down('xs')} {
      }

      display: flex;
      justify-content: space-between; */
    }
  `}
`;
