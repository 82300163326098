import * as S from './styles';
import { Grid } from '@material-ui/core';

import ThemeButton from '../ThemeButton';
import { useHistory } from 'react-router-dom';

import CheckIcon from '@material-ui/icons/Check';
import RoomRateOld from './RoomRateOld';
import { useState } from 'react';
import { useGlobal } from '../../contexts/globalContext';
import CarouselComponent from '../Carousel';
import { addFeeByDestinoFerias } from '../../utils/addFeeByDestinoFerias';
import RegisterModal from '../RegisterModal';

const RoomOld = ({
	roomName,
	images,
	description,
	nights,
	policies,
	roomDetails,
	hotel,
	hotelDetails,
	searchState,
	roomRates,
	buscaAtual,
	room,
}) => {
	const { user, signed } = useGlobal();

	const roomImages =
		roomDetails?.multimediaDescriptionsType.multimediaDescriptions[1]?.imageItemsType?.imageItems.map(
			(image) => image.url?.address
		);

	const getValuesByUser = (val) => {
		let value = signed
			? val * user.catalog.catalogCurrencyConfig.conversionRate
			: val;

		if (user.campaign) value = addFeeByDestinoFerias(value, buscaAtual);

		return signed ? Math.ceil(Math.round(value)) : value;
	};

	let history = useHistory();

	const [rateSelected, setRateSelected] = useState(room.roomRates[0]);
	const [isOpenRegisterModal, setIsOpenRegisterModal] = useState(false);

	const onChangeRate = (rate) => setRateSelected(rate);

	const handleRegisterModal = () => setIsOpenRegisterModal((prev) => !prev);
	const handleChooseRoom = () => {
		sessionStorage.setItem(
			'@roomSelected',
			JSON.stringify({
				roomName,
				images,
				description,
				nights,
				totalTax: rateSelected?.total.amountAfterTax,
				policies,
				roomDetails,
				hotel,
				hotelDetails,
				searchState,
				rateSelected,
				room,
			})
		);
		if (user?.guest || user?.travelVoucher) {
			return handleRegisterModal();
		}
		history.push('/busca/resumo');
	};
	return (
		<S.Wrapper>
			<RegisterModal
				open={isOpenRegisterModal}
				onClose={handleRegisterModal}
				travelVoucherUser={user?.travelVoucher}
			/>
			<Grid container spacing={2}>
				<S.GridFlexible item xs={12} lg={3}>
					{/* <S.RoomImage src={images[0]} alt='room image' />   */}
					<S.RoomImageContainer>
						<CarouselComponent buscaAtual={buscaAtual} type='card-room' images={roomImages} />
					</S.RoomImageContainer>
					<S.Amenities>
						{roomDetails?.amenitiesType?.roomAmenities.map((amenitie) => (
							<span>
								<CheckIcon fontSize='small' />
								{amenitie.roomAmenity}
							</span>
						))}
					</S.Amenities>
				</S.GridFlexible>
				<S.GridFlexible item xs={12} lg={6}>
					<S.RoomName>{roomName}</S.RoomName>
					<S.DescriptionContainer>
						<S.Description>Descrição</S.Description>
						<span>{description}</span>
					</S.DescriptionContainer>
					<S.RoomRatesContainer>
						{roomRates.map((rate) => (
							<RoomRateOld
								rate={rate}
								onChangeRate={onChangeRate}
								rateSelected={rateSelected}
								buscaAtual={buscaAtual}
							/>
						))}
					</S.RoomRatesContainer>
				</S.GridFlexible>
				<S.GridFlexible item xs={12} lg={3}>
					<S.PriceDetails>
						<S.TotalValue>
							{signed && !user?.guest && !user?.campaign?.disablePointActivity ? (
								<span>{getValuesByUser(rateSelected?.total.amountAfterTax)} pts</span>
							) : (
								<span className='bold total'>
									{(rateSelected?.total.amountAfterTax).toLocaleString('pt-BR', {
										style: 'currency',
										currency: 'BRL',
									})}
								</span>
							)}
						</S.TotalValue>
						<S.NightsQty>
							por {nights} noite(s) x {searchState?.qtRooms} quarto(s)
						</S.NightsQty>
						<S.DailyValue>
							{signed && !user?.guest && !user?.campaign?.disablePointActivity ? (
								<span>
									{getValuesByUser(rateSelected?.total.amountAfterTax / nights)} pts
								</span>
							) : (
								<span className='bold total'>
									{(rateSelected?.total.amountAfterTax / nights).toLocaleString(
										'pt-BR',
										{
											style: 'currency',
											currency: 'BRL',
										}
									)}
								</span>
							)}
							/diária
						</S.DailyValue>

						{policies &&
							policies.map((policie) => (
								<S.Policies>
									<S.PoliciesTitle>Politicas de cancelamento</S.PoliciesTitle>
									<p>
										<strong>Nome: </strong>
										{policie.name}
										<br />
										<strong>Descrição: </strong>
										{policie.description}
										<br />
										<strong>Valor (R$): </strong>
										{policie.value}
										<br />
										<strong>Valor (%): </strong>
										{policie.percent}
									</p>
								</S.Policies>
							))}
						<ThemeButton
							onClick={() => {
								handleChooseRoom();
							}}
							className='choose-hotel'
						>
							Escolher Quarto
						</ThemeButton>
					</S.PriceDetails>
				</S.GridFlexible>
			</Grid>
		</S.Wrapper>
	);
};

export default RoomOld;
