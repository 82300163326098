/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import * as S from "./styles";
import CheckboxFilter from "../../CheckboxFilter";
import { FormControlLabel, Switch } from "@material-ui/core";

const BusFilter = ({ changeFilter, searchState, results, loading }) => {
	const [stationsOptions, setStationsOptions] = useState([]);
	const [classesOptions, setClassesOptions] = useState([]);
	const [companiesOptions, setCompaniesOptions] = useState([]);

	const { busAvailability } = results;
	const [state, setState] = useState({
		stations: [],
		classes: [],
		companies: [],
	});

	const changeCheckboxStations = (obj) => {
		let stations = state.stations;
		if (obj.checked) {
			stations.push(obj.label);
		} else {
			stations = stations.filter((s) => s !== obj.label);
		}
		setState({ ...state, stations });
	};

	const changeCheckboxClasses = (obj) => {
		let classes = state.classes;
		if (obj.checked) {
			classes.push(obj.label);
		} else {
			classes = classes.filter((s) => s !== obj.label);
		}
		setState({ ...state, classes });
	};

	const changeCheckboxCompanies = (obj) => {
		let companies = state.companies;
		if (obj.checked) {
			companies.push(obj.label);
		} else {
			companies = companies.filter((s) => s !== obj.label);
		}
		setState({ ...state, companies });
	};

	useEffect(() => {
		changeFilter(state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, setState]);

	useEffect(() => {
		let busStationOptions = new Set();
		let classesOptions = new Set();
		let companiesOptions = new Set();

		if (busAvailability) {
			busAvailability.forEach((route) => {
				route.buses.forEach((bus) => {
					busStationOptions.add(bus.origin);
					busStationOptions.add(bus.destination);
					classesOptions.add(bus.company.type);
					companiesOptions.add(bus.company.name);
				});
			});
		}
		setStationsOptions([...busStationOptions]);
		setClassesOptions([...classesOptions]);
		setCompaniesOptions([...companiesOptions]);
	}, [busAvailability]);

	return loading ? (
		<> </>
	) : (
		<S.Wrapper>
			<div className="filter-group">
				{stationsOptions.length > 0 && <S.FilterTitle>Rodoviárias</S.FilterTitle>}
				{stationsOptions.map((label, index) => (
					<CheckboxFilter key={index} label={label} onChange={(obj) => changeCheckboxStations(obj)} />
				))}
			</div>
			<div className="filter-group">
				{classesOptions.length > 0 && <S.FilterTitle>Classes</S.FilterTitle>}
				{classesOptions.map((label, index) => (
					<CheckboxFilter key={index} label={label} onChange={(obj) => changeCheckboxClasses(obj)} />
				))}
			</div>
			<div className="filter-group">
				{companiesOptions.length > 0 && <S.FilterTitle>Viações</S.FilterTitle>}
				{companiesOptions.map((label, index) => (
					<CheckboxFilter key={index} label={label} onChange={(obj) => changeCheckboxCompanies(obj)} />
				))}
			</div>
			{searchState.idavolta && busAvailability?.length > 0 && (
				<div className="filter-group">
					<S.FilterTitle>Rodoviárias</S.FilterTitle>
					<FormControlLabel
						control={<Switch checked={state.sameStation} onChange={() => setState({ ...state, sameStation: !state.sameStation })} />}
						label={<S.ThinText>Ida e Volta na mesma rodoviária</S.ThinText>}
					/>
				</div>
			)}
		</S.Wrapper>
	);
};

export default BusFilter;
