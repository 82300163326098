import api from "./api";

export const useCampaignService = () => {
	const getCampaign = async (campaignCode) => {
		try {
			if (!campaignCode) {
				return null;
			}
			var result = await api.get(`api/campaign/${campaignCode}`);
			if (result?.data) {
				return result.data;
			}

			return null;
		} catch (e) {
			console.error(e);
		}
	};

	return {
		getCampaign,
	};
};
