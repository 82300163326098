import React from "react";

import { withRouter } from "react-router-dom";
import SummaryContent from "../../components/SummaryContent";

import { getSessionStorage } from "../../utils/getSessionStorage";

function Summary({ type, searchState, buscaAtual }) {
	const hotelSearchResultsInfos = getSessionStorage('hotelSearchResultsInfos');
	return (
		<div style={{ backgroundColor: "#f2f2f2" }}>
			<SummaryContent searchState={searchState} buscaAtual={buscaAtual} hotelSearchResultsInfos={hotelSearchResultsInfos} />
		</div>
	);
}

export default withRouter(Summary);
