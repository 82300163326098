import React from 'react';
import * as S from './styles';

const FlightStep = ({ steps }) => {
  return (
			<S.FlightStep>
				<span className={`steps-number ${steps.length > 1 && 'colored'}`}>{`${
					steps.length > 1
						? steps.length - 1 + ` parada${steps.length > 2 ? 's' : ''}`
						: 'DIRETO'
				}`}</span>
				<div className='steps'>
					<div className='ligature' />
					{steps.map((step, index) => (
						<S.Steps key={index}>
							<div className='step' />
						</S.Steps>
					))}
					<S.Steps>
						<div className='step' />
					</S.Steps>
				</div>
				<S.StepLabel>
					{steps.map((step, index) => (
						<span key={index}>{step.departurestation}</span>
					))}
					<span>{steps[steps.length - 1].arrivalstation}</span>
				</S.StepLabel>
			</S.FlightStep>
		);
};

export default FlightStep;
