import { companies } from '../constants/constants';

const getCompanyName = (validatingcarrier) => {
  const companyName = companies.find(
    (x) => x.validatingcarrier === validatingcarrier
  ).companyName;

  return companyName !== undefined
    ? companyName.charAt(0).toUpperCase() + companyName.slice(1).toLowerCase()
    : '';
};

export default getCompanyName;
