import React, { useEffect, useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import * as S from "./styles";
import {
	// Checkbox,
	// FormControlLabel,
	Grid,
	MenuItem,
	Portal,
	Typography,
} from "@material-ui/core";
import FormInput from "../FormInput";
import cardFlag from "../../utils/getFlagCard";
import { useGlobal } from "../../contexts/globalContext";
import createCard64 from "../../utils/createCard64";
import BookingsRepository from "../../repositories/bookingsRepository";
import UserRepository from "../../repositories/userRepository";
import { ThemeButton } from "../ThemeButton/styles";
import Loader from "../Loader";
import Auth2faModal from "../Auth2FaModal";
import { getCep } from "../../services/via-cep.datasource";
import { getClientIpAddress } from "../../services/get-client-ip";
import AppendHead from "react-append-head";
import { ufs } from "../../utils/ufList";

import { MAX_INSTALLMENTS } from "../../constants/constants";

function ReprocessarPagamento({ open, onClose, booking }) {
	const CodeAntiFraud = crypto.randomUUID();
	const ORG_ID = "1snn5n9w";
	const URL = `https://h.onlinemetrix.net/fp/tags.js?org_id=${ORG_ID}&session_id=adiq_br${CodeAntiFraud}`;

	const { user, signed, showAlert } = useGlobal();
	let history = useHistory();

	const bookingsRepository = new BookingsRepository();
	const userRepository = new UserRepository();

	// const [saveCardData, setSaveCardData] = useState(true);
	const [installmentsNumber, setInstallmentsNumber] = useState([]);
	const [state, setState] = useState({});
	const [error, setError] = useState("");
	const [send, setSend] = useState(false);
	const [userCardDataSaved, setUserCardDataSaved] = useState("");
	const [useSavedCard, setUseSavedCard] = useState(false);
	const [openLoader, setOpenLoader] = useState(false);
	const [openModal2fa, setOpenModal2fa] = useState(false);
	const closeModal2fa = () => setOpenModal2fa(false);

	const [ipAddress, setIpAddress] = useState("");

	const [userDataAddress, setUserDataAddress] = useState({
		zipCode: "",
		address: "",
		number: "",
		city: "",
		state: "",
		complement: "",
		documentNumber: "",
		email: "",
		mobilePhoneNumber: "",
		ipAddress: "",
	});

	const getClientIp = async () => {
		const data = await getClientIpAddress();
		setIpAddress(data);
	};

	useEffect(() => {
		getClientIp();
	}, []);

	const onChangeFieldCobranca = (e) => {
		setUserDataAddress({ ...userDataAddress, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		setInstallmentsNumber(
			Array.from(
				// { length: signed ? user.catalog.maximumInstallments : 1 },
				{ length: signed ? MAX_INSTALLMENTS : 1 },
				(_, i) => i + 1
			)
		);
	}, [signed, user]);

	const onChangeField = (e) => {
		setState({ ...state, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		userRepository.getUserDetails(user.id).then((snapshot) => {
			const res = snapshot.docs.map((i) => i.data());
			setUserCardDataSaved(res);
		});
		setError("");

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.id]);

	useEffect(() => {
		setError("");
	}, []);

	const onSubmit = () => {
		const customer = {
			...userDataAddress,
			state: userDataAddress.state.substring(0, userDataAddress.state.length - 5),
			ipAddress: ipAddress,
		};

		if (!useSavedCard) setSend(true);

		if (!useSavedCard && (!state.cardHolder || !state.cardNumber || !state.cardExpiration || !state.cardCCV)) {
		} else {
			let data = useSavedCard
				? {
					customer,
					paymentCode: CodeAntiFraud,
					vaultId: userCardDataSaved[0].vaultId,
				}
				: {
					customer,
					paymentCode: CodeAntiFraud,
					cardInfo: createCard64(state.cardHolder, state.cardNumber, state.cardExpiration, state.cardCCV),
					installments: state.installments,
					// saveCreditCard: saveCardData,
					saveCreditCard: false,
				};
			setOpenLoader(true);
			bookingsRepository
				.reprocessarPagamento(booking.bookingCode, data)
				.then((res) => {
					setOpenLoader(false);
					sessionStorage.setItem("@bookingCompleted", JSON.stringify(res));
					switch (res.status) {
						case 200:
							history.push("/busca/finalizado");
							break;
						case 201:
							if (res.data.auth2fa) {
								if (res.data.redirectAuth2fa !== null) {
									setOpenLoader(true);
									window.location.replace(res.data.redirectAuth2fa);
								} else {
									setOpenModal2fa(true);
								}
							} else {
								history.push("/busca/finalizado");
							}
							break;
						default:
							break;
					}
				})
				.catch((err) => {
					setOpenLoader(false);
					switch (err?.response?.status) {
						case 400:
							showAlert("Há algo de errado nos seus dados, verifique!");
							break;
						case 401:
							history.push("/session-expires");
							break;
						case 409:
							sessionStorage.setItem("@bookingError", JSON.stringify(err.response.data.error));
							sessionStorage.setItem("reprocessar", JSON.stringify({ reprocessar: true }));
							history.push("/busca/booking-error");
							break;
						case 504:
							sessionStorage.setItem("@bookingError", JSON.stringify("Tempo expirou, tente novamente!"));
							sessionStorage.setItem("reprocessar", JSON.stringify({ reprocessar: true }));
							history.push("/busca/booking-error");
							break;
						default:
							showAlert("error", `Compra não processada, por favor, tente novamente!`);
							break;
					}
				});
		}
	};

	const savedCard = (flag = "", lastFourDigits, vaultId) => {
		return (
			<S.CreditCard>
				<span>{flag}</span>
				<span>{` *${lastFourDigits}`}</span>
				<ThemeButton onClick={() => setUseSavedCard(!useSavedCard)} className="reprocessar">
					{useSavedCard ? "Não usar cartão salvo" : "Usar cartão salvo"}
				</ThemeButton>
			</S.CreditCard>
		);
	};

	const handleChangeCep = async (cep) => {
		if (cep?.length >= 8) {
			getCep(cep).then(({ data }) => {
				if (data.localidade) {
					setUserDataAddress({
						...userDataAddress,
						zipCode: cep,
						city: data.localidade,
						state: ufs.find((u) => u.includes(`(${data.uf})`)),
						address: data.logradouro,
					});
				}
			});
		}
	};

	return (
		<S.Modal>
			<AppendHead>
				<script type="text/javascript" src={URL}></script>
			</AppendHead>
			<Portal>
				<noscript>
					<iframe
						title="iframeAntiFraud"
						style={{
							width: 100,
							height: 100,
							border: 0,
							position: "absolute",
							top: -5000,
						}}
						src={URL}
					></iframe>
				</noscript>
			</Portal>

			<Auth2faModal open={openModal2fa} onClose={closeModal2fa} booking={{ data: booking }} type="reprocessar" />
			<Loader openLoader={openLoader} />
			<Dialog open={open} onClose={() => onClose(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="customized-dialog-title" onClose={() => onClose(false)}>
					Reprocessar Pagamento
				</DialogTitle>
				<DialogContent dividers>
					{userCardDataSaved.length > 0 && (
						<Grid container spacing={3}>
							<Grid item xs={12}>
								{savedCard(userCardDataSaved[0].brand, userCardDataSaved[0].last4Digits)}
							</Grid>
						</Grid>
					)}
					{!useSavedCard ? (
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="span">Por gentileza, digite os dados do seu cartão para confirmarmos a reserva</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormInput
									onChange={onChangeField}
									label="Nome do Titular do Cartão"
									name="cardHolder"
									error={send && !state.cardHolder}
									helperText={send && !state.cardHolder ? "Nome do titular do cartão não pode ser vazio" : ""}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormInput
									onChange={onChangeField}
									label="Numero do Cartão"
									name="cardNumber"
									error={send && !state.cardNumber}
									helperText={send && !state.cardNumber ? "Número do cartão não pode ser vazio" : ""}
								/>
								{state.cardNumber && <label className="cardFlag">{cardFlag.getCardFlag(state?.cardNumber)}</label>}
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormInput
									mask="99/9999"
									onChange={onChangeField}
									label="Data de expiração"
									name="cardExpiration"
									error={send && !state.cardExpiration}
									helperText={send && !state.cardExpiration ? "Data de expiração do cartão não pode ser vazio" : ""}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormInput
									onChange={onChangeField}
									label="Código CvV"
									name="cardCCV"
									error={send && !state.cardCCV}
									helperText={send && !state.cardCCV ? "CCV do cartão não pode ser vazio" : ""}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormInput
									onChange={onChangeField}
									label="Número de parcelas"
									name="installments"
									select
									error={send && !state.installments}
									helperText={send && !state.installments ? "Numero de parcelas não pode ser vazio" : ""}
								>
									{installmentsNumber.map((installment) => (
										<MenuItem key={installment} value={installment}>
											{installment}
										</MenuItem>
									))}
								</FormInput>
							</Grid>
							{/* <Grid item xs={12} sm={6}>
                <FormControlLabel
                  label='Salvar cartão para uso futuro?'
                  control={
                    <Checkbox
                      checked={saveCardData}
                      onChange={() => setSaveCardData(!saveCardData)}
                    />
                  }
                />
              </Grid> */}
						</Grid>
					) : (
						""
					)}
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<div className="title">
								<S.Title>Dados de cobrança</S.Title>
							</div>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormInput onChange={onChangeFieldCobranca} label="CPF" name="documentNumber" />
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormInput onChange={onChangeFieldCobranca} label="E-mail" name="email" />
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormInput mask="(99) 99999-9999" onChange={onChangeFieldCobranca} label="Celular" name="mobilePhoneNumber" />
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormInput onChange={onChangeFieldCobranca} onBlur={(e) => handleChangeCep(e.target.value)} label="CEP" name="zipCode" />
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormInput onChange={onChangeFieldCobranca} label="Endereço" name="address" value={userDataAddress.address} />
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormInput onChange={onChangeFieldCobranca} label="Número endereço" name="number" />
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormInput onChange={onChangeFieldCobranca} select label="Estado" name="state" value={userDataAddress.state}>
								{ufs.map((uf, index) => (
									<MenuItem value={uf} key={index}>
										{uf}
									</MenuItem>
								))}
							</FormInput>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormInput onChange={onChangeFieldCobranca} label="Cidade" name="city" value={userDataAddress.city} />
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormInput onChange={onChangeFieldCobranca} label="Complemento" name="complement" />
						</Grid>
					</Grid>

					{error !== "" && (
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="span" color="error">
									{error}
								</Typography>
							</Grid>
						</Grid>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						autoFocus
						onClick={() => {
							onSubmit();
						}}
						color="primary"
					>
						Confirmar
					</Button>
					<Button autoFocus onClick={() => onClose(false)} color="primary">
						Voltar
					</Button>
				</DialogActions>
			</Dialog>
		</S.Modal>
	);
}

export default ReprocessarPagamento;
