import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: #f2f2f2;
    font-family: 'Poppins';
    margin-bottom: 50px;
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: #000;
    opacity: 0.8;

    margin: 0;
    padding: 0;
  `}
`;

export const Title3 = styled.h3`
  ${({ theme }) => css`
    color: #000;
    opacity: 0.8;
  `}
`;

export const Descricao = styled.span`
  ${({ theme }) => css`
    color: #000;
    opacity: 0.8;
  `}
`;

export const CarouselContainer = styled.h1`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
  `}
`;

export const HotelName = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;

    padding: 30px;

    ${theme.breakpoints.down('md')} {
      padding: 0px;
    }

    span {
      width: 100%;
    }

    .location {
      display: flex;
      align-content: center;
      font-size: 12px;
      font-weight: 300;
      margin-top: 5px;
    }
  `}
`;

export const InformacoesUteis = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    padding: 30px;

    ${theme.breakpoints.down('md')} {
      padding: 0px;
    }

    span {
      width: 100%;
    }
  `}
`;

export const CheckInOut = styled.div`
  ${({ theme }) => css`
    width: 100%;
  `}
`;

export const Amenities = styled.div`
  display: flex;
  flex-wrap: wrap;

  span {
    width: 100%;
    display: flex;
    svg {
      margin-right: 5px;
    }
  }
`;

export const PlaceLink = styled.a`
  ${({ theme }) => css`
    text-decoration: none;
    color: #000;
    opacity: 0.8;

    &:hover {
      opacity: 0.5;
    }
  `}
`;
