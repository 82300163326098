import api from '../services/api';
import getHeaders from '../utils/returnHeaders';

const getFareDetails = async ({ cia, farecode }) => {
  const header = getHeaders();

  return api.get(`/flight/airline-fare/${cia}/${farecode}`, header);
};

export default getFareDetails;
