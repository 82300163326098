import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";

// import AuthContext, { useAuth } from '../contexts/auth';

// import SignRoutes from './SignRoutes';
import InternalRoutes from "./InternalRoutes";

const Routes = (campaign) => {
	// const { signed } = useAuth(AuthContext);

	// return signed ? <InternalRoutes /> : <SignRoutes />;
	return (
		<>
			<BrowserRouter>
				<Switch>
					<InternalRoutes campaign={campaign} />
				</Switch>
			</BrowserRouter>
		</>
	);
};

export default Routes;
