import styled, { css } from "styled-components";
import { isDiferentiatedBrands } from "../../../themes/themes";
import { getBrandStorage } from "../../../utils/getBrand";

const brand = getBrandStorage();

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: auto;
    max-height: 450px;
    width: 100%;
    background-color: ${isDiferentiatedBrands(brand)
      ? theme.palette.secondary.white
      : "#FFF"};
    border-radius: 6px !important;
    box-shadow: 0px 1px 4px #15223214;
    opacity: 1;
    margin-bottom: 15px;
    flex-wrap: wrap;
    overflow-y: scroll;

    ${theme.breakpoints.down("xs")} {
      flex-wrap: wrap;
    }
  `}
`;

export const LoadText = styled.span`
  ${({ theme }) => css`
    font-size: 28px;
    font-weight: 800;
    color: #545454;
    height: 118px;
  `}
`;

export const SegmentHeader = styled.div`
  ${({ theme }) => css`
    font-family: "Poppins";
    font-size: 10px;
    font-weight: bold;
    display: flex;
    align-items: stretch;
    width: 100%;
    height: 18px;
    padding-left: 5px;
    background: #e3e3e3 0% 0% no-repeat padding-box;
    opacity: 1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-top: 15px;

    .bus {
      margin: 2px;
    }

    span {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  `}
`;

export const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
`;

export const NotFound = styled.div`
  ${({ theme }) => css`
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: 800;
    color: #545454;
    flex-direction: column;
    min-height: calc(100vh - 510px);

    .pesquisa {
      margin-top: 50px;
      height: 50px;
    }
  `}
`;
