import * as S from "./styles";

import { useState } from "react";

import ReprocessarPagamento from "../../ReprocessarPagamento";
import BookingsRepository from "../../../repositories/bookingsRepository";
import { useGlobal } from "../../../contexts/globalContext";

import Loader from "../../Loader";
import { useHistory } from "react-router-dom";
import Auth2faModal from "../../Auth2FaModal";
import Passengers from "../../../assets/icons/passengers.svg";
import CarDoors from "../../../assets/icons/carDoors.svg";
import CarBaggage from "../../../assets/icons/carBaggages.svg";
import CarGear from "../../../assets/icons/carGear.svg";
import CarAc from "../../../assets/icons/carAC.svg";

import { ThemeButton } from "../../ThemeButton/styles";

import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";

const CarTravelsCard = ({ booking = [], departureDate, arrivalDate, coinAlias }) => {
	let history = useHistory();

	const bookingsRepository = new BookingsRepository();
	const { showAlert } = useGlobal();

	const [openDialogReprocessar, setOpenDialogReprocessar] = useState(false);
	const [openLoader, setOpenLoader] = useState(false);
	const [openModal2fa, setOpenModal2fa] = useState(false);

	const closeModal2fa = () => setOpenModal2fa(false);

	const onChangeOpenDialogReprocessar = (open) => setOpenDialogReprocessar(open);

	const getStatusTranslate = (status) => {
		switch (status) {
			case 1:
				return "pendente";
			case 2:
				return "emitido";
			case 3:
				return "expirado";
			case 4:
				return "finalizado";
			case 5:
				return "cancelado";
			default:
				break;
		}
	};

	const reprocessar = () => {
		// onChangeOpenDialogReprocessar(true);

		if ((booking.paymentTransaction === null || booking.paymentTransaction === undefined) && booking.cashTotalValue > 0) {
			onChangeOpenDialogReprocessar(true);
		} else {
			setOpenLoader(true);
			bookingsRepository
				.reprocessarPagamento(booking.bookingCode, {})
				.then((res) => {
					setOpenLoader(false);
					sessionStorage.setItem("@bookingCompleted", JSON.stringify(res));
					switch (res.status) {
						case 200:
							history.push("/busca/finalizado");
							break;
						case 201:
							if (res.data.auth2fa) {
								if (res.data.redirectAuth2fa !== null) {
									setOpenLoader(true);
									window.location.replace(res.data.redirectAuth2fa);
								} else {
									setOpenModal2fa(true);
								}
							} else {
								history.push("/busca/finalizado");
							}
							break;
						default:
							break;
					}
				})
				.catch((err) => {
					setOpenLoader(false);
					switch (err?.response?.status) {
						case 400:
							showAlert("Há algo de errado nos seus dados, verifique!");
							break;
						case 401:
							history.push("/session-expires");
							break;
						case 409:
							sessionStorage.setItem("@bookingError", JSON.stringify(err.response.data.error));
							sessionStorage.setItem("reprocessar", JSON.stringify({ reprocessar: true }));
							history.push("/busca/booking-error");
							break;
						case 504:
							sessionStorage.setItem("@bookingError", JSON.stringify("Tempo expirou, tente novamente!"));
							sessionStorage.setItem("reprocessar", JSON.stringify({ reprocessar: true }));
							history.push("/busca/booking-error");
							break;
						default:
							showAlert("error", `Compra não processada, por favor, tente novamente!`);
							break;
					}
				});
		}
	};

	// const cancelarReserva = (locator) => {
	//   bookingsRepository
	//     .cancelBooking(locator)
	//     .then((res) => {
	//       showAlert('success', 'Reserva cancelada');
	//       window.location.reload();
	//     })
	//     .catch((err) => showAlert('error', 'Houve algum erro, tente novamente!'));
	// };

	const reenviarVoucher = (locator) => {
		bookingsRepository
			.reenviarVoucher(locator)
			.then((res) => showAlert("success", "Voucher reenviado, verifique seu e-mail!"))
			.catch((err) => showAlert("error", "Houve algum erro, tente novamente!"));
	};

	const getCompanyImage = (car) => {
		if (car?.urlLogoCompany) {
			return car.urlLogoCompany;
		} else {
			if (car.companyName?.toUpperCase() === "LOCALIZA") return "/img/localiza.svg";
			return "https://via.placeholder.com/150";
		}
	};

	return booking.length !== 0 ? (
		<S.BlankCard>
			<ReprocessarPagamento open={openDialogReprocessar} onClose={onChangeOpenDialogReprocessar} booking={booking} />
			<Loader openLoader={openLoader} />
			<Auth2faModal open={openModal2fa} onClose={closeModal2fa} booking={{ data: booking }} type="reprocessar" />
			<S.CardHeader>
				<S.Title>
					Código da reserva: {booking?.bookingCode}
					<span className="subtitle">
						Data da reserva:{" "}
						{booking?.bookingDateStr !== undefined &&
							booking?.bookingDateStr !== "Invalid Date" &&
							format(parseISO(booking?.bookingDateStr), "dd/MM/yyyy", {
								locale: pt,
							})}
					</span>
				</S.Title>
				{booking?.statusId && (
					<S.StatusContainer>
						{booking?.statusId === 1 && (
							<ThemeButton className="reprocessar" onClick={reprocessar}>
								Reprocessar Pagamento
							</ThemeButton>
						)}
						{/* {booking?.statusId === 1 &&
              booking?.purchaseId !== null &&
              booking?.purchaseConfirmed === false && (
                <ThemeButton
                  className='reprocessar'
                  onClick={() => cancelarReserva(booking?.bookingCode)}
                >
                  Cancelar reserva
                </ThemeButton>
              )} */}
						{booking?.statusId === 2 && (
							<ThemeButton className="reprocessar" onClick={() => reenviarVoucher(booking?.bookingCode)}>
								Reenviar Voucher
							</ThemeButton>
						)}

						<S.Status className={`status ${getStatusTranslate(booking.statusId)}`}>{getStatusTranslate(booking.statusId)}</S.Status>
					</S.StatusContainer>
				)}
			</S.CardHeader>
			<S.Subtitle>Detalhes da reserva</S.Subtitle>
			<S.CardContent container>
				<S.CarDetails item xs={12}>
					<img className="car-picture" src={booking?.car?.urlCarImg} alt="Foto do carro" />
					<div className="car-details1">
						<img className="company-logo" src={getCompanyImage(booking?.car)} alt={booking?.car?.companyName?.toUpperCase()} />
						<span className="car-type">{booking?.car?.categoryName}</span>
						<span className="car-model">{booking?.car?.model}</span>
					</div>
					<div className="car-details2">
						<div>
							<div>
								{booking?.car?.capacity && (
									<>
										<img src={Passengers} alt="Passageiros" />
										<span>{booking?.car?.capacity}</span>
									</>
								)}
							</div>
							<div>
								{booking?.car?.numberDoors && (
									<>
										<img src={CarDoors} alt="Numero de portas" />
										<span>{booking?.car?.numberDoors}</span>
									</>
								)}
							</div>
							<div>
								{booking.car && (
									<>
										<img src={CarBaggage} alt="Numero de malas" />
										<span>{"???"}</span>
									</>
								)}
							</div>

							<div>
								<>
									<img src={CarGear} alt="Cambio" />
									<span>{booking?.car?.automaticTransmission ? "Auto" : "Man."}</span>
								</>
							</div>

							{booking?.car?.airConditioning && (
								<div>
									<>
										<img src={CarAc} alt="Ar condicionado" />
										<span>AC</span>
									</>
								</div>
							)}
						</div>
					</div>
				</S.CarDetails>
				<S.BottomDetails item xs={12}>
					<div className="place-details">
						<div>
							<span className="hour">
								Retirada {booking?.car?.pickupDateTime && format(parseISO(booking?.car?.pickupDateTime), "dd/MM/yyyy HH:mm")}
							</span>
							<span className="place">{booking?.car?.pickupAddress}</span>
						</div>
						<div>
							<span className="hour">
								Devolução {booking?.car?.dropoffDateTime && format(parseISO(booking?.car?.dropoffDateTime), "dd/MM/yyyy HH:mm")}
							</span>
							<span className="place">{booking?.car?.dropoffAddress}</span>
						</div>
					</div>
					<div className="reservation-details">
						<div>
							{/* <span>{booking?.car?.km === 0 ? 'Km Ilimitado' : booking?.car?.km + ' Kms'}</span> */}
							{/* <span>{booking?.car?.freeCancellation && 'Cancelamento grátis'}</span> */}
						</div>
					</div>
				</S.BottomDetails>
			</S.CardContent>

			<S.BottomInfos>
				{booking.passengers && (
					<S.Travellers>
						<S.Subtitle>VIAJANTES</S.Subtitle>
						{booking.passengers.map((passenger) => (
							<S.TravellerName>{`${passenger.name} ${passenger.lastName}`}</S.TravellerName>
						))}
					</S.Travellers>
				)}
				{!booking.pointsTotalValue && !booking.cashTotalValue && (
					<S.ValorTotal>Total: R$ {booking.BookingBySource[0].TotalFare.Total.toLocaleString("pt-BR")}</S.ValorTotal>
				)}
				{booking.pointsTotalValue ? (
					<S.ValorTotal>
						Valor em {coinAlias}: {booking.pointsTotalValue}
					</S.ValorTotal>
				) : (
					""
				)}
				{booking.cashTotalValue ? <S.ValorTotal> Valor em R$: {booking.cashTotalValue.toLocaleString("pt-BR")}</S.ValorTotal> : ""}
			</S.BottomInfos>
			{booking.bookingExpirationDate && (
				<S.ExpirationDate>
					{booking.purchaseId !== null && booking.purchaseId !== undefined && (
						<S.Title>
							<span className="subtitle-locator">Locator: {booking.locator}</span>
						</S.Title>
					)}
					{booking.statusId === 1 && (
						<span>
							Esta reserva expira em:{" "}
							{booking?.bookingExpirationDate !== undefined &&
								booking?.bookingExpirationDate !== "Invalid Date" &&
								format(parseISO(booking?.bookingExpirationDate), "dd/MM/yyyy HH:mm", {
									locale: pt,
								})}
						</span>
					)}
				</S.ExpirationDate>
			)}
		</S.BlankCard>
	) : (
		<> </>
	);
};

export default CarTravelsCard;
