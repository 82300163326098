import { IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

import { useGlobal } from "../../contexts/globalContext";
import { Snackbar } from "./styles";

const ShowAlert = () => {
	const { closeAlert, state } = useGlobal();

	return (
		<Snackbar
			anchorOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			open={state.alert.open}
			autoHideDuration={state.alert.duration || 6000}
			onClose={closeAlert}
		>
			<Alert
				elevation={6}
				variant="filled"
				severity={state.alert.type}
				action={
					<>
						<IconButton size="small" aria-label="close" color="inherit" onClick={closeAlert}>
							<CloseIcon width={20} />
						</IconButton>
					</>
				}
			>
				{state.alert.message}
			</Alert>
		</Snackbar>
	);
};

export default ShowAlert;
