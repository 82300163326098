const cleanStorageBeforeSearch = () => {
  sessionStorage.removeItem('carSearch');
  sessionStorage.removeItem('flySearch');
  sessionStorage.removeItem('busSearch');
  sessionStorage.removeItem('hotelSearch');
  sessionStorage.removeItem('busSearch');
  sessionStorage.removeItem('entertainmentSearch');
  sessionStorage.removeItem('buscaAtual');
};

export default cleanStorageBeforeSearch;
