import React, { useEffect, useState } from 'react';
import { useGlobal } from '../../contexts/globalContext';
import UserRepository from '../../repositories/userRepository';

import * as S from './styles';

function LinkHeader({ img, alt }) {
  const { user } = useGlobal();
  const userRepository = new UserRepository();

  const [link, setLink] = useState(null);

  const getImage = async (path) => {
    const imagemLink = await userRepository.getStorageImages(path);
    setLink(imagemLink);
  };

  useEffect(() => {
    getImage(`campaign/${user.campaign?.id}/images/${img}.png`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [img, user.campaign?.id]);

  return !link ? (
    <>carregando</>
  ) : (
    <S.Wrapper>
      <img src={link} alt={alt} />
    </S.Wrapper>
  );
}

export default LinkHeader;
