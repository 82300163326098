import React, { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import AuthenticationLoading from "../../components/Authentication";
import { useGlobal } from "../../contexts/globalContext";
import getTokenByCode from "../../services/authorizeTokenAvon";
import getAuth from "../../services/getAuth";

import { getSessionStorage } from "../../utils/getSessionStorage";

// import MyTravels from '../../components/MyTravels';

function Auth() {
	const { login, setLoadingPointsBalance } = useGlobal();
	let history = useHistory();
	const searchToObject = (objToConvert) => {
		var pairs = objToConvert.substring(1).split("&"),
			obj = {},
			pair,
			i;

		for (i in pairs) {
			if (pairs[i] === "") continue;

			pair = pairs[i].split("=");
			obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
		}

		return obj;
	};

	useEffect(() => {
		setLoadingPointsBalance(true);
		const campaignId = getSessionStorage("campaign");

		if (campaignId) {
			if (window.location.hash === "" && window.location.search === "") {
				getAuth(campaignId).then((res) => {
					let link = res.data;
					// link = link.replace(
					//   'https://app.viagensdestinoferias.com.br',
					//   window.location.origin
					// );

					const search = searchToObject(`${link.split("?")[1]}`);

					sessionStorage.setItem("client_id", search.lient_id);

					window.location.replace(link);
				});
			} else {
				if (window.location.search !== "") {
					// sessionStorage.setItem(
					//   'auth',
					//   JSON.stringify(searchToObject(window.location.search))
					// );

					const search = searchToObject(window.location.search);

					const client_id = getSessionStorage("client_id");
					getTokenByCode(search.code, client_id, search.scope.replaceAll("%20", "+"), campaignId)
						.then((result) => {
							sessionStorage.setItem("auth", JSON.stringify(result.data));
							login();
						})
						.catch((error) => console.error("error AQUI", error));
				} else {
					sessionStorage.setItem("auth", JSON.stringify(searchToObject(window.location.hash)));
					login();
				}

				history.push("/");
			}
		} else {
			history.push("/");
		}
	}, [history, login, setLoadingPointsBalance]);

	return (
		<div>
			<AuthenticationLoading />
		</div>
	);
}

export default withRouter(Auth);
