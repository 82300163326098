import React, { useEffect } from "react";

import { TextField } from "@material-ui/core/";

import Calendar from "../../../assets/icons/Calendar.svg";

import * as S from "./styles";
import { Container, makeStyles } from "@material-ui/core";
import InputDateRange from "../../InputDateRange";
import Grid from "@material-ui/core/Grid";
import ThemeButton from "../../ThemeButton";
import { useState } from "react";
import cleanStorageBeforeSearch from "../../../utils/cleanStorageBeforeSearch";
import CitiesRepository from "../../../repositories/citiesRepository";
import { Autocomplete } from "@material-ui/lab";
import { useGlobal } from "../../../contexts/globalContext";
import { useHistory } from "react-router-dom";

import { getSessionStorage } from "../../../utils/getSessionStorage";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFormHelperText-root.Mui-error": {
            color: "white",
        },
        opacity: 1,
        "& .MuiInputBase-input": {
            color: `#FFF`,
        },
        "& label.Mui-focused": {
            color: `#FFF`,
        },
        "& .MuiFormLabel-root": {
            color: "#FFF",
            opacity: 0.45,
        },
        "& .MuiSelect-icon": {
            color: "#FFF",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: `#FFF`,
                color: `#FFF`,
            },
            "&:hover fieldset": {
                borderColor: `#FFF`,
                color: `#FFF`,
                borderWidth: 2,
            },
            "&.Mui-focused fieldset": {
                borderColor: `#FFF`,
                color: `#FFF`,
            },
            "&.Mui-error fieldset": {
                borderColor: "#FFF",
            },
        },
    },
}));

const ExperiencesSearchInputs = () => {
    const { showAlert /**,signed**/, user } = useGlobal();
    let history = useHistory();
    const classes = useStyles();
    const citiesRepository = new CitiesRepository();

    const initialState = {
        cidade: "",
        datas: "",
    };
    const [state, setState] = useState(initialState);
    const [options, setOptions] = useState([]);
    const [send, setSend] = useState(false);

    useEffect(() => {}, [state]);

    const changeOptions = (value) => {
        if (value.length >= 3) {
            citiesRepository.getCities(value).then((cities) => {
                setOptions(cities.data || []);
            });
        }
    };

    const handleSubmit = () => {
        // if (signed) {
        setSend(true);
        if (state.cidade === "" || state.datas === "") {
            showAlert("error", "Por favor, preencha todos os campos");
        } else {
            cleanStorageBeforeSearch();
            sessionStorage.setItem("experienceSearch", JSON.stringify(state));
            sessionStorage.setItem("buscaAtual", "Experiencias");
            history.push("/busca/resultado-pesquisa");
        }
        // } else {
        //   showAlert('error', 'Efetue o login para realizar a busca');
        // }
    };

    useEffect(() => {
        if (state !== initialState) {
            sessionStorage.setItem("experienceSearch", JSON.stringify(state));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        const exp = getSessionStorage("experienceSearch");
        if (exp) setState(exp);
    }, []);

    return (
        <S.Wrapper>
            <Container>
                <div className="origem-destino-control">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={8} md={6} lg={7}>
                            <Autocomplete
                                freeSolo
                                disableClearable
                                className={classes.root}
                                noOptionsText="Não encontrado"
                                options={options}
                                getOptionLabel={(option) =>
                                    option
                                        ? `${option.cityName}${
                                              option.stateName
                                                  ? `, ${option.stateName}`
                                                  : ""
                                          }`
                                        : ""
                                }
                                onInputChange={(event, value, reason) => {
                                    if (reason === "input") {
                                        changeOptions(value);
                                    }
                                }}
                                onChange={(event, newValue) => {
                                    setState({ ...state, cidade: newValue });
                                }}
                                filterOptions={(x) => x}
                                value={state.cidade !== "" ? state.cidade : ""}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Cidade"
                                        variant="outlined"
                                        fullWidth
                                        className={classes.root}
                                        error={
                                            send &&
                                            state.cidade === initialState.cidade
                                        }
                                        helperText={
                                            send &&
                                            state.cidade === initialState.cidade
                                                ? "Cidade não pode ser vazia"
                                                : ""
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <InputDateRange
                                icon={Calendar}
                                label="Data"
                                width={190}
                                value={state.datas.abreviada}
                                onChange={(e) =>
                                    setState({ ...state, datas: e })
                                }
                                error={
                                    send && state.datas === initialState.datas
                                }
                                helperText={
                                    send && state.datas === initialState.datas
                                        ? "Data não pode ser vazia"
                                        : ""
                                }
                                minStartDays={
                                    user.campaign.advanceDaysCar
                                        ? user.campaign.advanceDaysCar
                                        : 1
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <ThemeButton
                                onClick={handleSubmit}
                                className="submit1"
                            >
                                Buscar
                            </ThemeButton>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </S.Wrapper>
    );
};

export default ExperiencesSearchInputs;
