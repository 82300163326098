const defaultProps = {
  typography: {
    fontFamily: 'Poppins',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
};

const padrao = {
	...defaultProps,
	palette: {
		primary: {
			main: '#FD665E',
			light: '#FEC7C7',
		},
		secondary: {
			main: '#FFFF',
			white: '#FFF',
			black: '#363636',
			text: '#FFF',
		},
	},
};

const bmw = {
	...defaultProps,
	palette: {
		primary: {
			main: '#5691FF',
			light: '#ACC8FC',
		},
		secondary: {
			main: '#FFFFFF',
			white: '#FFF',
			text: '#FFF',
		},
	},
};

const avon = {
	...defaultProps,
	palette: {
		primary: {
			main: '#363636',
			light: '#ffd9f9',
		},
		secondary: {
			main: '#e8308a',
			white: '#FFF',
			black: '#363636',
			text: '#e8308a',
		},
	},
};

const proshop = {
	...defaultProps,
	palette: {
		primary: {
			main: '#588893',
			light: '#5CBAC8',
		},
		secondary: {
			main: '#5CBAC8',
			white: '#fff',
			text: '#fff',
		},
	},
};

// const intelbras = {
//   ...defaultProps,
//   palette: {
//     primary: {
//       main: '#3e5055',
//       light: '#87c984',
//     },

//     secondary: {
//       main: '#00A335',
//       white: '#FFF',
//       black: '#3e5055',
//     },
//   },
// };

const portoplus = {
	...defaultProps,
	palette: {
		primary: {
			main: '#363636',
			light: '#c2c2c2',
		},
		secondary: {
			main: '#01c5ff',
			white: '#FFF',
			black: '#363636',
			text: '#01c5ff',
		},
	},
};

const Themes = {
	padrao: { ...padrao },
	portoplus: { ...portoplus },
	avon: { ...avon },
	bmw: { ...bmw },
	proshop: { ...proshop },
	// intelbras: { ...intelbras },
};

const diferentiatedBrands = [
  // 'avon',
  'portoplus',
];

export const isDiferentiatedBrands = (brand) => {
  return diferentiatedBrands.indexOf(brand) !== -1;
};

export default Themes;
