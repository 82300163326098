import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";
import ExperienceDetailsComponent from "../../components/ExperienceDetailsComponent";

import { getSessionStorage } from "../../utils/getSessionStorage";

function ExperienceDetails({ buscaAtual }) {
	const [searchState, setSearchState] = useState();

	useEffect(() => {
		setSearchState(getSessionStorage('@experience-details'));
	}, []);

	return (
		<div style={{ backgroundColor: "#f2f2f2" }}>
			<ExperienceDetailsComponent experience={searchState?.experience} images={searchState?.images} buscaAtual={buscaAtual} />
			{/* <GetDistance /> */}
		</div>
	);
}

export default withRouter(ExperienceDetails);
