import styled from 'styled-components';
import { Snackbar as MuiSnackbar } from '@material-ui/core';

export const Snackbar = styled(MuiSnackbar)`
  top: 170px;

  .MuiAlert-icon {
    display: flex;
    align-items: center;
  }

  .MuiAlert-filledSuccess {
    background-color: #38b6ac;
    color: #ffffff;
  }

  .MuiAlert-filledError {
    background-color: #cc2f0c;
    color: #ffffff;
  }

  .MuiAlert-filledWarning {
    background-color: #edc202;
    color: #302a38;
  }

  .MuiAlert-message {
    font-weight: 700;
  }
`;
