import { getSessionStorage } from "./getSessionStorage";

export const addFeeByDestinoFerias = (valdest, type) => {
	const storagedUser = getSessionStorage('@App:user');

	const getFee = (type) => {
		switch (type) {
			case "Aéreo":
				return storagedUser?.campaign?.destinoFeriasFeeFlight | 0;
			case "FLIGHT":
				return storagedUser?.campaign?.destinoFeriasFeeFlight | 0;
			case "Hotel":
				return storagedUser?.campaign?.destinoFeriasFeeHotel | 0;
			case "HOTEL":
				return storagedUser?.campaign?.destinoFeriasFeeHotel | 0;
			case "Experiencias":
				return storagedUser?.campaign?.destinoFeriasFeeExperience | 0;
			case "EXPERIENCE":
				return storagedUser?.campaign?.destinoFeriasFeeExperience | 0;
			case "Carro":
				return storagedUser?.campaign?.destinoFeriasFeeCar | 0;
			case "CAR":
				return storagedUser?.campaign?.destinoFeriasFeeCar | 0;
			default:
				return storagedUser?.campaign?.destinoFeriasFee;
		}
	};

	return valdest + valdest * (getFee(type) / 100);
};
