import * as React from "react";

const SvgAirplanePrimary = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 30 27.568"
    {...props}
  >
    <path
      d="M8.108 27.568H5.676l5.676-11.351H3.345L.811 20.27H0V7.3h.811l2.534 4.054h8.007L5.676 0h2.432l9.838 11.351h9.622a2.432 2.432 0 1 1 0 4.865h-9.622L8.108 27.568Z"
      fill="#fd665e"
    />
  </svg>
);

export default SvgAirplanePrimary;
