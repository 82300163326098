import styled, { css } from 'styled-components';
import { isDiferentiatedBrands } from '../../../themes/themes';
import { getBrandStorage } from '../../../utils/getBrand';

const brand = getBrandStorage();
export const Wrapper = styled.div`
  font-family: 'Poppins' !important;

  ${({ theme }) => css`
    height: auto;
    font-family: 'Poppins' !important;

    .filter-group {
      display: flex;
      flex-direction: column;
    }

    .outlined-input-iconed {
      background-color: ${isDiferentiatedBrands(brand)
        ? theme.palette.secondary.white
        : '#FFF'};
      border-radius: 20px;
      height: 40px;
    }
  `}
`;

export const FilterTitle = styled.span`
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
`;

export const ThinText = styled.div`
  ${({ theme }) => css`
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 200;
  `}
`;
