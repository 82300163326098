import { getSessionStorage } from "./getSessionStorage";

export const getBrand = (campaignId) => {
	let brand = 'padrao';
	switch (campaignId) {
		case '50667':
			brand = 'portoplus';
			break;
		case '30316':
			brand = 'avon';
			break;
		case '30194':
			brand = 'bmw';
			break;
		case '2000003141':
			brand = 'proshop';
			break;
		// case '299999':
		//   brand = 'intelbras';
		//   break;
		default:
			break;
	}

	return brand ? brand : 'padrao';
};

export const getBrandStorage = () => {
	let brand = '';
	const query = new URLSearchParams(window.location.search);
	const queryResult = query.get('campaignId');
	const env = process.env.REACT_APP_CAMPAIGN_CODE;
	let campaignId = env || queryResult;
	if (campaignId === null) {
		campaignId = '0';
	}

	if (campaignId !== '0') {
		sessionStorage.setItem('campaign', campaignId);
	}
	brand = getBrand(getSessionStorage('campaign'));

	return brand !== '' ? brand : 'padrao';
};
