import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
// import * as S from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormHelperText-root.Mui-error': {
      color: 'white',
    },
    opacity: 1,
    '& .MuiInputBase-input': {
      color: `#FFF`,
    },
    '& label.Mui-focused': {
      color: `#FFF`,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `#FFF`,
      color: `#FFF`,
    },
    '& .MuiFormLabel-root': {
      color: '#FFF',
      opacity: 0.45,
    },
    '& .MuiSelect-icon': {
      color: '#FFF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: `#FFF`,
        color: `#FFF`,
      },
      '&:hover fieldset': {
        borderColor: `#FFF`,
        color: `#FFF`,
        borderWidth: 2,
      },
      '&.Mui-focused fieldset': {
        borderColor: `#FFF`,
        color: `#FFF`,
      },
      '&.Mui-error fieldset': {
        borderColor: '#FFF',
      },
    },
  },
}));

function IconedInput({
  icon,
  label,
  onChange,
  select,
  children,
  value,
  error,
  helperText,
}) {
  const classes = useStyles();
  return (
    <TextField
      onChange={onChange}
      variant='outlined'
      placeholder={label}
      select={select}
      label={select && label}
      defaultValue={select && ''}
      className={classes.root}
      value={value}
      fullWidth
      InputProps={
        icon && {
          startAdornment: (
            <InputAdornment position='start'>
              <img alt='icon' src={icon} />
            </InputAdornment>
          ),
        }
      }
      error={error}
      helperText={helperText}
    >
      {children}
    </TextField>
  );
}

export default IconedInput;
