import { Grid } from "@material-ui/core";
import styled, { css } from "styled-components";

export const CardContent = styled.div`
    ${({ theme }) => css`
        height: 280px;
        font-family: "Poppins";
        color: #023566;
        width: 100%;
        position: relative;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 10px;

        .container_img {
            position: relative;
            width: 40%;

            .item_img {
                position: relative;
                height: 100%;

                .img {
                    position: relative;
                    height: 268px;
                    max-width: 325px;
                    border-radius: 10px;
                    cursor: pointer;
                }
            }
        }

        .container_details { 
            height: 257px;
            position: relative;
            width: 60%;

            .top {
                height: 90px;
                position: relative;
                width: 100%;

                .title {
                    color: #3b3c40;
                    font-size: 16px;
                    font-weight: 600;
                    height: 19px;
                    left: 0;
                    top: -1;
                    letter-spacing: 0.08px;
                    line-height: normal;
                    position: absolute;
                    white-space: wrap;
                    cursor: pointer;
                }

                .type {
                    color: ${theme.palette.primary.main};
                    font-size: 12px;
                    font-weight: 600;
                    height: 15px;
                    left: 0;
                    top: 50px;
                    letter-spacing: 0.08px;
                    line-height: normal;
                    position: absolute;
                }

                .location {
                    color: #7f7f7f;
                    font-size: 12px;
                    font-weight: 400;
                    height: 15px;
                    left: 0;
                    top: 70px;
                    letter-spacing: 0.08px;
                    line-height: normal;
                    position: absolute;
                }

                .separator {
                    border-bottom-style: dashed;
                    border-bottom-width: 1px;
                    border-color: #666666;
                    height: 28px;
                    width: 135px;
                    left: 390px;
                    top: 65px;
                    position: absolute;

                    .cancelation {
                        color: #008000;
                        font-size: 12px;
                        font-weight: 600;
                        height: 15px;
                        left: 1px;
                        top: 10px;
                        letter-spacing: 0.08px;
                        line-height: normal;
                        position: absolute;
                        white-space: nowrap;
                        cursor: pointer;

                        .tooltiptext {
                            width: 250px;
                            visibility: hidden;
                            background-color: #f9f9f9;
                            color: #333;
                            text-align: left;
                            border-radius: 8px;
                            padding: 10px;
                            position: absolute;
                            z-index: 1;
                            bottom: 150%;
                            left: 50%;
                            transform: translateX(-50%);
                            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                            opacity: 0;
                            transition: opacity 0.3s ease;
                            font-size: 0.9rem;
                            line-height: 1.5;
                            word-wrap: break-word;
                            white-space: normal;
                        }

                        .tooltiptext h3 {
                            font-size: 14px;
                            margin: 0 0 5px 0;
                            white-space: normal;
                        }

                        .tooltiptext p {
                            font-size: 12px;
                            margin: 0;
                            white-space: normal;
                        }

                        .tooltiptext i {
                            content: "";
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            transform: translateX(-50%);
                            border-width: 10px;
                            border-style: solid;
                            border-color: #f9f9f9 transparent transparent
                                transparent;
                        }

                        &:hover .tooltiptext {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .mid {
            color: #333333;
            height: 54px;
            width: 100%;
            padding-right: 5px;
            position: relative;
            top: -8px;

            .description {
                font-size: 14px;
                font-weight: 400;
                height: 54px;
                left: 0;
                top: 0;
                letter-spacing: 0.08px;
                line-height: 18px;
                position: relative;
            }

            .plus {
                font-weight: 400;
                color: ${theme.palette.primary.main};
                cursor: pointer;
            }
        }

        .bottom {
            height: 55px;
            position: relative;
            width: 350px;

            .group {
                height: 55px;
                left: 0;
                top: 0;
                position: relative;
                width: 100%;

                .left {
                    height: 55px;
                    left: 0;
                    top: 5px;
                    position: relative;

                    .duration {
                        color: #555555;
                        height: 34px;
                        position: relative;

                        .icon {
                            height: 13px;
                            left: 0;
                            top: 2px;
                            position: absolute;
                        }

                        .text {
                            font-size: 14px;
                            font-weight: 400;
                            height: 18px;
                            left: 21px;
                            top: -1px;
                            letter-spacing: 0;
                            line-height: normal;
                            position: absolute;
                            width: max-content;
                        }
                    }

                    .weekDays {
                        color: #555555;
                        height: 34px;
                        position: relative;

                        .icon {
                            height: 13px;
                            left: 0;
                            top: 2px;
                            position: absolute;
                        }

                        .text {
                            font-size: 14px;
                            font-weight: 400;
                            height: 18px;
                            left: 21px;
                            top: -1px;
                            letter-spacing: 0;
                            line-height: normal;
                            position: absolute;
                        }
                    }
                }
            }

            .price_label {
                color: #7f7f7f;
                font-size: 12px;
                font-weight: 500;
                height: 15px;
                width: 144px;
                left: 370px;
                top: -36px;
                letter-spacing: 0;
                line-height: normal;
                position: relative;
            }

            .price_text {
                color: ${theme.palette.primary.main};
                font-size: 18px;
                font-weight: 700;
                height: 22px;
                width: 143px;
                left: 370px;
                top: -16px;
                line-height: normal;
                position: relative;
                text-align: right;
            }

            ${theme.breakpoints.down("sm")} {
                font-size: 12px;
            }
        }
    `}
`;

export const GridItemFlex = styled(Grid)`
    ${({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
    `}
`;

export const Modal = styled.div`
    .enabled {
        color: green;
    }

    .disabled {
        color: red;
    }
`;
