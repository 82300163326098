import styled, { css } from "styled-components";
import { getBrandStorage } from "../../utils/getBrand";
import { isDiferentiatedBrands } from "../../themes/themes";
import { getCampaignColors } from "../../utils/getCampaignColors";

const campaignColors = getCampaignColors();

const brand = getBrandStorage();
export const Wrapper = styled.div`
	${({ theme }) => css`
    width: 100%;
    background-color: transparent;
    justify-content: center;

    .Tabs-control {
      height: 65px;
      background-color: transparent;
      margin-top: -65px;
      width: 100%;

      ${theme.breakpoints.down("sm")} {
        margin-top: 0px;
      }

      .MuiTabs-scroller {
        display: flex;
        justify-content: center;

        ${theme.breakpoints.down("sm")} {
          width: 100%;
          display: flex;
          justify-content: center;
          .MuiTabs-flexContainer {
            width: 100%;
          }
        }
      }

      .Tab-label {
        background-color: #ffffff;
        color: #131523;
        opacity: 1;
        padding: 0 !important;
        height: 48px !important;
        margin-top: 15px;
        padding-left: 10px !important;
        padding-right: 10px !important;

        ${theme.breakpoints.down("sm")} {
          display: flex;
          width: 25%;
        }

            &.active {
              margin-top: 0px;
              padding-top: 10px !important;
              .MuiTab-wrapper {
                padding-left: 4px;
                padding-right: 4px;
                color: ${isDiferentiatedBrands(brand) ? theme.palette.secondary.white : "#FFF"} !important;
                .Tab-image {

                }
              }
            }

            .MuiTab-wrapper {
              margin-top: -10px;
              display: flex;
              flex-direction: row;
              opacity: 50%;
              margin-bottom: 5px;
              text-transform: capitalize;
              align-items: center;
              font-size: 18px;

              ${theme.breakpoints.down("sm")} {
                font-size: 13px;
                flex-direction: column;
                margin-top: -15px;
                margin-bottom: 10px;
                font-size: 12px;

                
              }

          .Tab-image {
            width: 20px;
            margin-right: 5px;
            padding-top: 4px;
            stroke-width: 2px;

            ${theme.breakpoints.down("sm")} {
              margin-right: 0px;    
            }

            ${theme.breakpoints.down("xs")} {
              padding: 0px;
              margin: 0px;

  
            }
          }
        }
        }
      }
      .MuiTabs-indicator {
        background-color: ${
					// brand === 'avon'
					//   ? theme.palette.secondary.main
					//   :
					campaignColors.searchColor ? campaignColors.searchColor : theme.palette.primary.main
				};
      }
      .active {
        background-color: ${
					// brand === 'avon'
					//   ? theme.palette.secondary.main
					//   :
					campaignColors.searchColor ? campaignColors.searchColor : theme.palette.primary.main
				} !important;
        margin-top: 0px;
        color: #ffffff;


        .MuiTab-wrapper {
          opacity: 1;
          margin-bottom: -5px;
        }
      }
    }
  `}
`;

export const TabPanel = styled.div`
	${({ theme }) => css`
		background-color: ${
			// brand === 'avon'
			//   ? theme.palette.secondary.main
			//   :
			campaignColors.searchColor ? campaignColors.searchColor : theme.palette.primary.main
		};
	`}
`;
