import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const ForgotPass = styled.button`
  ${({ theme }) => css`
    background: none;
    color: #1166bb;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  `}
`;

export const LoginWithoutRegister = styled.button`
	${({ theme }) => css`
		background: none;
		color: #1166bb;
		border: none;
		padding: 0;
		font: inherit;
		cursor: pointer;
		outline: inherit;
	`}
`;
export const CallToContactAction = styled.button`
	${({ theme }) => css`
		background: none;
		color: ${theme.palette.primary.main};
		border: none;
		font-weight: 600;
		cursor: pointer;
		font-size: 14px;
		vertical-align: middle;
	`}
`;
export const WhatsappIcon = styled.img`
	${({ theme }) => css`
		filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.2));
		width: 24px;
		height: 24px;
		cursor: pointer;
	`}
`;

export const TitleLogin = styled(Typography)`
  ${({ theme }) => css`
    font-family: 'Poppins';
    color: #424242;
    margin-top: 30px;
  `}
`;

export const Logo = styled.img`
  ${({ theme }) => css`
    width: 400px;

    ${theme.breakpoints.down('sm')} {
      width: 300px;
    }
  `}
`;

export const Aviso = styled(Typography)`
  ${({ theme }) => css`
    font-family: 'Poppins';
    color: #424242;
    opacity: 0.8;
  `}
`;
