import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import * as S from "./styles";
import { ThemeButton } from "./../../../ThemeButton/styles.js";
import { Grid } from "@material-ui/core";

const BusPrice = ({ searchState, selectedDepart, selectedReturn }) => {
	let history = useHistory();
	const [points, setPoints] = useState(0);

	useEffect(() => {
		if (selectedDepart && selectedReturn) {
			let pointsDepart = selectedDepart?.pointsValue || 0;
			let pointsReturn = selectedReturn?.pointsValue || 0;
			setPoints(pointsDepart + pointsReturn);
		} else if (selectedDepart) {
			let pointsDepart = selectedDepart?.pointsValue || 0;
			setPoints(pointsDepart);
		}
	}, [selectedDepart, selectedReturn, searchState]);

	const handleSubmit = () => {
		sessionStorage.setItem("@BusSelected", JSON.stringify({ ...searchState, selectedDepart, selectedReturn }));
		history.push("/busca/resumo");
	};

	return (
		<S.Wrapper>
			<S.CardContent>
				<Grid container>
					{searchState.idavolta === true ? (
						<>
							<S.GridItemFlex>
								<span className="label-ida-e-volta">Ida e Volta</span>
							</S.GridItemFlex>
						</>
					) : (
						<>
							<S.GridItemFlex>
								<span className="label-ida">Ida</span>
							</S.GridItemFlex>
						</>
					)}
					<S.GridItemFlex>
						<span className="total-points">
							{points.toLocaleString("pt-br", {
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							})}{" "}
							Pts
						</span>
					</S.GridItemFlex>
					<S.GridItemFlex>
						<span className="label-total">Total</span>
					</S.GridItemFlex>
					{/* {searchState.passageiros > 1 ? (
						<>
							<S.GridItemFlex>
								<span className="single-points">
									{singlePoints.toLocaleString("pt-br", {
										minimumFractionDigits: 0,
										maximumFractionDigits: 0,
									})}{" "}
									Pts
								</span>
							</S.GridItemFlex>
							<S.GridItemFlex>
								<span className="label-points">
									{searchState.passageiros} pessoa{searchState.passageiros > 1 ? "s" : ""}
								</span>
							</S.GridItemFlex>
						</>
					) : (
						<></>
					)} */}
				</Grid>
			</S.CardContent>
			<ThemeButton
				onClick={handleSubmit}
				className="button-select"
				disabled={!selectedDepart.key || (searchState.idavolta && !selectedReturn.key)}
			>
				Selecionar
			</ThemeButton>
		</S.Wrapper>
	);
};

export default BusPrice;
