import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: auto;
  `}

  .marker-label {
    padding: 4px;
    border-radius: 3px;
    font-weight: 500;
    background-color: red;
  }
  .marker-label:hover {
    opacity: 0.5 !important;
  }
`;

export const RenderedMarker = styled.div`
  display: flex;
  flex-direction: column;

  .bolder {
    font-weight: 500;
  }
`;
