import styled, { css } from "styled-components";
import { getCampaignColors } from "../../utils/getCampaignColors";

const campaingColors = getCampaignColors();

export const Wrapper = styled.div`
	${({ theme }) => css`
		.MuiFormControlLabel-root {
			width: 100%;
			height: 30px;
			padding-top: 0px;

			.MuiFormControlLabel-label {
				width: 100%;
			}
		}

		.Mui-checked {
			color: ${campaingColors.searchColor ? campaingColors.searchColor : theme.palette.primary.mains} !important;
		}
	`}
`;
export const CheckboxLabelAdornment = styled.div`
	${({ theme }) => css`
		width: 100%;

		display: flex;
		font-family: "Poppins";
		font-size: 13px;
		font-weight: 200;
		justify-content: space-between;

		&:first-child {
			margin: 0px;
		}
	`}
`;
