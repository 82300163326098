import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: auto;
    font-family: 'Poppins';
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    height: auto;
    padding-top: 20px;
  `}
`;

export const BlankCard = styled.div`
  ${({ theme }) => css`
    height: auto;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    padding: 12px;
    padding-top: 9px;
    padding-bottom: 27px;
    margin-bottom: 40px;

    .title {
      padding-bottom: 8px;
      padding-left: 5px;
      border-bottom: 1px solid ${rgba('#707070', 0.21)};
      margin-bottom: 20px;
    }

    .title2 {
      margin-bottom: 10px;
    }
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    text-align: left;
    font: normal normal bold 14px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  `}
`;

export const SideHeader = styled.div`
  ${({ theme }) => css`
    background-color: #e0e0e0;
    height: 35px;
    font-family: 'Poppins';
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px;

    div {
      span {
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    &.first {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .date-header {
      text-transform: capitalize;
    }
  `}
`;

export const BlankCardOnly = styled.div`
  ${({ theme }) => css`
    height: auto;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;

    .hotel-details {
      display: flex;
      flex-direction: column;
      padding: 15px;

      span {
        font-size: 10px;
        margin-bottom: 10px;
      }

      .hotel-name {
        font-size: 16px;
        font-weight: 600;
      }
    }
  `}
`;

export const Total = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
    font-size: 10px;

    span {
      font-weight: 200;
    }
    .bold {
      font-weight: Bold;
    }

    &.wrap {
      flex-wrap: wrap;
      padding: 15px 15px;

      div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  `}
`;

export const Spacer = styled.div`
  height: 70px;
`;

export const Subtext = styled.div`
  font-weight: 200;
  font-size: 10px;
  padding: 5px;
`;

export const BlockBuyText = styled.div`
  font-weight: 600;
  font-size: 13px;
  padding: 5px;
  color: red;
`;

export const PagamentoLabel = styled.span`
  ${({ theme }) => css`
    display: flex;

    text-align: left;
    font: normal normal bold 14px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding: 15px 15px;
  `}
`;
