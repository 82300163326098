import { Grid } from "@material-ui/core";
import styled, { css } from "styled-components";
// import { getBrandStorage } from '../../utils/getBrand';
import { getCampaignColors } from "../../utils/getCampaignColors";

const campaignColors = getCampaignColors();
// const brand = getBrandStorage();

export const Wrapper = styled.footer`
	${({ theme }) => css`
    padding-top: 50px;
    width: 100%;
    height: auto;
    background-color: ${
			// brand === 'avon'
			//   ? theme.palette.secondary.main
			// :
			campaignColors.footerColor ? campaignColors.footerColor : "#263238"
		};};
    font-family: 'Poppins';
  `}
`;

export const WrapperPorto = styled.footer`
	${({ theme }) => css`
		padding-top: 50px;
		width: 100%;
		height: 500px;
		background-color: #263238;
		font-family: "Roboto";
	`}
`;

export const GridCentralized = styled(Grid)`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	margin-bottom: 35px;
`;

export const FooterTitle = styled.div`
	font-family: "Poppins";
	display: flex;
	margin-top: 0;
	margin-bottom: 20px;
	font-weight: bold;
	font-size: 18px;
	color: #fff;
	height: 17px;
	opacity: 90%;
`;

export const FooterTitlePorto = styled.div`
	font-family: "Roboto";
	display: flex;
	margin-top: 0;
	margin-bottom: 20px;
	font-weight: bold;
	font-size: 18px;
	color: #fff;
	height: 17px;
	opacity: 90%;
`;

export const FooterList = styled.div`
	font-family: "Poppins";
	margin-top: 0;
	margin-bottom: 12px;
	font-size: 16px;
	color: #fff !important;
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	span,
	svg {
		color: #fff !important;
		text-decoration: none;
		width: 100%;
		opacity: 80%;

		:hover {
			opacity: 1;
			cursor: pointer;
		}
	}

	a {
		text-decoration: none;
		width: 100%;
	}

	.icon {
		width: unset !important;
	}

	svg {
		width: unset !important;
		margin-right: 15px;
	}
`;

export const FooterListPorto = styled.div`
	font-family: "Poppins";
	margin-top: 0;
	/* margin-bottom: 12px; */
	font-size: 16px;
	color: #fff !important;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;

	span,
	a,
	svg {
		text-decoration: none;
		/* width: 100%; */
		opacity: 80%;
		color: #fff !important;
		:hover {
			opacity: 1;
			cursor: pointer;
		}
	}
	svg {
		width: unset;
		margin-right: 15px;
	}
`;

export const CompanyName = styled.div`
	color: #fff;
	display: flex;
	justify-content: center;
	padding-top: 50px;
	padding-bottom: 50px;
	opacity: 0.7;
`;
