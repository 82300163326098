import { useQuery } from "./useQuery";
import { useCampaignService } from "../services/campaign";
import { storage } from "../services/firebase";

import { getSessionStorage } from "../utils/getSessionStorage";

function useCampaign() {
	const { getCampaign } = useCampaignService();

	const campaignIdFromQueryString = useQuery().get("campaignId");
	const codeFromQueryString = useQuery().get("code");

	const campaignIdFromEnv = process.env.REACT_APP_CAMPAIGN_ID;
	const campaignCodeFromEnv = process.env.REACT_APP_CAMPAIGN_CODE;

	const campaignId = campaignIdFromEnv ? campaignIdFromEnv : campaignIdFromQueryString;
	const campaignCode = campaignCodeFromEnv ? campaignCodeFromEnv : codeFromQueryString;

	const getCampaignColors = async (force = false) => {
		let campaignConfig = force ? null : getSessionStorage('@App:campaignConfig');
		let imagesStorage = force ? null : getSessionStorage('@App:imagesStorage');
		const userDetails = force ? null : getSessionStorage('@App:user');

		if (!campaignConfig) {
			campaignConfig = userDetails ? { ...userDetails.campaign.campaignConfigLayout } : null;
		}

		if (!imagesStorage) imagesStorage = {};

		if (campaignConfig) {
			return { ...campaignConfig, ...imagesStorage };
		}

		var campaign = await getCampaign(campaignCode);
		if (campaign) {
			sessionStorage.setItem("@App:campaignConfig", JSON.stringify(campaign.campaignConfigLayout));
			return { ...campaign.campaignConfigLayout, ...imagesStorage };
		}

		return null;
	};

	const getImagesStorage = async (campaignCode) => {
		var storageRef = storage.ref();
		var stgHome = storageRef.child(`campaign/${campaignCode}/home/${campaignCode}.png`);

		var stgLogo = storageRef.child(`campaign/${campaignCode}/logo/${campaignCode}.png`);

		var stgLogoSecundaria = storageRef.child(`campaign/${campaignCode}/logo/${campaignCode}-secundaria.png`);

		var stgLogoMobile = storageRef.child(`campaign/${campaignCode}/logoMobile/${campaignCode}.png`);

		var stgHomeDefault = storageRef.child(`campaign/0/home/0.png`);

		var stgLogoDefault = storageRef.child(`campaign/0/logo/0.png`);
		var stgLogoSecundariaDefault = storageRef.child(`campaign/0/logo/0-secundaria.png`);

		var stgLogoMobileDefault = storageRef.child(`campaign/0/logoMobile/0.png`);

		const homeDefault = await stgHomeDefault
			.getDownloadURL()
			.then((url) => url)
			.catch(() => "");
		const logoDefault = await stgLogoDefault
			.getDownloadURL()
			.then((url) => url)
			.catch(() => "");
		const logoSecundariaDefault = await stgLogoSecundariaDefault
			.getDownloadURL()
			.then((url) => url)
			.catch(() => "");
		const logoMobileDefault = await stgLogoMobileDefault
			.getDownloadURL()
			.then((url) => url)
			.catch(() => "");

		const home = await stgHome
			.getDownloadURL()
			.then((url) => url)
			.catch(() => "");
		const logo = await stgLogo
			.getDownloadURL()
			.then((url) => url)
			.catch(() => "");
		const logoSecundaria = await stgLogoSecundaria
			.getDownloadURL()
			.then((url) => url)
			.catch(() => "");
		const logoMobile = await stgLogoMobile
			.getDownloadURL()
			.then((url) => url)
			.catch(() => "");

		const images = {
			home,
			logo,
			logoMobile,
			homeDefault,
			logoDefault,
			logoMobileDefault,
			logoSecundaria,
			logoSecundariaDefault,
		};

		return images;
	};

	return {
		campaignId,
		campaignCode,
		getCampaignColors,
		getImagesStorage,
	};
}

export { useCampaign };
