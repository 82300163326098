import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: auto;
    font-family: 'Poppins';
    margin-bottom: 50px;

    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    height: auto;
    padding-top: 20px;
  `}
`;

export const BlankCard = styled.div`
  ${({ theme }) => css`
    height: auto;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    padding: 12px;
    padding-top: 9px;
    padding-bottom: 27px;
    margin-bottom: 40px;

    .title {
      padding-bottom: 8px;
      padding-left: 5px;
      border-bottom: 1px solid ${rgba('#707070', 0.21)};
      margin-bottom: 20px;
    }

    .title2 {
      margin-bottom: 10px;
    }

    .cardFlag {
      font-size: 10px;
      padding: 0;
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      text-transform: capitalize;
    }

    .brandeiras-aceitas {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      div {
        display: flex;
        align-items: center;
        width: 160px;
        justify-content: space-between;

        .visa {
          width: 50px;
        }
        .master {
          width: 40px;
        }
        .elo {
          width: 28px;
        }
        .amex {
          width: 35px;
        }
      }
    }

    .radio-group {
      margin-top: 20px;

      span {
        font-weight: bold;
        margin-right: 20px;
      }

      .radio-options {
        display: inline-flex;
        gap: 15px;

        label {
        padding-top: 10px;
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #333;

          input[type="radio"] {
            margin-right: 8px;
            accent-color: ${theme.palette.primary.main};
          }
        }
      }

      .airport-details {
        padding-top: 10px;
        display: flex;
        gap: 10px;
        margin-top: 15px;

        span {
          font-weight: normal;
          margin-right: 0px;
        }

        .MuiTextField-root {
          flex: 1;
        }
      }
    }
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    text-align: left;
    font: normal normal bold 14px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  `}
`;

export const SideHeader = styled.div`
  ${({ theme }) => css`
    background-color: #e0e0e0;
    height: 35px;
    font-family: 'Poppins';
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px;

    &.first {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .airplane-badge {
      width: 17px;
      height: 17px;
      margin-left: 5px;
      padding-top: 7px;
    }
  `}
`;

export const BlankCardOnly = styled.div`
  ${({ theme }) => css`
    height: auto;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;
  `}
`;

export const Connection = styled.div`
  ${({ theme }) => css`
    padding: 15px 5px;
    display: flex;
    width: 100%;
    font-size: 10px;
    justify-content: space-between;

    .company {
      display: flex;

      img {
        background-color: #ededed;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        object-fit: contain;
        padding: 3px;
      }
      div {
        padding: 0px 5px;

        display: flex;
        flex-wrap: wrap;
        span {
          width: 100%;
        }

        .connection {
          text-align: left;
          font: normal normal 200 10px 'Poppins';
          letter-spacing: 0px;
        }

        .name {
          font-size: 11px;
        }
      }
    }
    .airport {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
    }
  `}
`;

export const Total = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
    font-size: 10px;

    span {
      font-weight: 200;
    }
    .bold {
      font-weight: Bold;
    }

    &.wrap {
      flex-wrap: wrap;
      padding: 15px 15px;

      div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  `}
`;

export const Spacer = styled.div`
  height: 70px;
`;

export const Subtext = styled.div`
  font-weight: 200;
  font-size: 10px;
  padding: 5px;
`;

export const FlightSummaryCardCategory = styled.div`
  display: flex;
  padding-bottom: 8px;
  padding-left: 5px;
  border-bottom: 1px solid ${rgba('#707070', 0.21)};
  margin-bottom: 20px;

  img {
    width: 23px;
    margin-right: 15px;
  }

  .category {
    width: 100%;
    font-size: 10px;
  }

  .category-details {
    color: #2271cf;
    display: flex;
    justify-content: left;

    span {
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
`;

export const CreditCard = styled.div`
  ${({ theme }) => css`
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `}
`;

export const AlertStyle = styled.div`
	${({ theme }) => css`
    margin-bottom: 20px;
		.text {
			color: rgba(255, 247, 226, 1) !important;
      `}
`;