import axios from 'axios';

const getTokenByCode = (code, client_id, scope, idCampanha ) => {
  const configAPI = {
      baseURL: 'https://sso.webpremios.digital/connect/token',
      headers: {
      Authorization: `Basic ${window.btoa(`${client_id}:prd.destino@${idCampanha}#`)}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  const api = axios.create(configAPI);
  api.defaults.timeout = 300000;

  return api.post(
    '',
    // `?grant_type=authorization_code&client_id=${client_id}&code=${code}&scopes=${scope}&redirect_uri=https://app.viagensdestinoferias.com.br/auth/callback`
    new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: client_id,
      code: code,
      scopes: scope,
      redirect_uri: 'https://app.viagensdestinoferias.com.br/auth/callback',
    })
  );
};

export default getTokenByCode;


// const getTokenByCode = (code ) => {
//   const configAPI = {
//     baseURL: 'https://sso.webpremios.digital/connect/token',
//     headers: {
//       Authorization: 'Basic cHJkLjMwMzE2LmRlc3Rpbm86cHJkLmRlc3Rpbm9AMzAzMTYj',
//       'Content-Type': 'application/x-www-form-urlencoded',
//     },
//   };

//   const api = axios.create(configAPI);
//   api.defaults.timeout = 300000;

//   return api.post(
//     '',
//     new URLSearchParams({
//       grant_type: 'authorization_code',
//       client_id: 'prd.30316.destino',
//       code: code,
//       scopes: 'openid profile email custom.profile webpremios.campaigns/30316',
//       redirect_uri: 'https://app.viagensdestinoferias.com.br/auth/callback',
//     })
//   );
// };