import { Grid } from '@material-ui/core';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const BlankCard = styled.div`
  ${({ theme }) => css`
    height: auto;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    padding: 12px;
    padding-top: 9px;
    margin-bottom: 40px;
  `}
`;

export const CardHeader = styled.div`
  ${({ theme }) => css`
    padding-bottom: 8px;
    padding-left: 5px;
    border-bottom: 1px solid ${rgba('#707070', 0.21)};
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    ${theme.breakpoints.down('xs')} {
      flex-wrap: wrap;

      div {
        display: flex;
        width: 100%;

        flex-wrap: wrap;
        justify-content: center;

        span {
          text-align: center;
          width: 100%;
        }
      }
      span {
        width: 100%;
      }
    }
  `}
`;

export const StatusContainer = styled.div`
  display: flex;
`;

export const Status = styled.span`
  ${({ theme }) => css`
    color: #fff;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0 10px;
    border-radius: 15px;
    font-family: 'Poppins';
    font-size: 10px;
    font-weight: bold;
    margin-left: 10px;

    ${theme.breakpoints.down('xs')} {
      width: 100%;
      justify-content: center;
      display: flex;
      height: 20px;
    }

    &.pendente {
      background-color: #97992f;
    }

    &.emitido {
      background-color: #4485cf;
    }

    &.expirado {
      background-color: #b50076;
    }

    &.finalizado {
      background-color: #01b956;
    }

    &.cancelado {
      background-color: #db422e;
    }
  `}
`;

export const BottomInfos = styled.div`
  ${({ theme }) => css`
    display: flex;
    border-top: 1px solid ${rgba('#707070', 0.21)};
    margin-top: 10px;
    padding-top: 10px;

    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
  `}
`;

export const ExpirationDate = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  color: red;
  padding-top: 15px;
`;

export const ValorTotal = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    ${theme.breakpoints.down('xs')} {
      margin-top: 20px;
      justify-content: center;
    }
  `}
`;

export const Travellers = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 70%;
  `}
`;

export const TravellerName = styled.span`
  ${({ theme }) => css`
    font-weight: 300;
    padding-top: 3px;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    text-align: left;
    font: normal normal bold 15px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;

    .subtitle {
      font-size: 11px;
      font-weight: 400;
      margin-left: 10px;
    }

    .subtitle-locator {
      font-size: 12px;
      font-weight: 500;
    }
  `}
`;

export const Subtitle = styled.div`
  ${({ theme }) => css`
    text-align: left;
    font: normal normal bold 10px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  `}
`;

export const FlightSummaryCardDetails = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding-top: 10px;

    div {
      display: flex;
      flex-direction: column;
    }

    img {
      width: 180px;
      float: left;
      border-radius: 5px;
      margin-right: 15px;
    }
  `}
`;

export const HotelDetails = styled.div`
  ${({ theme }) => css`
    display: flex;
    ${theme.breakpoints.down('xs')} {
      flex-wrap: wrap;
    }

    img {
      width: 220px;
      height: 180px;
      object-fit: cover;
      border-top-left-radius: 6px;

      ${theme.breakpoints.down('xs')} {
        width: 100%;
        border-top-right-radius: 6px;
      }
    }

    .roomDetails {
      margin-left: 15px;
      margin-top: 15px;

      .cancel-policies {
        color: #ff5757;
        margin-top: 5px;
      }
    }

    .content {
      margin-left: 15px;
      margin-top: 15px;
      border-bottom: 1px solid #e6e6e6;

      ${theme.breakpoints.down('xs')} {
        margin-left: 0px;
        padding-left: 10px;
        padding-right: 10px;
      }

      .hotel-details {
        display: flex;
        width: 100%;

        .hotel-name {
          font-size: 16px;
          font-weight: 600;
          padding-right: 50px;
        }

        .hotel-rate {
          background-color: #18a563;
          color: #fff;
          font-size: 12px;
          font-weight: 700;
          border-radius: 2px;
          padding-left: 3px;
          padding-right: 3px;
          margin-right: 3px;
        }

        .hotel-rate-level {
          opacity: 0.41;
          color: #000;
          font-size: 12px;
        }
      }

      .location {
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;
        span {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          svg {
            color: #fff;
            stroke: ${rgba('#000', 0.7)};
            stroke-width: 1.7px;
          }
        }
        opacity: 0.41;
        color: #000;
        font-size: 12px;
      }
    }

    .stars {
      color: #ffb900;
      display: flex;
      flex-direction: row;
    }
  `}
`;

export const CardContent = styled(Grid)`
  ${({ theme }) => css`
    display: flex;

    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
  `}
`;

export const CarDetails = styled(Grid)`
  ${({ theme }) => css`
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 15px;

    ${theme.breakpoints.down('xs')} {
      flex-wrap: wrap;
    }

    .car-picture {
      width: 120px;
      margin-top: 10px;
      margin-left: 5px;
      object-fit: contain;

      ${theme.breakpoints.down('xs')} {
        width: 40%;
      }
    }

    .car-details1 {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      margin-left: 20px;
      margin-bottom: 20px;

      ${theme.breakpoints.down('xs')} {
        width: 50%;
      }

      .company-logo {
        max-width: 65px;
        max-height: 50px;
        width: auto;
        height: auto;
        object-fit: contain;
      }

      .car-type {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
      }

      .car-model {
        width: 100%;
        font-size: 11px;
        font-weight: 500;
      }
    }

    .car-details2 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      ${theme.breakpoints.down('xs')} {
        margin-top: 15px;
      }

      div {
        display: flex;
        justify-content: space-between;

        div {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          width: 35px;

          span {
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            bottom: 0px;
            padding-top: 5px;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  `}
`;

export const BottomDetails = styled(Grid)`
  ${({ theme }) => css`
    display: flex;
    padding: 20px;

    ${theme.breakpoints.down('xs')} {
      flex-wrap: wrap;
    }

    .place-details {
      display: flex;
      width: 90%;

      ${theme.breakpoints.down('xs')} {
        width: 100%;
      }

      div {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        padding-right: 20px;

        ${theme.breakpoints.down('xs')} {
          width: 100%;
          padding-right: 0;
        }

        span {
          width: 100%;
          font-size: 11px;

          ${theme.breakpoints.down('xs')} {
            &.place {
              margin-bottom: 15px;
            }
          }

          &.hour {
            font-weight: 200;
            padding-bottom: 10px;
          }
        }
      }
    }

    .reservation-details {
      display: flex;
      align-items: center;
      justify-content: center;
      ${theme.breakpoints.down('xs')} {
        width: 100%;
      }
      div {
        display: flex;
        flex-wrap: wrap;

        ${theme.breakpoints.down('xs')} {
          justify-content: space-between;
          flex-wrap: nowrap;
          width: 100%;
        }

        span {
          font-weight: 400;
          font-size: 11px;
          color: #4d7e4f;
          width: 100%;
        }
      }
    }
  `}
`;

export const SideContent = styled.div`
  ${({ theme }) => css`
    border-left: 1px solid #e6e6e6;
    width: 20%;
    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;

      ${theme.breakpoints.down('xs')} {
        margin-bottom: 15px;

        div {
          display: flex;
          align-items: center;
        }
      }

      justify-content: center;

      .price-detail {
        width: 70%;
        border: 1px solid #e6e6e6;
        margin-top: 15px;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 20px;

        ${theme.breakpoints.down('xs')} {
          border: none;
          width: 50%;
        }

        .total-price-less-discount {
          color: ${(props) => (props.discount === 0 ? '#000000' : '#4D7E4F')};
          font-size: 20px;
          font-weight: 700;
          display: flex;

          .cifrao {
            margin-top: 3px;
            font-size: 13px;
            margin-right: 3px;
            font-weight: normal;
          }
        }

        div {
          .discount {
            color: #4d7e4f;
            font-weight: 700;
          }

          .discount-value {
            padding-left: 10px;
            padding-right: 10px;
            text-decoration: line-through;
            opacity: 0.31;
          }
        }
      }

      .price-info {
        margin-top: 10px;
        flex-wrap: wrap;
        font-size: 11px;
        width: 90%;
        display: flex;
        justify-content: center;
        align-content: center;
        height: auto;

        ${theme.breakpoints.up('xs')} {
          width: 50%;
        }

        span {
          width: 100%;
          text-align: center;

          ${theme.breakpoints.up('xs')} {
            text-align: left;
          }
        }
      }

      .button {
        ${theme.breakpoints.up('xs')} {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  `}
`;
