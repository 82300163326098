import { Grid } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: auto;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 25px;
  font-family: 'Poppins';
`;

export const Amenities = styled.div`
  display: flex;
  flex-wrap: wrap;

  span {
    width: 100%;
    display: flex;
    svg {
      margin-right: 5px;
    }
  }
`;

export const RoomName = styled.span`
  ${({ theme }) => css`
    font-weight: 700;
    font-size: 15px;
    width: 100%;
    margin: 10px 0px;
    color: ${theme.palette.primary.main};
  `}
`;
export const RoomImage = styled.img`
  width: 180px;
  height: 150px;
  object-fit: cover !important;
  border-radius: 6px;
`;

export const GridFlexible = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 50%;

  span {
    display: flex;
    justify-content: center;
    opacity: 50%;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  span {
    width: 100%;
    display: flex;
  }
`;

export const Description = styled.span`
  ${({ theme }) => css`
    font-weight: 700;
    font-size: 15px;
    width: 100%;
    margin: 10px 0px;
    color: #000;
    opacity: 75%;
  `}
`;

export const PriceDetails = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;

    span {
      display: flex;
      justify-content: flex-end;
    }

    button {
      width: 60%;
      span {
        justify-content: center;
      }
    }
  `}
`;

export const TotalValue = styled.div`
  ${({ theme }) => css`
    font-weight: 700;
    font-size: 25px;
    letter-spacing: 1px;
    color: #000;
    opacity: 75%;
  `}
`;

export const NightsQty = styled.span`
  ${({ theme }) => css`
    font-weight: 800;
    font-size: 15px;
    width: 100%;
    /* margin: 10px 0px; */
    color: #000;
    opacity: 75%;
  `}
`;

export const DailyValue = styled.span`
  ${({ theme }) => css`
    font-weight: 300;
    font-size: 15px;
    width: 100%;
    color: #000;
    opacity: 60%;
  `}
`;

export const Policies = styled.div`
  ${({ theme }) => css`
    font-size: 10px;
    width: 100%;
    letter-spacing: 1px;
    color: #000;
    opacity: 75%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;

    p {
      text-align: right;
      padding-left: 20px;
    }
  `}
`;

export const PoliciesTitle = styled.span`
  ${({ theme }) => css`
    font-size: 12px;
    color: red;
    margin-top: 7px;
    margin-bottom: 5px;
  `}
`;

export const PoliciesTexts = styled.span`
  ${({ theme }) => css`
    font-size: 10px;
    font-weight: 200;
  `}
`;

export const PoliciesTitle2 = styled.span`
  ${({ theme }) => css`
    font-size: 10px;
    font-weight: 600;
    color: #000;
    opacity: 75%;
    margin-right: 3px;
  `}
`;
