import { db } from '../services/firebase';
import deburr from 'lodash/deburr';
import api from '../services/api';
import getHeaders from '../utils/returnHeaders';

export default class AirportsRepository {
  getAirports_old = (term) => {
    const findResult = deburr(term).toLowerCase();
    let baseQuery = db.collection('airports').limit(50);
    return baseQuery.where('keyWords', 'array-contains', findResult).get();
  };

  getAirports = async (term) => {
    const header = getHeaders();

    const findResult = deburr(term).toLowerCase();

    return api.get(`/flight/airports?name=${findResult}`, header);
  };

  getAirportsByIataApi = async (iata) => {
    const header = getHeaders();

    const res = await api.get(`/flight/airports?name=${iata}`, header);

    return res?.data?.find((x) => x.iata === iata);
  };

  getLocations = (airport, country) => {
    const header = getHeaders();

    return api.get(`/car/locations/${airport}?country=${country}`, header);
  };

  getAirportsByIata = (term) => {
    let baseQuery = db.collection('airports').where('iata', '==', term);

    return baseQuery.get();
  };

  getAirportCars = async (term) => {
    const header = getHeaders();

    const findResult = deburr(term).toLowerCase();

    return api.get(`/car/cities?name=${findResult}`, header);
  };

  getRentalLocation = (iata, country) => {
    const header = getHeaders();

    return api.get(`/car/locations/${iata}?country=${country}`, header);
  };
}
