import styled, { css } from "styled-components";
import Busy from "../../../assets/icons/Busy.svg";

export const BusContainer = styled.div`
  display: flex;
  position: relative;
  left: 48px;
  width: 90%;
  height: 300px;
  background-image: ${(props) => `url(${props.BusModel})`};
  background-size: contain;
  background-repeat: no-repeat;
`;

export const SeatButton = styled.button`
  color: #fff;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;

  ${(props) =>
    props.available &&
    !props.selected &&
    css`
      background-color: #1256cc;
    `}
  ${(props) =>
    !props.available &&
    css`
      color: rgba(0, 0, 0, 0);
      background-image: url(${Busy});
      background-size: contain;
      cursor: not-allowed;
      pointer-events: none;
    `}
  ${(props) =>
    props.selected &&
    css`
      background-color: #eace3b;
      color: #000;
    `}
`;

export const LegendContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
`;

export const LegendColor = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 4px;
  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;

export const LegendColorBusy = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 4px;
  ${(props) =>
    props.color &&
    css`
      background-image: url(${Busy});
      background-size: contain;
    `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    font-family: "Poppins";
    font-size: 16px;
    font-weight: bold;
    display: flex;
    width: 100%;
    padding-left: 5px;
    justify-content: space-between;
    margin-bottom: 30px;

    .count {
      font-size: 12px;
      font-weight: normal;
      display: flex;
      flex-direction: column;
      padding-right: 5px;
    }
    }
  `}
`;

export const LoadText = styled.span`
  ${({ theme }) => css`
    font-size: 26px;
    font-weight: 800;
    color: #545454;
  `}
`;

export const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 315px;
`;
