import React, { useEffect, useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { green } from "@material-ui/core/colors";

import * as S from "./styles";
import getFareDetails from "../../repositories/getFareDetails";
import { LinearProgress } from "@material-ui/core";

import { getSessionStorage } from "../../utils/getSessionStorage";

function FareDetails({ open, onClose, cia, farecode }) {
	function createData(tipo, disponibilidade) {
		return { tipo, disponibilidade };
	}

	const [loading, setLoading] = useState(true);
	const [rows, setRows] = useState([]);
	const [response, setResponse] = useState([]);

	useEffect(() => {
		//????
		setRows([
			createData("1 mala de mão + 1 item pessoal", true),
			createData("1 mala despachada incluida", response?.weitghtLuggageIncluded === 0 ? false : true),
			// createData('Acumula milhas', false),
			createData("Assento conforto", response?.comfortSeat ? response?.comfortSeat : false),
			createData("Alteração / cancelamento", response?.allowChange ? response?.allowChange : false),
			createData("Reembolso", response?.percentageRefund ? response?.percentageRefund : false),
			// createData('Prioridade no checkin', false),
		]);
	}, [cia, farecode, response]);

	useEffect(() => {
		setLoading(true);
		if (open) {
			const cache = getSessionStorage("@fareDetailsCache");

			let cacheSession = [];

			if (cache?.length > 0) {
				cacheSession = JSON.parse(cache);
			}

			const fare = cacheSession.find((item) => item.airlineCode === cia && item.fareBaseCode === farecode);

			if (fare) {
				setResponse(fare);
				setLoading(false);
			} else {
				getFareDetails({ cia, farecode }).then((res) => {
					if (res.data) {
						cacheSession?.push(res.data);
						sessionStorage.setItem("@fareDetailsCache", JSON.stringify(cacheSession));

						setResponse(res.data);
						setLoading(false);
					} else {
						setLoading(false);
					}
				});
			}
		}
	}, [cia, farecode, open]);

	return (
		<S.Modal>
			<Dialog open={open} onClose={() => onClose(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="customized-dialog-title" onClose={() => onClose(false)}>
					Detalhes da Tarifa
				</DialogTitle>
				<DialogContent dividers>
					{loading ? (
						<>
							Buscando detalhes da tarifa...
							<LinearProgress />
						</>
					) : (
						<>
							<TableContainer>
								<Table className={""} aria-label="caption table">
									<TableHead>
										<TableRow>
											<TableCell>Descrição</TableCell>
											<TableCell align="left">{response.fareBaseName ? response.fareBaseName : ""}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row) => (
											<TableRow key={row.tipo}>
												<TableCell component="th" scope="row" align="left">
													{row.tipo}
												</TableCell>
												<TableCell align="right">
													{typeof row.disponibilidade !== "boolean" ? (
														<span>{row.disponibilidade}%</span>
													) : !row.disponibilidade ? (
														<HighlightOffIcon color="error" className="disabled" fontSize="small" />
													) : (
														<CheckCircleOutlineIcon style={{ color: green[600] }} className="enabled" fontSize="small" />
													)}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					)}
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={() => onClose(false)} color="primary">
						Voltar
					</Button>
				</DialogActions>
			</Dialog>
		</S.Modal>
	);
}

export default FareDetails;
