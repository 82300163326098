import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: auto;
  `}
`;

export const LoadText = styled.span`
  ${({ theme }) => css`
    font-size: 28px;
    font-weight: 800;
    color: #545454;
    height: 118px;
  `}
`;
