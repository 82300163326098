import styled, { css } from "styled-components";
import { isDiferentiatedBrands } from "../../../themes/themes";
import { getBrandStorage } from "../../../utils/getBrand";

const brand = getBrandStorage();
export const Wrapper = styled.div`
  font-family: "Poppins" !important;

  ${({ theme }) => css`
    p {
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .filter-group {
      display: flex;
      flex-direction: column;
    }

    .outlined-input-iconed {
      background-color: ${isDiferentiatedBrands(brand)
        ? theme.palette.secondary.white
        : "#FFF"};
      border-radius: 20px;
      height: 40px;
    }

    .MuiFormControlLabel-root {
      width: 100%;
      height: 100%;
      padding-top: 0px;

      .MuiFormControlLabel-label {
        width: 100%;
      }
    }

    .MuiFormControlLabel-labelPlacementStart {
      margin-left: 0px;
    }

    .MuiSwitch-root {
      position: relative;
      left: -70px;
    }
  `}
`;

export const FilterTitle = styled.span`
  color: rgba(0, 0, 0, 0.87);
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
`;

export const ThinText = styled.div`
  ${({ theme }) => css`
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 200;
  `}
`;
