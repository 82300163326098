import React from 'react';
import * as S from './styles';
import Loading from '../../assets/img/loading.gif';

const AuthenticationLoading = () => {
  return (
    <S.Wrapper>
      <span>Autenticando</span>
      <img src={Loading} alt='Carregando' />
    </S.Wrapper>
  );
};

export default AuthenticationLoading;
