import React from "react";

import { withRouter } from "react-router-dom";
import EntertainmentDetails from "../../components/EntertainmentDetails";

function Entertainment({ searchState, buscaAtual }) {
  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      {searchState ? (
        <EntertainmentDetails
          searchState={searchState}
          buscaAtual={buscaAtual}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default withRouter(Entertainment);
