import * as S from './styles';

function ThemeButton({ className, children, onClick, disabled }, props) {
  return (
    <S.ThemeButton
      {...props}
      disabled={disabled}
      onClick={onClick}
      className={`${className} ${disabled ? 'disabled' : ''}`}
    >
      {children}
    </S.ThemeButton>
  );
}

export default ThemeButton;
