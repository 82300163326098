import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const ModalBox = styled(Box)`
    background-color: #fff;
    padding: 20px;
    max-width: 600px;
    width: 100%;
    margin: auto;
    border-radius: 8px;
    position: relative;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    th, td {
        border: 1px solid #e0e0e0;
        padding: 10px;
        text-align: center;
    }

    th {
        background-color: #f5f5f5;
        font-weight: bold;
    }
`;