/* eslint-disable array-callback-return */
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useGlobal } from '../../../contexts/globalContext';
import getCompanyLogos from '../../../mock/companyLogos';

import FlightCard from './FlightCard';
import ResumoFlightsCard from './ResumoFlightsCard';
import * as S from './styles';

const FlightList = ({
  filters,
  searchState,
  recommendations,
  resumos,
  loading,
  buscaAtual,
}) => {
  const { setLoading } = useGlobal();
  const theme = useTheme();
  const downOfSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [flightsValueResume, setFlightsValueResume] = useState([]);

  const [flightGoingSelected, setFlightGoingSelected] = useState();
  const [flightReturnSelected, setFlightReturnSelected] = useState();

  const onChangeGoingSelected = (value) => setFlightGoingSelected(value);
  const onChangeReturnSelected = (value) => setFlightReturnSelected(value);

  useEffect(() => {}, [flightGoingSelected, flightReturnSelected]);

  useEffect(() => {
    let arr = [];
    resumos?.map((item) => {
      let obj = {
        logo: getCompanyLogos(item.validatingcarrier),
        direct: 0,
        oneStop: 0,
        // moreOneStop: 0,
      };
      item?.availabilitysummarydetail.map((detail) => {
        if (detail.stopsnumber === 0)
          obj.direct = detail.minimumtotalfare.total
            ? detail.minimumtotalfare.total
            : 0;
        if (detail.stopsnumber === 1)
          obj.oneStop = detail.minimumtotalfare.total
            ? detail.minimumtotalfare.total
            : 0;
        if (detail.stopsnumber > 1)
          obj.moreOneStop = detail.minimumtotalfare.total
            ? detail.minimumtotalfare.total
            : 0;
      });

      arr.push(obj);
    });
    setFlightsValueResume(arr);
  }, [resumos, setFlightsValueResume]);

  useEffect(() => {
    setLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return loading ? (
    <>
      <Grid container spacing={3}>
        <Grid item sm={12} md={12} lg={12}>
          <Skeleton
            animation='wave'
            variant='rect'
            width={'100%'}
            height={118}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Skeleton
            animation='wave'
            variant='rect'
            width={'100%'}
            height={300}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Skeleton
            animation='wave'
            variant='rect'
            width={'100%'}
            height={300}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Skeleton
            animation='wave'
            variant='rect'
            width={'100%'}
            height={300}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Skeleton
            animation='wave'
            variant='rect'
            width={'100%'}
            height={300}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Skeleton
            animation='wave'
            variant='rect'
            width={'100%'}
            height={300}
          />
        </Grid>
      </Grid>
    </>
  ) : (
    <div>
      <S.Wrapper>
        <Grid container>
          {!downOfSm && recommendations?.length > 0 && (
            <Grid item sm={12}>
              <ResumoFlightsCard
                flightsValueResume={flightsValueResume}
                buscaAtual={buscaAtual}
              />
            </Grid>
          )}
          <Grid item xs={12} md={12} lg={12}>
            <Grid container>
              {recommendations && recommendations.length > 0 ? (
                recommendations.map((recommendation, index) => (
                  <Grid item xs={12} key={index}>
                    <FlightCard
                      recommendation={recommendation}
                      filter={filters}
                      idaVolta={searchState.idavolta}
                      adults={searchState.adultos}
                      childs={searchState.criancas}
                      babies={searchState.bebes}
                      flightGoingSelected={flightGoingSelected}
                      flightReturnSelected={flightReturnSelected}
                      onChangeGoingSelected={onChangeGoingSelected}
                      onChangeReturnSelected={onChangeReturnSelected}
                      buscaAtual={buscaAtual}
                    />
                  </Grid>
                ))
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </S.Wrapper>
    </div>
  );
};

export default FlightList;
