import React from 'react';
import { useEffect, useState } from 'react';
import * as S from './styles';
import CheckboxFilter from '../../CheckboxFilter';
import { Slider } from '@material-ui/core';
import CurrencyFormat from 'react-currency-format';
import { Skeleton } from '@material-ui/lab';
import { useGlobal } from '../../../contexts/globalContext';
import { addFeeByDestinoFerias } from '../../../utils/addFeeByDestinoFerias';

const CarFilter = ({
	changeFilter,
	searchState,
	results,
	loading,
	error,
	buscaAtual,
}) => {
	const [state, setState] = useState({
		carType: [],
		rentalCias: [],
	});
	const [value, setValue] = useState([0, 1000]);
	const [amount, setAmount] = useState([0, 500]);
	const [rentalCias, setRentalCias] = useState([]);
	const [carTypes, setCarTypes] = useState([]);
	const { user, signed } = useGlobal();
	const [shortenCoinAlias, setShortenCoinAlias] = useState('Pts');

	const getValuesByUser = (val) => {
		let value = signed
			? val * user.catalog.catalogCurrencyConfig.conversionRate
			: val;

		if (user.campaign) value = addFeeByDestinoFerias(value, buscaAtual);

		return signed ? Math.ceil(Math.round(value)) : value;
	};
	// const onChangeFilters = (e) => {
	//   setState({ ...state, [e.target.name]: e.target.value });
	// };

	useEffect(() => {
		changeFilter(state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, setState]);

	useEffect(() => {
		let cias = [];
		let types = [];
		let values = [];

		// eslint-disable-next-line array-callback-return
		results.map((res) => {
			if (!cias.find((x) => x.name === res.companyCode)) {
				cias.push({
					name: res.companyCode,
					label: res.companyName,
					value: res.totalAmount,
					disabled: false,
				});
			}

			if (!types.find((x) => x.name === res.category)) {
				types.push({
					name: res.category,
					label:
						res.categoryName.charAt(0).toUpperCase() +
						res.categoryName.slice(1).toLowerCase(),
					value: res.totalAmount,
					disabled: false,
				});
			}

			values.push(res.totalAmount);
		});

		setAmount([0, Math.max.apply(null, values)]);
		setValue([0, Math.max.apply(null, values)]);
		setRentalCias(cias);
		setCarTypes(types);
	}, [results]);

	const changeRentalCias = (obj) => {
		let rentalCias = state.rentalCias;
		if (obj.checked) {
			rentalCias.push(obj.name);
		} else {
			rentalCias = rentalCias.filter((c) => c !== obj.name);
		}
		setState({ ...state, rentalCias });
	};

	const changeCarTypes = (obj) => {
		let carType = state.carType;
		if (obj.checked) {
			carType.push(obj.name);
		} else {
			carType = carType.filter((c) => c !== obj.name);
		}
		setState({ ...state, carType });
	};

	useEffect(() => {
		setState({
			...state,
			amount: {
				valorInicial: amount[0],
				valorFinal: amount[1],
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [amount]);

	useEffect(() => {
		setShortenCoinAlias(
			user?.campaign?.campaignConfig?.shortenCoinAlias || 'Pts'
		);
	}, [user?.campaign?.campaignConfig?.shortenCoinAlias]);

	return loading ? (
		<Skeleton variant='rect' width={'100%'} height={1000} />
	) : (
		<div>
			{!error && (
				<S.Wrapper>
					<div className='filter-group'>
						<S.FilterTitle>Locadoras</S.FilterTitle>
						{rentalCias.map((cbx, index) => (
							<CheckboxFilter
								key={index}
								label={cbx.label}
								name={cbx.name}
								disabled={cbx.disabled}
								valueLabel={getValuesByUser(cbx.value)}
								onChange={(obj) => changeRentalCias(obj)}
							/>
						))}
					</div>
					<div className='filter-group'>
						<S.FilterTitle>Tipos de Carros</S.FilterTitle>
						{carTypes.map((cbx, index) => (
							<CheckboxFilter
								key={index}
								label={cbx.label}
								disabled={cbx.disabled}
								name={cbx.name}
								valueLabel={getValuesByUser(cbx.value)}
								onChange={(obj) => changeCarTypes(obj)}
							/>
						))}
					</div>
					<div className='filter-group'>
						<S.FilterTitle>Valor</S.FilterTitle>
						<S.ThinText>
							{signed && !user?.guest && !user?.campaign?.disablePointActivity ? (
								<span>
									{getValuesByUser(amount[0])} {shortenCoinAlias} -{' '}
									{getValuesByUser(amount[1])} {shortenCoinAlias}
								</span>
							) : (
								<>
									{' '}
									<CurrencyFormat
										value={amount[0]}
										displayType={'text'}
										decimalSeparator=','
										decimalScale={2}
										prefix={'R$ '}
									/>{' '}
									-{' '}
									<CurrencyFormat
										value={amount[1]}
										displayType={'text'}
										decimalSeparator=','
										decimalScale={2}
										prefix={'R$ '}
									/>
								</>
							)}
						</S.ThinText>

						<Slider
							value={amount}
							onChange={(event, newValue) => setAmount(newValue)}
							valueLabelDisplay='off'
							aria-labelledby='range-slider'
							min={0}
							max={value[1]}
						/>
					</div>
				</S.Wrapper>
			)}
		</div>
	);
};

export default CarFilter;
