import * as S from './styles';
import CurrencyFormat from 'react-currency-format';
import { Grid } from '@material-ui/core';

import ThemeButton from '../ThemeButton';
import { useHistory } from 'react-router-dom';

import CheckIcon from '@material-ui/icons/Check';
import RoomRate from './RoomRate';
import { useState, useEffect } from 'react';
import { useGlobal } from '../../contexts/globalContext';
import CarouselComponent from '../Carousel';
import { addFeeByDestinoFerias } from '../../utils/addFeeByDestinoFerias';
import RegisterModal from '../RegisterModal';

const Room = ({
	roomName,

	nights,
	policies,
	roomDetails,
	hotel,
	hotelDetails,
	searchState,
	buscaAtual,
	room,
}) => {
	const { user, signed } = useGlobal();
	const [isOpenRegisterModal, setIsOpenRegisterModal] = useState(false);
	const [shortenCoinAlias, setShortenCoinAlias] = useState('Pts');
	// const roomImages =
	//   roomDetails?.multimediaDescriptionsType.multimediaDescriptions[1]?.imageItemsType?.imageItems.map(
	//     (image) => image.url?.address
	//   );

	const getValuesByUser = (val) => {
		let value = signed
			? val * user.catalog.catalogCurrencyConfig.conversionRate
			: val;

		if (user.campaign) value = addFeeByDestinoFerias(value, buscaAtual);

		return signed ? Math.ceil(Math.round(value)) : value;
	};

	let history = useHistory();

	const [rateSelected, setRateSelected] = useState(room.roomRates[0]);

	const onChangeRate = (rate) => setRateSelected(rate);

	const arrayImgs =
		room.guestRoom?.multimediaDescriptionsType?.multimediaDescriptions[1]?.imageItemsType?.imageItems.map(
			(imgs) => imgs.url.address
		) || [];

	useEffect(() => {
		setShortenCoinAlias(
			user?.campaign?.campaignConfig?.shortenCoinAlias || 'Pts'
		);
	}, [user?.campaign?.campaignConfig?.shortenCoinAlias]);

	const handleRegisterModal = () => setIsOpenRegisterModal((prev) => !prev);
	const handleChooseRoom = () => {
		sessionStorage.setItem(
			'@roomSelected',
			JSON.stringify({
				roomName,
				nights,
				totalTax:
					rateSelected?.[
						rateSelected.total.currencyCode === 'BRL'
							? 'total'
							: 'origTotal' || 'total'
					].amountAfterTax,
				policies,
				roomDetails,
				hotel,
				searchState,
				rateSelected,
				room,
			})
		);
		if (user?.guest || user?.travelVoucher) {
			return handleRegisterModal();
		}
		history.push('/busca/resumo');
	};

	return (
		<S.Wrapper>
			<RegisterModal
				open={isOpenRegisterModal}
				onClose={handleRegisterModal}
				travelVoucherUser={user?.travelVoucher}
			/>
			<Grid container spacing={2}>
				<S.GridFlexible item xs={12} lg={3}>
					{/* <S.RoomImage src={images[0]} alt='room image' />   */}
					<S.RoomImageContainer>
						<CarouselComponent buscaAtual={buscaAtual} type='card-room' images={arrayImgs} />
					</S.RoomImageContainer>
					<S.Amenities>
						{room.guestRoom?.amenitiesType?.roomAmenities.map((amenitie) => (
							<span>
								<CheckIcon fontSize='small' />
								{amenitie.roomAmenity}
							</span>
						))}
					</S.Amenities>
				</S.GridFlexible>
				<S.GridFlexible item xs={12} lg={6}>
					<S.RoomName>{roomName}</S.RoomName>
					<S.DescriptionContainer>
						<S.Description>Descrição</S.Description>
						<span>
							{room?.roomDescription?.description || room?.roomRates[0]?.description}
						</span>
					</S.DescriptionContainer>
					<S.RoomRatesContainer>
						{room.roomRates.map((rate) => (
							<RoomRate
								rate={rate}
								onChangeRate={onChangeRate}
								rateSelected={rateSelected}
								buscaAtual={buscaAtual}
							/>
						))}
					</S.RoomRatesContainer>
				</S.GridFlexible>
				<S.GridFlexible item xs={12} lg={3}>
					<S.PriceDetails>
						<S.TotalValue>
							{signed && !user?.guest && !user?.campaign?.disablePointActivity ? (
								<span>
									{getValuesByUser(
										rateSelected?.[
											rateSelected.total.currencyCode === 'BRL'
												? 'total'
												: 'origTotal' || 'total'
										].amountAfterTax
									)}{' '}
									{shortenCoinAlias}
								</span>
							) : (
								<CurrencyFormat
									value={
										rateSelected?.[
											rateSelected.total.currencyCode === 'BRL'
												? 'total'
												: 'origTotal' || 'total'
										].amountAfterTax
									}
									displayType={'text'}
									decimalSeparator=','
									decimalScale={2}
									prefix={'R$ '}
								/>
							)}
						</S.TotalValue>
						<S.NightsQty>
							por {nights} noite(s) x {searchState?.qtRooms} quarto(s)
						</S.NightsQty>
						<S.DailyValue>
							{signed && !user?.guest && !user?.campaign?.disablePointActivity ? (
								<span>
									{getValuesByUser(
										rateSelected?.[
											rateSelected.total.currencyCode === 'BRL'
												? 'total'
												: 'origTotal' || 'total'
										].amountAfterTax / nights
									)}{' '}
									{shortenCoinAlias}
								</span>
							) : (
								<CurrencyFormat
									value={
										rateSelected?.[
											rateSelected.total.currencyCode === 'BRL'
												? 'total'
												: 'origTotal' || 'total'
										].amountAfterTax / nights
									}
									displayType={'text'}
									decimalSeparator=','
									decimalScale={2}
									prefix={'R$ '}
								/>
							)}
							/diária
						</S.DailyValue>

						{policies &&
							policies.map((policie) => (
								<S.Policies>
									<S.PoliciesTitle>Politicas de cancelamento</S.PoliciesTitle>
									<p>
										<strong>Nome: </strong>
										{policie.name}
										<br />
										<strong>Descrição: </strong>
										{policie.description}
										<br />
										<strong>Valor (R$): </strong>
										{policie.value}
										<br />
										<strong>Valor (%): </strong>
										{policie.percent}
									</p>
								</S.Policies>
							))}
						<ThemeButton
							onClick={() => {
								handleChooseRoom();
							}}
							className='choose-hotel'
						>
							Escolher Quarto
						</ThemeButton>
					</S.PriceDetails>
				</S.GridFlexible>
			</Grid>
		</S.Wrapper>
	);
};

export default Room;
