import React from "react";
import * as S from "./styles";
import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";

const BusStep = ({ steps, departDate, departArrivalDate }) => {
    const hasDifferentArrivalDate = departDate !== departArrivalDate;

    return (
        <S.BusStep>
            <S.StepLabel>
                <span>
                    {departDate
                        ? format(parseISO(departDate), "dd MMMM", {
                              locale: pt,
                          })
                        : "N/A"}
                </span>
                <span>
                    {hasDifferentArrivalDate && (
                        <span>{`${format(
                            parseISO(departArrivalDate),
                            "dd MMMM",
                            { locale: pt }
                        )}`}</span>
                    )}
                </span>
            </S.StepLabel>
            <div className="steps">
                <S.Steps className="step-left">
                    <div className="step" />
                </S.Steps>
                <div className="ligature" />
                <S.Steps className="step-right">
                    <div className="step" />
                </S.Steps>
            </div>
            <S.StepLabel>
                <span>{steps.origin ? steps.origin : "N/A"}</span>
                <span>{steps.destiny ? steps.destiny : "N/A"}</span>
            </S.StepLabel>
        </S.BusStep>
    );
};

export default BusStep;
