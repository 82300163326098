import api from '../services/api';
import getHeaders from '../utils/returnHeaders';

const getCars = async (data) => {
  const header = getHeaders();

  return api.post(`/car`, JSON.stringify(data), header);
};

export { getCars };
