import styled, { css } from "styled-components";
import { isDiferentiatedBrands } from "../../../themes/themes";
import { getBrandStorage } from "../../../utils/getBrand";
import { rgba } from "polished";

const brand = getBrandStorage();

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: auto;
    width: 100%;
    background-color: ${isDiferentiatedBrands(brand)
      ? theme.palette.secondary.white
      : "#FFF"};
    box-shadow: 0px 1px 4px #15223214;
    opacity: 1;
    margin-top: 10px;
    margin-bottom: 15px;
    border: 1px solid ${rgba("#707070", 0.17)};
    border-radius: 10px !important;

    ${theme.breakpoints.down("xs")} {
      flex-wrap: wrap;
    }
  `}
`;

export const CounterText = styled.span`
  ${({ theme }) => css`
    font-size: 16px;
    font-weight: 400;
    height: 28px;
    font-family: "Poppins";
    color: #023566;
    padding-left: 10px;
  `}
`;

export const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
`;
