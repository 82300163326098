import styled from 'styled-components';

export const Modal = styled.div`
  .enabled {
    color: green;
  }

  .disabled {
    color: red;
  }
`;
