import React from "react";

import * as S from "./styles";

import { withRouter, useHistory } from "react-router-dom";
import Gif from "../../assets/img/bookingError.png";
import { Typography } from "@material-ui/core";
import ThemeButton from "../../components/ThemeButton";

import { getSessionStorage } from "../../utils/getSessionStorage";

function BookingError({ type, searchState, buscaAtual }) {
	let history = useHistory();
	const bookingError = getSessionStorage('@bookingError');
	const reprocessar = getSessionStorage('reprocessar');
	return (
		<S.Wrapper>
			<img src={Gif} alt="Compra concluida" />
			<Typography variant="h5">Erro ao realizar reserva!</Typography>
			<Typography variant="span" color="error">
				{bookingError}
			</Typography>

			<ThemeButton
				onClick={() => {
					reprocessar ? history.push("/conta/minhas-viagens") : history.push("/busca/resumo");
				}}
				className="pesquisa"
			>
				Tentar novamente
			</ThemeButton>
		</S.Wrapper>
	);
}

export default withRouter(BookingError);
