import { useState, useEffect } from 'react';
import { useGlobal } from '../../../../contexts/globalContext';
import { addFeeByDestinoFerias } from '../../../../utils/addFeeByDestinoFerias';
import * as S from './styles';

const ResumoFlightsCard = ({ flightsValueResume, buscaAtual }) => {
	const { user, signed } = useGlobal();
	const [shortenCoinAlias, setShortenCoinAlias] = useState('Pts');

	const getValuesByUser = (val) => {
		let value = signed
			? val * user.catalog.catalogCurrencyConfig.conversionRate
			: val;

		if (user.campaign) value = addFeeByDestinoFerias(value, buscaAtual);

		return signed ? Math.ceil(Math.round(value)) : value;
	};

	useEffect(() => {
		setShortenCoinAlias(
			user?.campaign?.campaignConfig?.shortenCoinAlias || 'Pts'
		);
	}, [user?.campaign?.campaignConfig?.shortenCoinAlias]);

	return (
		<div>
			<S.Wrapper>
				<table>
					<tbody>
						<tr>
							<th></th>
							{flightsValueResume.map((val) => (
								<th key={`${val.direct}${val.moreOneStop}${val.oneStop}`}>
									<S.ImageLogo src={val.logo} alt='logo' />
								</th>
							))}
						</tr>
						<tr>
							<td className='column-title'>Direto</td>
							{flightsValueResume.map((val) => (
								<td
									className='column-value'
									key={`${val.direct}${val.moreOneStop}${val.oneStop}`}
								>
									{val.direct > 0 ? (
										<>
											<span>{`${
												signed && !user?.guest && !user?.campaign?.disablePointActivity
													? shortenCoinAlias
													: 'R$'
											}`}</span>{' '}
											{signed && !user?.guest && !user?.campaign?.disablePointActivity
												? getValuesByUser(val.direct)
												: val.direct.toLocaleString('pt-BR', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
												  })}
										</>
									) : (
										''
									)}
								</td>
							))}
						</tr>
						<tr>
							<td className='column-title'>1 Parada</td>

							{flightsValueResume.map((val) => (
								<td
									className='column-value'
									key={`${val.direct}${val.moreOneStop}${val.oneStop}`}
								>
									{val.oneStop > 0 ? (
										<>
											<span>{`${
												signed && !user?.guest && !user?.campaign?.disablePointActivity
													? shortenCoinAlias
													: 'R$'
											}`}</span>{' '}
											{signed && !user?.guest && !user?.campaign?.disablePointActivity
												? getValuesByUser(val.oneStop)
												: val.oneStop.toLocaleString('pt-BR', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
												  })}
										</>
									) : (
										''
									)}
								</td>
							))}
						</tr>
						<tr>
							<td className='column-title last'>+1 Parada</td>

							{flightsValueResume.map((val) => (
								<td
									className='column-value last'
									key={`${val.direct}${val.moreOneStop}${val.oneStop}`}
								>
									{val.moreOneStop > 0 ? (
										<>
											<span>{`${
												signed && !user?.guest && !user?.campaign?.disablePointActivity
													? shortenCoinAlias
													: 'R$'
											}`}</span>{' '}
											{signed && !user?.guest && !user?.campaign?.disablePointActivity
												? getValuesByUser(val.moreOneStop)
												: val.moreOneStop.toLocaleString('pt-BR', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
												  })}
										</>
									) : (
										''
									)}
								</td>
							))}
						</tr>
					</tbody>
				</table>
				{/* <Grid container>
          <S.Grid item sm={12}>
            <S.GridContent container>
              <Grid item lg={3} xs={3} className='grid-item'>
                <span></span>
                <span>Direto</span>
                <span>1 parada</span>
              </Grid>
              {flightsValueResume.slice(0, 4).map((connection, index) => (
                <Grid item lg={3} xs={3} className='grid-item' key={index}>
                  <img src={connection.logo} alt='logo' />
                  <span>
                    <span>Pts </span>
                    <span>
                      {connection.direct
                        ? connection.direct.toLocaleString('pt-BR')
                        : ''}
                    </span>
                  </span>
                  <span>
                    <span>Pts </span>
                    <span>
                      {connection.oneStop
                        ? connection.oneStop.toLocaleString('pt-BR')
                        : ''}
                    </span>
                  </span>
                </Grid>
              ))}
            </S.GridContent>
          </S.Grid>
        </Grid> */}
			</S.Wrapper>
		</div>
	);
};

export default ResumoFlightsCard;
