import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: auto;
    font-family: 'Poppins';
    background-color: #fff;
    margin-bottom: 50px;

    display: flex;

    flex-direction: column;
    align-items: center;
    padding: 50px;

    img {
      width: 200px;
    }

    .copyButton {
      width: 50px;
    }
  `}
`;
