import React, { useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import * as S from './styles';
import { TextField, MenuItem, Grid } from '@material-ui/core';

function ChooseChidsAge({
  open,
  onClose,
  qtdChilds,
  setQtChilds,
  handleChangeChildsAge,
}) {
  const [childAges, setChildAges] = useState([]);
  const [numberOfChilds, setNumberOfChilds] = useState(0);
  // const [error, setError] = useState(false);

  const ages = Array(17)
    .fill()
    .map((_, idx) => idx + 1);

  const [childs, setChilds] = useState([]);

  useEffect(() => {
    setNumberOfChilds(qtdChilds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtdChilds]);

  useEffect(() => {
    setQtChilds(numberOfChilds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfChilds]);

  useEffect(() => {
    if (qtdChilds > 0) {
      const teste = Array(qtdChilds)
        .fill()
        .map((_, idx) => `Idade do menor ${idx + 1}`);
      setChildAges(
        Array(qtdChilds)
          .fill()
          .map(() => 0)
      );
      setChilds(teste);
    } else onClose();
  }, [onClose, qtdChilds]);

  return (
    <S.Modal>
      <Dialog
        maxWidth='xs'
        open={open}
        onClose={() => {
          handleChangeChildsAge(childAges);
          onClose(false);
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle onClose={() => onClose(false)}>
          Idade das crianças
        </DialogTitle>
        <DialogContent dividers>
          <S.InputChilds
            className='inputChilds'
            onChange={(e) => setNumberOfChilds(parseInt(e.target.value))}
            variant='outlined'
            fullWidth
            label='Crianças'
            type='number'
            value={numberOfChilds}
          />{' '}
          <Grid container spacing={2}>
            {childs.map((child, index) => (
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  select
                  value={childAges[index]}
                  label={child}
                  onChange={(e) =>
                    setChildAges({
                      ...childAges,
                      [index]: e.target.value,
                    })
                  }
                >
                  {ages.map((age) => (
                    <MenuItem key={age} value={parseInt(age)}>
                      {age}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* {error && (
            <S.Typography color='error'>
              Por favor, preencha a idade de todos os menores
            </S.Typography>
          )} */}
          <Button
            autoFocus
            onClick={() => {
              handleChangeChildsAge(childAges);
              onClose(false);
            }}
            color='primary'
          >
            Voltar
          </Button>
        </DialogActions>
      </Dialog>
    </S.Modal>
  );
}

export default ChooseChidsAge;
