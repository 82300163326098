/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { useGlobal } from '../../../contexts/globalContext';
import HotelCardOld from './HotelCardOld';
import * as S from './styles';

const HotelListOld = ({ filters, searchState, stays, loading, buscaAtual }) => {
  const [staysFiltered, setStaysFiltered] = useState([]);
  const { setLoading } = useGlobal();

  useEffect(() => {
    setLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const filterByHotelName = (stays) => {
    return filters?.hotelName
      ? stays?.filter((s) => {
        const matchStars = filters.stars.length === 0 || filters.stars.includes(s.basicPropertyInfo?.award?.rating);
        return matchStars && s.basicPropertyInfo?.hotelRef?.hotelName
          ?.toLowerCase()
          .includes(filters.hotelName.toLowerCase());
      })
      : stays;
  };

  const filterByStars = (stays) => {
    return filters?.stars.length > 0
      ? stays?.filter(
        (s) =>
          filters.stars.indexOf(s.basicPropertyInfo?.award?.rating) !== -1
      )
      : stays;
  };

  const filterByDailyPrice = (stays) => {
    return filters?.dailyPrice
      ? stays?.filter((s) => {
        const matchStars = filters.stars.length === 0 || filters.stars.includes(s.basicPropertyInfo?.award?.rating);
        return matchStars && s.price.amountaftertax >= filters?.dailyPrice[0] &&
          s.price.amountaftertax <= filters?.dailyPrice[1];
      })
      : stays;
  };

  const returnArrayOfValuesFilteredTrue = (field) => {
    return stays?.filter((stay) => {
      const matchStars = filters.stars.length === 0 || filters.stars.includes(stay.basicPropertyInfo?.award?.rating);
      const matchMeals = stay.roomStays.roomTypes.find((roomType) =>
        roomType.roomRates.find(
          (roomRate) => roomRate.ratePlans?.mealsIncluded?.[field] === true
        )
      );
      return matchStars && matchMeals;
    });
  };

  const returnArrayOfValuesFilteredFalse = (field) => {
    return stays?.filter((stay) => {
      const matchStars = filters.stars.length === 0 || filters.stars.includes(stay.basicPropertyInfo?.award?.rating);
      const matchMeals = stay.roomStays.roomTypes.find((roomType) =>
        roomType.roomRates.find(
          (roomRate) => roomRate.ratePlans?.mealsIncluded?.[field] === false
        )
      );
      return matchStars && matchMeals;
    });
  };

  const mealsIncludedFilterBreakfast = (stays) => {
    return filters?.breakfast
      ? returnArrayOfValuesFilteredTrue('breakfast')
      : stays;
  };

  const mealsIncludedFilterLunch = (stays) => {
    return filters?.lunch ? returnArrayOfValuesFilteredTrue('lunch') : stays;
  };

  const mealsIncludedFilterDinner = (stays) => {
    return filters?.dinner ? returnArrayOfValuesFilteredTrue('dinner') : stays;
  };

  const noBreakfast = (stays) => {
    return filters?.noBreakfast
      ? returnArrayOfValuesFilteredFalse('breakfast')
      : stays;
  };

  useEffect(() => {
    let filteredStays = stays;
    filteredStays = filterByHotelName(filteredStays);
    filteredStays = filterByStars(filteredStays);
    filteredStays = filterByDailyPrice(filteredStays);
    filteredStays = mealsIncludedFilterBreakfast(filteredStays);
    filteredStays = mealsIncludedFilterLunch(filteredStays);
    filteredStays = mealsIncludedFilterDinner(filteredStays);
    filteredStays = noBreakfast(filteredStays);
    setStaysFiltered(filteredStays);
  }, [stays, filters]);

  return loading ? (
    <>
      <Grid container spacing={3}>
        <Grid item sm={12} md={12} lg={12}>
          <Skeleton
            animation='wave'
            variant='rect'
            width={'100%'}
            height={118}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Skeleton
            animation='wave'
            variant='rect'
            width={'100%'}
            height={300}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Skeleton
            animation='wave'
            variant='rect'
            width={'100%'}
            height={300}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Skeleton
            animation='wave'
            variant='rect'
            width={'100%'}
            height={300}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Skeleton
            animation='wave'
            variant='rect'
            width={'100%'}
            height={300}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Skeleton
            animation='wave'
            variant='rect'
            width={'100%'}
            height={300}
          />
        </Grid>
      </Grid>
    </>
  ) : (
    <div>
      <S.Wrapper>
        <Grid container>
          <Grid item xs={12} md={12} lg={10}>
            <Grid container>
              {staysFiltered &&
                staysFiltered.length > 0 &&
                staysFiltered
                  .sort(
                    (a, b) => a.price.amountaftertax - b.price.amountaftertax
                  )
                  .map((hotel, index) => (
                    <Grid item xs={12} key={index}>
                      <HotelCardOld
                        buscaAtual={buscaAtual}
                        hotel={hotel}
                        searchState={searchState}
                      // hotel={{
                      //   pictures: [hotel.basicPropertyInfo.imageURL],
                      //   name: `${hotel.basicPropertyInfo.hotelRef.hotelName}`,
                      //   stars: hotel.basicPropertyInfo?.award?.rating,
                      //   location: hotel.basicPropertyInfo.address.addressLine,
                      //   centerDistance: 0.46,
                      //   price: hotel.price.amountbeforetax,
                      //   nights:
                      //     (new Date(hotel.price.end) -
                      //       new Date(hotel.price.start)) /
                      //     (1000 * 3600 * 24),
                      //   before: hotel.price.amountaftertax,
                      //   rate: 7.9,
                      //   rateLevel: 'Bom',
                      //   reviews: 120,
                      //   code: hotel.basicPropertyInfo.hotelRef.hotelCode,
                      //   rooms: hotel.roomStays.roomTypes,
                      //   hotel,
                      // }}
                      />
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        </Grid>
      </S.Wrapper>
    </div>
  );
};

export default HotelListOld;
