import api from '../services/api';
import { db } from '../services/firebase';
import getHeaders from '../utils/returnHeaders';

const hasGuestLoginInThisCampaign = {
	hasGuestLoginInThisCampaign:
		process.env.REACT_APP_GUEST_LOGIN?.toLowerCase() === 'true',
};
export default class BookingsRepository {
	getAllBookingsUser = (userId, type) => {
		if (type === 'ALL') {
			let baseQuery = db
				.collection('bookings')
				.where('userId', '==', userId)
				.limit(50);
			return baseQuery.get();
		} else {
			let baseQuery = db
				.collection('bookings')
				.where('userId', '==', userId)
				.where('type', '==', type)
				.limit(50);
			return baseQuery.get();
		}
	};

	postBooking = (data) => {
		const header = getHeaders();
		Object.assign(data, hasGuestLoginInThisCampaign);
		return api.post(`/booking`, JSON.stringify(data), {
			timeout: 300000,
			...header,
		});
	};

	reprocessarPagamento = (locator, data) => {
		const header = getHeaders();
		Object.assign(data, hasGuestLoginInThisCampaign);
		return api.post(
			`/booking/issue-ticket?locator=${locator}`,
			JSON.stringify(data),
			{
				headers: {
					accept: 'application/json',
					'content-type': 'application/json',
					...header.headers,
				},
				timeout: 300000,
			}
		);
	};

	reenviarVoucher = (locator) => {
		const header = getHeaders();

		return api.post(
			`/booking/send-voucher?locator=${locator}`,
			{},
			{
				headers: {
					accept: 'application/json',
					'content-type': 'application/json',
					...header.headers,
				},
				timeout: 300000,
			}
		);
	};

	confirm2fa = (locator, token2fa, data) => {
		const header = getHeaders();
		Object.assign(data, hasGuestLoginInThisCampaign);
		return api.post(
			`/booking/issue-ticket?locator=${locator}&token2fa=${token2fa}`,
			data ? data : {},
			{
				headers: {
					accept: 'application/json',
					'content-type': 'application/json',
					...header.headers,
				},
				timeout: 300000,
			}
		);
	};

	resend2fa = (locator) => {
		const header = getHeaders();

		return api.post(`/booking/auth2fa/resend?locator=${locator}`, '', {
			headers: {
				accept: 'application/json',
				'content-type': 'application/json',
				...header.headers,
			},
			timeout: 300000,
		});
	};

	cancelBooking = (locator) => {
		const header = getHeaders();

		return api.post(`/booking/cancel?locator=${locator}`, '', {
			headers: {
				accept: 'application/json',
				'content-type': 'application/json',
				...header.headers,
			},
			timeout: 300000,
		});
	};

	validateDiscount = (data) => {
		const header = getHeaders();
		Object.assign(data, hasGuestLoginInThisCampaign);
		return api.post(`/booking/coupon/check`, data, {
			headers: {
				accept: 'application/json',
				'content-type': 'application/json',
				...header.headers,
			},
			timeout: 300000,
		});
	};
}
