import styled, { css } from 'styled-components';

export const Steps = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .step {
      background-color: #023566;
      width: 5px;
      height: 5px;
    }
  `}
`;

export const StepLabel = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 10px;
  `}
`;

export const FlightStep = styled.div`
  ${({ theme }) => css`
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
    font-family: 'Poppins';

    .steps-number {
      font-size: 10px;
      font-weight: 500;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .colored {
      color: #2271cf;
    }
    .steps {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .ligature {
      position: relative;
      width: 99%;
      height: 1px;
      opacity: 0.3;
      background-color: #023566;
      top: 3px;
    }
  `}
`;
