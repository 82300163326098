import { styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { getCampaignColors } from "../../utils/getCampaignColors";

const campaignColors = getCampaignColors();

export const DecrementButton = styled(Button)(({ theme }) => ({
  fontFamily: "Poppins",
  color: "#7f7f7f",
  fontSize: "16px",
  fontWeight: 900,
  height: "22px",
  lineHeight: "22px",
  whiteSpace: "nowrap",
  border: "none",

  "&:disabled": {
    color: "#7f7f7f",
    border: "none",
  },
}));

export const IncrementButton = styled(Button)(({ theme }) => ({
  fontFamily: "Poppins",
  color: `${
    campaignColors.searchColor
      ? campaignColors.searchColor
      : theme.palette.primary.main
  }`,
  fontSize: "16px",
  fontWeight: 900,
  height: "22px",
  lineHeight: "22px",
  whiteSpace: "nowrap",
  border: "none",
}));

export const CounterDisplay = styled(Button)(({ theme }) => ({
  fontFamily: "Poppins",
  color: "#333",
  fontSize: "16px",
  fontWeight: 700,
  height: "22px",
  lineHeight: "22px",
  whiteSpace: "nowrap",
  border: "none",
  backgroundColor: "transparent",
  pointerEvents: "none",

  "&:disabled": {
    color: "#333",
    border: "none",
  },
}));
