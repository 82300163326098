import styled, { css } from "styled-components";
import { getBrandStorage } from "../../utils/getBrand";
import { getCampaignColors } from "../../utils/getCampaignColors";
import { isDiferentiatedBrands } from "../../themes/themes";

import Typography from "@mui/material/Typography";

const brand = getBrandStorage();
const campaignColors = getCampaignColors();

export const Wrapper = styled.div`
	${({ theme }) => css`
		margin-bottom: 50px;
		margin-top: 50px;
	`}
`;

export const ButtonTrip = styled.button`
	${({ theme }) => css`
		border-radius: 10px;
		border-color: transparent;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		color: #ffffff;
		background-color: ${isDiferentiatedBrands(brand)
			? theme.palette.secondary.white
			: campaignColors.headerColor
			? campaignColors.headerColor
			: theme.palette.primary.main};
		width: 154px;
		height: 45px;
		font-size: 15px;
		font-weight: 600;
		text-transform: none;
		cursor: pointer;
	`}
`;

export const TitleTrip = styled(Typography)`
	${({ theme }) => css`
		font-size: 35px !important;
		font-weight: 700 !important;
		color: ${isDiferentiatedBrands(brand)
			? theme.palette.secondary.black
			: campaignColors.headerColor
			? campaignColors.headerColor
			: theme.palette.primary.main};
	`}
`;

export const LinkMailTrip = styled.a`
	${({ theme }) => css`
		color: ${isDiferentiatedBrands(brand)
			? theme.palette.secondary.black
			: campaignColors.headerColor
			? campaignColors.headerColor
			: theme.palette.primary.main};
	`}
	font-size: 16px;
	font-weight: 400;
`;
