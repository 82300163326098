import api from '../services/api';
import { db, storage } from '../services/firebase';
import getHeaders from '../utils/returnHeaders';

export default class UserRepository {
	getUserData = (voucherUser) => {
		const header = getHeaders();

		if (voucherUser) {
			return api.get(`/user/${voucherUser}`, header);
		} else {
			return api.get(`/user`, header);
		}
	};

	getUserPointsBalance = async () => {
		const header = getHeaders();
		return await api.get(`/user/balance-history`, header);
	};

	getUserDetails = (userId) => {
		let baseQuery = db.collection('user').where('userId', '==', userId).limit(50);
		return baseQuery.get();
	};

	changePasswordFirstAccess = (newPassword) => {
		const header = getHeaders();
		return api.put(`/user/first-access/${btoa(newPassword)}`, '', header);
	};

	getStorageImages = async (filePath) => {
		var storageRef = storage.ref();

		var stg = storageRef.child(`${filePath}`);

		const img = await stg
			.getDownloadURL()
			.then((url) => url)
			.catch(() => null);

		return img;
	};

	createNewUser = (data) => {
		const header = getHeaders();
		return api.post(`/user`, data, header);
	};
}
