import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ThemeButton from "../ThemeButton";

export default function SimpleBusModal({
  opened,
  openedState,
  policies,
  closeButtonText,
  allowCloseByClickOutside,
  closeButtonDisabledTillScrollEnded,
}) {
  const handleClose = () => openedState(false);
  const [bottom, setBottom] = React.useState(false);
  const [contentArea, setContentArea] = React.useState(null);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    setBottom(bottom);
  };

  React.useEffect(() => {
    if (contentArea) {
      setBottom(
        contentArea.scrollHeight - contentArea.scrollTop ===
          contentArea.clientHeight
      );
    }
  }, [contentArea]);

  return (
    <React.Fragment>
      <Dialog
        open={opened}
        onClose={allowCloseByClickOutside ? handleClose : null}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          onScroll={handleScroll}
          ref={(el) => {
            setContentArea(el);
          }}
        >
          {policies.map((policy, index) => (
            <div key={index}>
              <DialogTitle id={`alert-dialog-title-${index}`}>
                {policy.name}
              </DialogTitle>
              <DialogContentText
                id={`alert-dialog-description-${index}`}
                dangerouslySetInnerHTML={{ __html: policy.description }}
              ></DialogContentText>
            </div>
          ))}
        </DialogContent>

        <DialogActions>
          <ThemeButton
            className="payment"
            onClick={handleClose}
            disabled={closeButtonDisabledTillScrollEnded && !bottom}
          >
            {closeButtonText}
          </ThemeButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
