import * as React from "react";
import { useTheme } from "@material-ui/core";

const RightArrow = (props) => {
	const theme = useTheme();

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 15 15" {...props}>
			<path
				data-name="BusTraveling"
				d="M3.125 1.87536V13.1254C3.12535 13.2393 3.15677 13.3509 3.21586 13.4483C3.27495 13.5457 3.35947 13.6251 3.46034 13.678C3.56121 13.7309 3.6746 13.7553 3.7883 13.7485C3.902 13.7418 4.01171 13.7042 4.10562 13.6397L12.2306 8.01473C12.5675 7.78161 12.5675 7.22036 12.2306 6.98661L4.10562 1.36161C4.01191 1.2965 3.90214 1.25832 3.78825 1.25121C3.67436 1.24411 3.5607 1.26835 3.45962 1.3213C3.35853 1.37426 3.2739 1.45391 3.21491 1.55159C3.15591 1.64927 3.12482 1.76125 3.125 1.87536Z"
				fill={`${theme.palette.secondary.main}`}
				strokeWidth={2}
			/>
		</svg>
	);
};

export default RightArrow;
