import { Grid } from '@material-ui/core';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { isDiferentiatedBrands } from '../../../../themes/themes';
import { getBrandStorage } from '../../../../utils/getBrand';

const brand = getBrandStorage();

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: auto;
    width: 100%;
    background-color: ${isDiferentiatedBrands(brand)
      ? theme.palette.secondary.white
      : '#FFF'};
    border-radius: 6px !important;
    margin-top: 15px;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    display: flex;
    font-family: 'Poppins';
  `}
`;

export const CardContent = styled(Grid)`
  ${({ theme }) => css`
    display: flex;
  `}
`;

export const HotelDetails = styled(Grid)`
  ${({ theme }) => css`
    display: flex;
    ${theme.breakpoints.down('xs')} {
      flex-wrap: wrap;
    }

    img {
      width: 220px;
      height: 180px;
      object-fit: cover;
      border-top-left-radius: 6px;

      ${theme.breakpoints.down('xs')} {
        width: 100%;
        border-top-right-radius: 6px;
      }
    }

    .content {
      margin-left: 15px;
      margin-top: 15px;
      border-bottom: 1px solid #e6e6e6;

      ${theme.breakpoints.down('xs')} {
        margin-left: 0px;
        padding-left: 10px;
        padding-right: 10px;
      }

      .hotel-details {
        display: flex;
        width: 100%;

        .hotel-name {
          font-size: 16px;
          font-weight: 600;
          padding-right: 50px;
        }

        .hotel-rate {
          background-color: #18a563;
          color: #fff;
          font-size: 12px;
          font-weight: 700;
          border-radius: 2px;
          padding-left: 3px;
          padding-right: 3px;
          margin-right: 3px;
        }

        .hotel-rate-level {
          opacity: 0.41;
          color: #000;
          font-size: 12px;
        }
      }

      .location {
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;
        span {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          svg {
            color: #fff;
            stroke: ${rgba('#000', 0.7)};
            stroke-width: 1.7px;
          }
        }
        opacity: 0.41;
        color: #000;
        font-size: 12px;
      }
    }

    .stars {
      color: #ffb900;
    }
  `}
`;

export const BottomDetails = styled(Grid)`
  ${({ theme }) => css`
    margin: 10px;
    display: flex;
    justify-content: flex-end;

    div {
      display: flex;
      align-items: center;

      ${theme.breakpoints.down('xs')} {
        flex-wrap: wrap;
        width: 100%;
        justify-content: flex-end;
      }

      .old-value {
        color: #000;
        opacity: 65%;
        font-size: 12px;
        ${theme.breakpoints.down('xs')} {
          display: flex;
          justify-content: flex-end !important;
          width: 100%;
        }

        .value {
          text-decoration: line-through;
        }
      }

      .final-value {
        font-size: 23px;
        font-weight: 600;
        margin-left: 4px;

        ${theme.breakpoints.down('xs')} {
          display: flex;
          justify-content: flex-end !important;
          width: 100%;
        }
      }

      .nights {
        margin-left: 6px;
        display: flex;
        flex-direction: column;
        opacity: 32%;

        ${theme.breakpoints.down('xs')} {
          text-align: right;
          margin-bottom: 5px;
          width: auto;
        }

        .qty {
          width: 100%;
          font-size: 12px;
          font-weight: 700;
        }

        .daily-value {
          width: 100%;
          font-size: 10px;
        }
      }

      .discount {
        background-color: #fff0d1;
        margin-left: 10px;
        margin-right: 15px;
        border-radius: 2px;
        font-weight: 700;
        font-size: 10px;
        color: #e7a413;
        padding: 2px;
      }
    }
  `}
`;
