import React from 'react';

import { withRouter } from 'react-router-dom';
import HotelRoomDetails from '../../components/HotelRoomDetails';
import HotelRoomDetailsOld from '../../components/HotelRoomDetailsOld';
// import GetDistance from '../../utils/getDistanceBetweenCordinates';

function HotelDetails({ searchState, buscaAtual }) {
  // const [searchState, setSearchState] = useState();

  return (
    <div style={{ backgroundColor: '#f2f2f2' }}>
      {searchState && searchState.cidade.isoCode !== 'BR' ? (
        <HotelRoomDetails searchState={searchState} buscaAtual={buscaAtual} />
      ) : (
        <HotelRoomDetailsOld
          searchState={searchState}
          buscaAtual={buscaAtual}
        />
      )}
      {/* <GetDistance /> */}
    </div>
  );
}

export default withRouter(HotelDetails);
