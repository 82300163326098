import { Container } from '@material-ui/core';
import React from 'react';
import MaisBuscados from './MaisBuscados';
import ShowCase from './ShowCase';
// import BigCard from './BigCard';
import * as S from './styles';
// import Vitrine from './Vitrine';

const Content = ({ idCampanha, user }) => {
  return (
    <S.Wrapper>
      <Container>
        {/* <BigCard /> */}
        {user?.campaign?.campaignConfigLayout?.topDestinationsEnabled && (
          <MaisBuscados user={user} />
        )}
        {user && <ShowCase campaignId={idCampanha} user={user} />}
        {/* <Vitrine campaignId={campaignId} /> */}
      </Container>
    </S.Wrapper>
  );
};

export default Content;
