import styled, { css } from "styled-components";
import { rgba } from "polished";
import { getBrandStorage } from "../../../utils/getBrand";
import { isDiferentiatedBrands } from "../../../themes/themes";
import { Typography } from "@material-ui/core/";

const brand = getBrandStorage();

export const Wrapper = styled.div`
  ${({ theme }) => css`    
    display: flex;
    justify-content: center;
    width: 100%;
    color: #ffffff;
    font-family: 'Poppins';

    .entretenimento-control {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 140px;
      flex-direction: column;
      position: relative;
      top: 25px;

      ${theme.breakpoints.up("md")} {
        .search-divider {
          border-left 1px solid ${rgba(
            isDiferentiatedBrands(brand)
              ? theme.palette.secondary.white
              : theme.palette.secondary.main,
            0.4
          )}
        }
      }
            
      .MuiGrid-container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
      }
            
      .MuiInputBase-root, .MuiAutocomplete-root, .MuiGrid-item {
        width: 600px !important;
      }      
    }

    .submit {
      display: flex;
      justify-content: center;
      padding: 0;
    }

    .submit1 {
      width: 140px !important;
      height: 55px !important;
    }

    .MuiSvgIcon-root {
      fill: white;
    }

    .results {
      position: relative;
      left: -104px;
      top: 5px;
    }

  `}
`;
export const CustomTypography = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.secondary.main};
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    text-align: left;
    font: normal normal 10px Poppins;
    letter-spacing: 0px;
    color: ${theme.palette.secondary.white};
    opacity: 1;
  `}
`;