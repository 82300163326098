import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import * as S from "./styles";

import Passengers from "../../../../assets/icons/passengers.svg";
import CarDoors from "../../../../assets/icons/carDoors.svg";
import CarBaggage from "../../../../assets/icons/carBaggages.svg";
import CarGear from "../../../../assets/icons/carGear.svg";
import CarAc from "../../../../assets/icons/carAC.svg";
import { format, intervalToDuration, parseISO } from "date-fns";
import { ThemeButton } from "../../../ThemeButton/styles";
import { useGlobal } from "../../../../contexts/globalContext";
import CurrencyFormat from "react-currency-format";
import { addFeeByDestinoFerias } from "../../../../utils/addFeeByDestinoFerias";
import RegisterModal from "../../../RegisterModal";

const CarCard = ({ car, searchState, buscaAtual }) => {
	let history = useHistory();
	const [rentalDays, setRentalDays] = useState(0);
	const { user, signed } = useGlobal();
	const [isOpenRegisterModal, setIsOpenRegisterModal] = useState(false);
	const [shortenCoinAlias, setShortenCoinAlias] = useState("Pts");

	const handleRegisterModal = () => setIsOpenRegisterModal((prev) => !prev);

	const getValuesByUser = (val) => {
		let value = signed ? val * user?.catalog?.catalogCurrencyConfig?.conversionRate : val;

		if (user?.campaign) value = addFeeByDestinoFerias(value, buscaAtual);

		return signed ? Math.ceil(Math.round(value)) : value;
	};

	const getCompanyImage = (car) => {
		if (car?.urlLogoCompany) {
			return car.urlLogoCompany;
		} else {
			if (car.companyName?.toUpperCase() === "LOCALIZA") return "/img/localiza.svg";
			return "https://via.placeholder.com/150";
		}
	};

	useEffect(() => {
		setRentalDays(
			intervalToDuration({
				start: new Date(car?.dropoffDateTime),
				end: new Date(car?.pickupDateTime),
			}).days
		);
	}, [car?.dropoffDateTime, car?.pickupDateTime]);

	const handleSubmit = () => {
		sessionStorage.setItem("@CarSelected", JSON.stringify({ ...car, searchState }));

		if (user?.guest || user?.travelVoucher) {
			return handleRegisterModal();
		}

		history.push("/busca/resumo");
	};

	useEffect(() => {
		setShortenCoinAlias(user?.campaign?.campaignConfig?.shortenCoinAlias || "Pts");
	}, [user?.campaign?.campaignConfig?.shortenCoinAlias]);

	return (
		<div>
			<S.Wrapper>
				<RegisterModal open={isOpenRegisterModal} onClose={handleRegisterModal} travelVoucherUser={user?.travelVoucher} />
				<S.CardContent container>
					<S.CarDetails item xs={12}>
						<img className="car-picture" src={car?.urlCarImg} alt="Foto do carro" />
						<div className="car-details1">
							<img className="company-logo" src={getCompanyImage(car)} alt={car.companyName?.toUpperCase()} />
							<span className="car-type">{car?.categoryName}</span>
							<span className="car-model">{car?.model}</span>
						</div>
						<div className="car-details2">
							<div>
								<div>
									{car?.capacity && (
										<>
											<img src={Passengers} alt="Passageiros" />
											<span>{car?.capacity}</span>
										</>
									)}
								</div>
								<div>
									{car?.numberDoors && (
										<>
											<img src={CarDoors} alt="Numero de portas" />
											<span>{car?.numberDoors}</span>
										</>
									)}
								</div>
								<div>
									{car && (
										<>
											<img src={CarBaggage} alt="Numero de malas" />
											<span>{"???"}</span>
										</>
									)}
								</div>

								<div>
									<>
										<img src={CarGear} alt="Cambio" />
										<span>{car?.automaticTransmission ? "Auto" : "Man."}</span>
									</>
								</div>

								{car?.airConditioning && (
									<div>
										<>
											<img src={CarAc} alt="Ar condicionado" />
											<span>AC</span>
										</>
									</div>
								)}
							</div>
						</div>
					</S.CarDetails>
					<S.BottomDetails item xs={12}>
						<div className="place-details">
							<div>
								<span className="hour">Retirada {format(parseISO(car?.pickupDateTime), "HH:mm")}</span>
								<span className="place">{car?.pickupAddress}</span>
							</div>
							<div>
								<span className="hour">Devolução {format(parseISO(car?.dropoffDateTime), "HH:mm")}</span>
								<span className="place">{searchState?.mesmoLocal ? car?.pickupAddress : car?.dropoffAddress}</span>
							</div>
						</div>
						<div className="reservation-details">
							<div>
								{/* <span>{car.km === 0 ? 'Km Ilimitado' : car.km + ' Kms'}</span> */}
								{/* <span>{car.freeCancellation && 'Cancelamento grátis'}</span> */}
							</div>
						</div>
					</S.BottomDetails>
				</S.CardContent>

				<S.SideContent>
					<div className="content">
						<div className="price-detail">
							<span className="total-price-less-discount">
								<span className="cifrao">{signed && !user?.guest && !user?.campaign?.disablePointActivity && shortenCoinAlias}</span>
								{signed && !user?.guest && !user?.campaign?.disablePointActivity ? (
									<span>{getValuesByUser(car?.totalAmount)}</span>
								) : (
									<CurrencyFormat
										fixedDecimalScale={true}
										value={car?.totalAmount}
										displayType={"text"}
										decimalSeparator=","
										decimalScale={2}
										prefix={"R$ "}
									/>
								)}
							</span>
							{/* {car.discount !== 0 && (
                <div>
                  <span className='discount'>-{car.discount}%</span>
                  <span className='discount-value'>
                    {car.totalPrice * (car.discount / 100)}
                  </span>
                </div>
              )} */}
						</div>
						<div className="price-info">
							<span>Preço total por {rentalDays} dias</span>
							{/* <span>
                {signed ? (
                  <span>{getValuesByUser(car.dailyAmount)} pts</span>
                ) : (
                  <CurrencyFormat
                    fixedDecimalScale={true}
                    value={car.dailyAmount}
                    displayType={'text'}
                    decimalSeparator=','
                    decimalScale={2}
                    prefix={'R$ '}
                  />
                )}{' '}
                / dia
              </span> */}
						</div>
						<div className="button">
							<ThemeButton onClick={handleSubmit} className="button-select">
								Selecionar
							</ThemeButton>
						</div>
					</div>
				</S.SideContent>
			</S.Wrapper>
		</div>
	);
};

export default CarCard;
