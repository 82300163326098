/* eslint-disable array-callback-return */
import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useGlobal } from "../../../contexts/globalContext";

import * as S from "./styles";
import BusCard from "./BusCard";
import BusPrice from "./BusPrice";
import BusTraveling from "./../../../assets/icons/BusTraveling.svg";
import BusTravelingBack from "./../../../assets/icons/BusTravelingBack.svg";
import BusLoader from "../../SummaryContent/BusSummarySeats/Bus/Seats/BusLottie";

const BusList = ({ searchState, busAvailability, loading, filter }) => {
	const [busRouteDepartSelected, setBusRouteDepartSelected] = useState({});
	const onChangeDepartSelected = (value) => {
		setBusRouteDepartSelected(value);
	};
	const [busRouteReturnSelected, setBusRouteReturnSelected] = useState({});
	const onChangeReturnSelected = (value) => setBusRouteReturnSelected(value);

	const [listaIdaFiltrada, setListaIdaFiltrada] = useState([]);
	const [listaVoltaFiltrada, setListaVoltaFiltrada] = useState([]);

	useEffect(() => {
		const filterBus = (busList) => {
			if (!Array.isArray(busList)) {
				return [];
			}

			return busList.filter((bus) => {
				const isIncludedStation =
					filter.stations.length === 0 ||
					filter.stations.includes(bus.origin) ||
					filter.stations.includes(bus.destination);

				const isIncludedClass =
					filter.classes.length === 0 ||
					filter.classes.includes(bus.company.type);

				const isIncludedCompany =
					filter.companies.length === 0 ||
					filter.companies.includes(bus.company.name);

				return isIncludedStation && isIncludedClass && isIncludedCompany;
			});
		};

		if (busAvailability && busAvailability.length > 0) {
			const listaIda = [];
			const listaVolta = [];

			busAvailability[0]?.buses?.forEach((bus) => {
				const filteredBus = filterBus([bus]);
				if (filteredBus.length > 0) {
					listaIda.push(...filteredBus);
				}
			});

			busAvailability[1]?.buses?.forEach((bus) => {
				const filteredBus = filterBus([bus]);
				if (filteredBus.length > 0) {
					listaVolta.push(...filteredBus);
				}
			});

			setListaIdaFiltrada(listaIda);
			setListaVoltaFiltrada(listaVolta);
		}
	}, [busAvailability, filter]);

	const { setLoading } = useGlobal();

	useEffect(() => {
		setLoading(loading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	return loading ? (
		<Grid
			item
			container
			style={{ paddingTop: 12, paddingBottom: 12 }}
			spacing={3}
			sm={12}
			md={12}
			lg={12}
		>
			<Grid item sm={12} md={12} lg={12}>
				<S.Container>
					<BusLoader />
				</S.Container>
			</Grid>
		</Grid>
	) : (
		<>
			<Grid item sm={8} md={8} lg={8}>
				{listaIdaFiltrada.length > 0 ? (
					<>
						<S.SegmentHeader>
							<img className="bus" alt="ida" src={BusTraveling} />
							<span>IDA - {searchState?.datas.dataIda}</span>
						</S.SegmentHeader>
						<S.Wrapper>
							<Grid item xs={12} md={12} lg={12}>
								{listaIdaFiltrada.map((bus, index) => (
									<Grid item xs={12} key={index}>
										<BusCard
											setSelected={onChangeDepartSelected}
											selected={busRouteDepartSelected}
											busRoute={bus}
											searchState={searchState}
										/>
									</Grid>
								))}
							</Grid>
						</S.Wrapper>
					</>
				) : (
					<S.NotFound>
						Não encontramos resultados que correspondam aos filtros selecionados. <br />Tente ajustar os critérios de busca para obter mais opções.
					</S.NotFound>
				)}
				{searchState?.idavolta && listaVoltaFiltrada.length > 0 && (
					<>
						<S.SegmentHeader>
							<img className="bus" alt="volta" src={BusTravelingBack} />
							<span>VOLTA - {searchState?.datas.dataVolta}</span>
						</S.SegmentHeader>
						<S.Wrapper>
							<Grid container>
								{listaVoltaFiltrada.map((bus, index) => (
									<Grid item xs={12} key={index}>
										<BusCard
											setSelected={onChangeReturnSelected}
											selected={busRouteReturnSelected}
											busRoute={bus}
											searchState={searchState}
										/>
									</Grid>
								))}
							</Grid>
						</S.Wrapper>
					</>
				)}
			</Grid>
			<Grid item sm={2} md={2} lg={2}>
				<BusPrice
					searchState={searchState}
					selectedDepart={busRouteDepartSelected}
					selectedReturn={busRouteReturnSelected}
				/>
			</Grid>
		</>
	);
};

export default BusList;
