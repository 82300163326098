import api from '../services/api';
import getHeaders from '../utils/returnHeaders';

const getTermByTag = async (tag) => {
	const header = getHeaders();

	return api.get(`term/${tag}`, header);
};

export default getTermByTag;
