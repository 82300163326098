import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import {
  // Checkbox,
  // FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import FormInput from '../FormInput';

import { useGlobal } from '../../contexts/globalContext';

import BookingsRepository from '../../repositories/bookingsRepository';
import Loader from '../Loader';

function Auth2faModal({ open, onClose, booking, paymentData, type }) {
  const { showAlert, login } = useGlobal();
  let history = useHistory();

  const bookingsRepository = new BookingsRepository();
  const [state, setState] = useState({ token2fa: '' });
  const [error, setError] = useState('');
  const [openLoader, setOpenLoader] = useState(false);

  const resendCode = () => {
    setOpenLoader(true);
    bookingsRepository
      .resend2fa(
        type === 'reprocessar'
          ? booking?.data.bookingCode
          : booking?.data.bookingResponse.bookingCode
      )
      .then((res) => {
        setOpenLoader(false);
        showAlert(
          'success',
          'Código reenviado, verifique sua caixa de mensagens!'
        );
      })
      .catch((err) => {
        setOpenLoader(false);
        switch (err?.response?.status) {
          case 401:
            history.push('/session-expires');
            break;
          case 409:
            setError(err.response.data.error);
            break;
          case 504:
            sessionStorage.setItem(
              '@bookingError',
              JSON.stringify('Tempo expirou, tente novamente!')
            );
            history.push('/busca/booking-error');
            break;
          default:
            setError(`Não conseguimos reenviar, por favor, tente novamente!`);
            break;
        }
      });
  };

  const confirmCode = () => {
    setOpenLoader(true);

    bookingsRepository
      .confirm2fa(
        type === 'reprocessar'
          ? booking?.data.bookingCode
          : booking?.data.bookingResponse.bookingCode,
        state.token2fa,
        paymentData
      )
      .then((res) => {
        setOpenLoader(false);
        switch (res?.status) {
          case 201:
            setError(res?.data?.error);
            break;
          default:
            if (type === 'reprocessar') {
              showAlert('success', 'Compra reprocessada com sucesso');
              window.location.reload();
              onClose();
              login();
            } else {
              sessionStorage.setItem('@bookingCompleted', JSON.stringify(res));
              login();
              history.push('/busca/finalizado');
            }
            break;
        }

      })
      .catch((err) => {
        setOpenLoader(false);
        switch (err?.response?.status) {
          case 401:
            history.push('/session-expires');
            break;
          case 409:
            setError(err.response.data.error);
            break;
          case 504:
            sessionStorage.setItem(
              '@bookingError',
              JSON.stringify('Tempo expirou, tente novamente!')
            );
            history.push('/busca/booking-error');
            break;
          default:
            setError(`Ocorreu algum problema, por favor, tente novamente!`);
            break;
        }
      });
  };

  const onChangeField = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    setError('');
  };

  return (
    <S.Modal>
      <Loader openLoader={openLoader} />

      <Dialog
        open={open}
        // onClose={() => onClose(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          id='customized-dialog-title'
          onClose={() => onClose(false)}
        >
          Autenticação de 2 fatores
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='span'>
                Por gentileza, Informe o código enviado para o seu celular
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormInput onChange={onChangeField} name='token2fa' />
            </Grid>
          </Grid>
          {error && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='span' color='error'>
                  {error}
                </Typography>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose(false);
              type !== 'reprocessar' && history.push('/');
            }}
            color='primary'
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              resendCode();
            }}
            color='primary'
          >
            Reenviar código
          </Button>
          <Button
            onClick={() => {
              confirmCode();
            }}
            color='primary'
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </S.Modal>
  );
}

export default Auth2faModal;
