import { Grid } from "@material-ui/core";
import { rgba } from "polished";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: auto;
  `}
`;

export const CardContent = styled.div`
  ${({ theme }) => css`
    height: 100%;
    font-family: "Poppins";
    color: #023566;
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    max-height: 80%;
    
    .container {
      width: calc(100% - 16px);
      height: 90px;
      margin: 8px;
      padding: 4px;
      display: flex;
      align-items: stretch;
      flex-wrap: nowrap;
      align-items: center
      box-sizing: border-box;
      border: 1px solid ${rgba("#707070", 0.17)};
      border-radius: 6px !important;
    }

    .radio {
      align-items: center;
      justify-content: center;
      transform: scale(1.5);
    }

    .company-logo {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;

      img {
        max-height: 100%;
        width: 100%;
        float: left;
      }
    }

    .bus-line {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;
      margin: 4px;

      img {
        overflow: hidden;
      }

    }

    .duracao {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-size: 12px;
    }

    .paradas {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      font-size: 12px;
    }

    .company-name {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      width: 100%;
    }

    .departure-date {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 14px;

      ${theme.breakpoints.down("sm")} {
        font-size: 12px;
      }
    }

    .departure-city {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 14px;

      ${theme.breakpoints.down("sm")} {
        font-size: 12px;
      }
    }

    .arrival-date {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 14px;

      ${theme.breakpoints.down("sm")} {
        font-size: 12px;
      }
    }

    .arrival-city {
      justify-content: center;
      align-items: center;
      display: flex;
      width: 100%;
      font-size: 14px;

      ${theme.breakpoints.down("sm")} {
        font-size: 12px;
      }
    }

    .points-class {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Poppins";
      font-size: 14px;
      color: #263238;
    }

    .points-amount {
      font-size: 20px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
    }

    .points-person {
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
    }
  `}
`;

export const Steps = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .step {
      background-color: #023566;
      width: 5px;
      height: 5px;
    }
  `}
`;

export const StepLabel = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 10px;
  `}
`;

export const SideContent = styled.div`
  ${({ theme }) => css`
    width: 30%;
    height: fit-content;

    ${theme.breakpoints.down("md")} {
      width: 25% !important;
    }

    ${theme.breakpoints.down("sm")} {
      width: 30% !important;
    }

    ${theme.breakpoints.down("xs")} {
      width: 100% !important;
      height: 100%;
      padding-bottom: 14px;
    }

    padding-top: 14px;
    padding-left: 14px;
    padding-right: 14px;
    display: flex;
    flex-wrap: wrap;
    font-family: "Poppins";
    font-size: 9px;
    letter-spacing: 0px;

    .content {
      width: 100%;
      border: 1px solid #e0e2e6;
      border-radius: 5px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding-top: 10px;
      height: 78%;

      .total-points {
        margin-bottom: 10px;
        padding-bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;

        .label {
          padding-bottom: 6px;
          font-size: 18px;
        }

        .points {
          font-size: 28px;
          font-weight: 600;
          margin-left: 5px;
        }
      }

      .subtotais {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: 10px;
        margin-right: 10px;
        padding-bottom: 4px;
        font-size: 0.8rem;

        .points {
          font-weight: 600;
          font-family: "Poppins";

          .label2 {
            font-weight: normal;
          }
        }
      }

      .meus-pontos {
        margin-top: 8px;
      }

      .resgate-valor {
        margin-top: 8px;
        display: flex;
        align-items: center;

        span {
          text-align: center;
          span {
            color: #008b15;
            font-weight: 600;
          }
        }
      }

      .tipo-tarifa {
        color: #2271cf;
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 10px;
        margin-top: 8px;

        &:hover {
          cursor: pointer;
        }

        span {
          display: flex;
          align-items: center;
          text-align: center;
        }
      }
    }

    .card-actions {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  `}
`;

export const GridItemFlex = styled(Grid)`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;

    .alterar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-left: 1px solid ${rgba("#707070", 0.21)};
    }
  `}
`;

export const Modal = styled.div`
  .enabled {
    color: green;
  }

  .disabled {
    color: red;
  }
`;
