export const getSessionStorage = (key) => {
	const value = sessionStorage.getItem(key);
	if (value === 'undefined' || value == null) {
		sessionStorage.removeItem(key);
		return null;
	}

	try {
		if (testJSON(value)) {
			return value ? JSON.parse(value) : null;
		}
		return value ? value : null;
	} catch (error) {
		sessionStorage.removeItem(key);
		return null;
	}
};

function testJSON(text) {
	if (typeof text !== 'string') {
		return false;
	}
	try {
		JSON.parse(text);
		if (text.includes('{')) return true;

		return false;
	} catch (error) {
		return false;
	}
}
