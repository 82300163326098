import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { getBrandStorage } from '../../../utils/getBrand';
import { isDiferentiatedBrands } from '../../../themes/themes';

const brand = getBrandStorage();

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    color: #ffffff;
    font-family: 'Poppins';

    .submit1 {
      width: 100% !important;
      height: 100% !important;
    }

    .origem-destino-control {
      display: flex;
      justify-content: space-between;

      a {
      text-decoration: none;
    }

      .label-input-info {
        padding: 0;
        margin: 0;
        padding-bottom: 5px;
        margin-top: -23px;
        font-size: 12px;
      }

      ${theme.breakpoints.up('xs')} {
      padding-bottom: 29px;
      padding-top: 29px;
    }

      ${theme.breakpoints.up('md')} {
      .search-divider {
        border-left 1px solid ${rgba(
          isDiferentiatedBrands(brand)
            ? theme.palette.secondary.white
            : theme.palette.secondary.main,
          0.4
        )}
      }
      padding-bottom: 59px;
      padding-top: 59px;
    }
    }
  `}
`;
