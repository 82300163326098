import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';
import React from 'react';
import { useGlobal } from '../../../contexts/globalContext';
import Separator from '../../Separator';

import * as S from './styles';
// import Separator from '../../Separator';`

const HotelSideSummaryOld = ({
	hotel,
	total,
	totalApagar,
	cashPrice,
	pointsPrice,
	pointsTotalValue,
	cashTotalValue,
	userPoints,
	minimumPointsToBuy,
	discountPoints,
	discountCash,
}) => {
	const { signed, user } = useGlobal();

	const Header = ({ type, date, ...props }) => {
		return (
			<S.SideHeader {...props}>
				<div>
					<span>{type}</span>
				</div>
				<span className='date-header'>
					{date !== undefined &&
						date !== 'Invalid Date' &&
						format(parseISO(date), 'EEE, dd MMM yyyy', {
							locale: pt,
						})}
				</span>
			</S.SideHeader>
		);
	};

	return (
		<div>
			<Header className='first' type='Check-in' date={hotel?.hotel.price.start} />
			<S.BlankCardOnly>
				<div className='hotel-details'>
					<span className='hotel-name'>
						{hotel?.hotel.basicPropertyInfo.hotelRef.hotelName}
					</span>
					<span>Quarto: {hotel?.roomName} </span>
					<span>Total para {hotel?.nights} diárias</span>
				</div>
			</S.BlankCardOnly>
			<Header type='Checkout' date={hotel?.hotel.price.end} />
			<Separator className='solid thin' />

			<S.BlankCardOnly>
				<S.PagamentoLabel>Pagamento</S.PagamentoLabel>
				<Separator className='solid thin' margin={15} />
				<Separator className='solid' margin={15} />
				<S.Total className='wrap'>
					{signed && !user?.guest && !user?.campaign?.disablePointActivity && (
						<div>
							<span className='bold total'>Valor do Saldo</span>
							<span className='bold total'>
								{signed ? 'Pts' : 'R$'} {total}
							</span>
						</div>
					)}
					{/* <div>
      <span className='bold'>Meus Pontos</span>
      <span className='bold'>{userPoints.toLocaleString('pt-BR')}</span>
    </div> */}
				</S.Total>
				{discountPoints > 0 && (
					<S.Total>
						<span className='bold total'>Desconto aplicado em pontos</span>
						<span className='bold total'>Pts {discountPoints}</span>
					</S.Total>
				)}
				{discountCash > 0 && (
					<S.Total>
						<span className='bold total'>Desconto aplicado em R$</span>
						<span className='bold total'>
							{discountCash.toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL',
							})}
						</span>
					</S.Total>
				)}
				{user?.campaign?.acceptCashPayment && (
					<S.Total>
						<span className='bold total'>Total em R$</span>
						<span className='bold total'>
							{totalApagar.toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL',
							})}
						</span>
					</S.Total>
				)}

				{/* <S.Subtext>
    <p>
      * Tarifas adicionais da companhia aérea e do cartão de crédito podem
      ser aplicadas na emissão da passagem. Veja a fatura final
    </p>
  </S.Subtext> */}
				{signed && userPoints < minimumPointsToBuy && (
					<S.BlockBuyText>
						<p>
							** Você deve possuir ao menos {minimumPointsToBuy} pontos para realizar
							esta compra!
						</p>
					</S.BlockBuyText>
				)}
			</S.BlankCardOnly>
		</div>
	);
};

export default HotelSideSummaryOld;
