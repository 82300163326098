import React, { useEffect } from "react";
import Content from "../../components/Content";
import Header from "../../components/Header";
import SearchBar from "../../components/SearchBar";
import FooterPorto from "../../components/FooterPorto";
import { getBrandStorage } from "../../utils/getBrand";
import { useHistory } from "react-router-dom";
import { useGlobal } from "../../contexts/globalContext";

import { withRouter } from "react-router-dom";

import Footer from "../../components/Footer";
import ResetPasswordFirstAccessModal from "../../components/ResetPasswordFirstAccessModal";

function Home() {
	const { user } = useGlobal();
	let history = useHistory();

	// const [campaignId, setCampaignId] = useState(0);

	useEffect(() => {
		if (window.location.search !== "") {
			history.push("/auth/callback");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.search]);

	// useEffect(() => {
	//   setCampaignId(getSessionStorage('campaign'));

	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [signed]);

	return (
		<div style={{ backgroundColor: '#FFF' }}>
			<ResetPasswordFirstAccessModal user={user} />
			<Header type='home' />
			{(user?.campaign?.campaignConfigLayout?.flight ||
				user?.campaign?.campaignConfigLayout?.experience ||
				user?.campaign?.campaignConfigLayout?.car ||
				user?.campaign?.campaignConfigLayout?.bus ||
				user?.campaign?.campaignConfigLayout?.entertainment ||
				user?.campaign?.campaignConfigLayout?.hotel) && <SearchBar />}
			<Content idCampanha={user?.campaign?.id} user={user} />
			{getBrandStorage() === 'portoplus' ? <FooterPorto /> : <Footer />}
		</div>
	);
}

export default withRouter(Home);
