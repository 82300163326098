import React from 'react';
import DispatchBaggage from '../../assets/icons/DispatchBaggage.svg';
import HandBaggage from '../../assets/icons/HandBaggage_.svg';

import * as S from './styles';

function Baggage({ baggage }) {
  return (
    <S.Wrapper>
      <div className='baggage'>
        <img src={HandBaggage} alt='hand-baggage' className='hand-baggage' />
        {baggage === '1P' && (
          <>
            <img
              src={DispatchBaggage}
              alt='baggage'
              className='other-baggage'
            />
          </>
        )}
        {baggage === '2P' && (
          <>
            <img
              src={DispatchBaggage}
              alt='baggage'
              className='other-baggage'
            />
            <img
              src={DispatchBaggage}
              alt='baggage'
              className='other-baggage'
            />
          </>
        )}
      </div>
      <S.BaggageType>{`${
        baggage === '0P' || baggage === ''
          ? 'Bagagem de mão'
          : 'Bagagem incluída'
      } `}</S.BaggageType>
    </S.Wrapper>
  );
}

export default Baggage;
