import React from "react";
import Routes from "./routes";
import { CssBaseline } from "@material-ui/core";
import { createTheme, StylesProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import ShowAlert from "./components/ShowAlert";
import PortalHead from "./components/Content/Head";
import { useGlobal } from "./contexts/globalContext";
import Loading from "./components/Loading";
import Themes from "./themes/themes";
import GlobalLoader from "./components/GlobalLoader";

function App() {
	const { brand } = useGlobal();

	return (
		<ThemeProvider theme={createTheme(Themes[brand])}>
			<StylesProvider injectFirst>
				<PortalHead />
				<CssBaseline />
				<Loading />
				<GlobalLoader />
				<Routes />
				<ShowAlert />
			</StylesProvider>
		</ThemeProvider>
	);
}

export default App;
