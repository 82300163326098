import * as S from './styles';
import CurrencyFormat from 'react-currency-format';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useState } from 'react';

const CheckboxFilter = ({ label, valueLabel, onChange, name, disabled }) => {
  const [checked, setChecked] = useState(false);

  return (
    <S.Wrapper>
      <FormControlLabel
        name={name}
        control={
          <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={(e) => {
              setChecked(!checked);
              onChange({ name: name, checked: !checked, label: label });
            }}
          />
        }
        label={
          <CheckboxLabelAdornment
            label={label}
            value={!disabled ? valueLabel : 0}
          />
        }
      />
    </S.Wrapper>
  );
};

const CheckboxLabelAdornment = ({ label, value, currency }) => {
  return (
    <S.CheckboxLabelAdornment>
      <p>{label}</p>
      <p>
        {value !== 0 && currency ? (
          <CurrencyFormat
            value={value}
            displayType={'text'}
            decimalSeparator=','
            decimalScale={2}
            prefix={'R$ '}
          />
        ) : (
          value
        )}
      </p>
    </S.CheckboxLabelAdornment>
  );
};

export default CheckboxFilter;
