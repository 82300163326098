import { Grid } from '@material-ui/core';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { isDiferentiatedBrands } from '../../../../themes/themes';
import { getBrandStorage } from '../../../../utils/getBrand';

const brand = getBrandStorage();
export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: auto;
    width: 100%;
    background-color: ${isDiferentiatedBrands(brand)
      ? theme.palette.secondary.white
      : '#FFF'};
    border-radius: 6px !important;
    margin-top: 15px;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    display: flex;
    padding-bottom: 15px;

    ${theme.breakpoints.down('xs')} {
      flex-wrap: wrap;
    }
  `}
`;

export const CardContent = styled.div`
  ${({ theme }) => css`
    width: 70% !important;

    ${theme.breakpoints.down('md')} {
      width: 75% !important;
    }

    ${theme.breakpoints.down('sm')} {
      width: 70% !important;
    }

    ${theme.breakpoints.down('xs')} {
      width: 100% !important;
    }

    .card-actions-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 6px;

      .ver-detalhes {
        font-size: 12px;
      }

      .total-points {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        color: #008b15;

        .label {
        }

        .points {
          font-size: 18px;
          font-weight: 600;
          margin-left: 5px;
        }
      }
    }
  `}
`;

export const SegmentHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 18px;
    padding-left: 5px;
    background: #e3e3e3 0% 0% no-repeat padding-box;
    opacity: 1;
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 10px;
    border-top-left-radius: 6px;

    ${theme.breakpoints.down('sm')} {
      border-top-right-radius: 6px;
    }

    .airplane-badge {
      width: 14px;
      height: 14px;
      padding-right: 3px;
    }
  `}
`;

export const FlightSegmentOption = styled.div`
  ${({ theme }) => css`
    height: 50px;
    ${theme.breakpoints.down('sm')} {
      height: 70px;
    }
    font-family: 'Poppins';
    margin-left: 5px;
    border-bottom: 1px solid ${rgba('#707070', 0.17)};
    display: flex;
    align-items: center;

    .company-logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 25px;
        height: 25px;
      }
    }
    .flight-number {
      background-color: #e3e8ef;
      font-size: 8px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
    }

    .duracao {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-size: 9px;
      color: #023566;
    }

    .company-name {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-weight: 00;
      width: 100%;
    }

    .departure-date {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      font-size: 18px;
      color: #023566;
      padding-left: 7px;

      ${theme.breakpoints.down('sm')} {
        font-size: 14px;
      }
    }

    .arrival-date {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 18px;
      color: #023566;
      ${theme.breakpoints.down('sm')} {
        font-size: 14px;
      }
    }
  `}
`;

export const Steps = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .step {
      background-color: #023566;
      width: 5px;
      height: 5px;
    }
  `}
`;

export const StepLabel = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 10px;
  `}
`;

export const FlightStep = styled.div`
  ${({ theme }) => css`
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
    font-family: 'Poppins';

    .steps-number {
      font-size: 10px;
      font-weight: 500;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .colored {
      color: #2271cf;
    }
    .steps {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .ligature {
      position: relative;
      width: 99%;
      height: 1px;
      opacity: 0.3;
      background-color: #023566;
      top: 3px;
    }
  `}
`;

export const SideContent = styled.div`
  ${({ theme }) => css`
    width: 30%;
    height: fit-content;

    ${theme.breakpoints.down('md')} {
      width: 25% !important;
    }

    ${theme.breakpoints.down('sm')} {
      width: 30% !important;
    }

    ${theme.breakpoints.down('xs')} {
      width: 100% !important;
      height: 100%;
      padding-bottom: 14px;
    }

    padding-top: 14px;
    padding-left: 14px;
    padding-right: 14px;
    display: flex;
    flex-wrap: wrap;
    font-family: 'Poppins';
    font-size: 9px;
    letter-spacing: 0px;

    .content {
      width: 100%;
      border: 1px solid #e0e2e6;
      border-radius: 5px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding-top: 10px;
      height: 78%;

      .total-points {
        margin-bottom: 10px;
        padding-bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;

        .label {
          padding-bottom: 6px;
          font-size: 18px;
        }

        .points {
          font-size: 28px;
          font-weight: 600;
          margin-left: 5px;
        }
      }

      .subtotais {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: 10px;
        margin-right: 10px;
        padding-bottom: 4px;
        font-size: 0.8rem;

        .points {
          font-weight: 600;
          font-family: 'Poppins';

          .label2 {
            font-weight: normal;
          }
        }
      }

      .meus-pontos {
        margin-top: 8px;
      }

      .resgate-valor {
        margin-top: 8px;
        display: flex;
        align-items: center;

        span {
          text-align: center;
          span {
            color: #008b15;
            font-weight: 600;
          }
        }
      }

      .tipo-tarifa {
        color: #2271cf;
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 10px;
        margin-top: 8px;

        &:hover {
          cursor: pointer;
        }

        span {
          display: flex;
          align-items: center;
          text-align: center;
        }
      }
    }

    .card-actions {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  `}
`;

export const GridItemFlex = styled(Grid)`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;

    &.select-option,
    &.baggage {
      align-items: center;
      justify-content: center;
    }

    .plus-day {
      color: red !important;
      font-size: 15px !important;
    }
  `}
`;

export const BaggageType = styled.span`
  font-size: 9px;
`;

export const Modal = styled.div`
  .enabled {
    color: green;
  }

  .disabled {
    color: red;
  }
`;
