import * as React from "react";

const SvgCarPrimary = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    {...props}
  >
    <defs>
      <clipPath id="CarPrimary_svg__a">
        <path
          d="M32.159 31.591v1.7a1.7 1.7 0 0 1-3.409 0v-1.7h-12.5v1.7a1.7 1.7 0 0 1-3.409 0v-1.7A2.849 2.849 0 0 1 10 28.75v-6.818a2.82 2.82 0 0 1 1.278-2.358l1.761-7.045A3.428 3.428 0 0 1 16.25 10h12.5a3.428 3.428 0 0 1 3.21 2.528l1.761 7.045A2.82 2.82 0 0 1 35 21.932v6.818a2.849 2.849 0 0 1-2.841 2.841Zm-2.386-18.523a1.2 1.2 0 0 0-1.023-.8h-12.5a1.2 1.2 0 0 0-1.023.8l-1.506 6.023h17.557l-1.506-6.023Zm-17.5 8.864v6.818a.57.57 0 0 0 .568.568h19.318a.57.57 0 0 0 .568-.568v-6.818a.57.57 0 0 0-.568-.568H12.841a.57.57 0 0 0-.568.568Zm3.977 5.114a1.7 1.7 0 1 1 1.7-1.7 1.71 1.71 0 0 1-1.7 1.7Zm12.5 0a1.7 1.7 0 1 1 1.7-1.7 1.71 1.71 0 0 1-1.7 1.7Z"
          transform="translate(-10 -10)"
          fill="#fd665e"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#CarPrimary_svg__a)">
      <path fill="#fd665e" d="M-2.841-2.841h30.682v30.682H-2.841z" />
    </g>
  </svg>
);

export default SvgCarPrimary;
