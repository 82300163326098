import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';
import { useEffect, useState } from 'react';

import * as S from './styles';
import Separator from '../../Separator';
import { useGlobal } from '../../../contexts/globalContext';
import Alert from '@material-ui/lab/Alert';

const ExperienceSideSummary = ({
	total,
	totalApagar,
	cashPrice,
	pointsPrice,
	pointsTotalValue,
	cashTotalValue,
	userPoints,
	minimumPointsToBuy,
	experience,
	discountPoints,
	discountCash,
}) => {
	const { signed, user } = useGlobal();
	const [coinAlias, setCoinAlias] = useState('Pontos');
	const [shortenCoinAlias, setShortenCoinAlias] = useState('Pts');

	useEffect(() => {
		setCoinAlias(user?.campaign?.campaignConfig?.coinAlias || 'Pontos');
		setShortenCoinAlias(
			user?.campaign?.campaignConfig?.shortenCoinAlias || 'Pts'
		);
	}, [
		user?.campaign?.campaignConfig?.coinAlias,
		user?.campaign?.campaignConfig?.shortenCoinAlias,
	]);

	const Header = ({ type, date, ...props }) => {
		return (
			<S.SideHeader {...props}>
				<div>
					<span>{type}</span>
				</div>
				<span className='date-header'>
					{date !== undefined &&
						date !== 'Invalid Date' &&
						format(parseISO(date), 'EEE, dd MMM yyyy', {
							locale: pt,
						})}
				</span>
			</S.SideHeader>
		);
	};

	return (
		<div>
			<Header
				className='first'
				type='Entrada'
				date={experience?.experienceDate.date}
			/>
			<S.BlankCardOnly>
				<div className='hotel-details'>
					<span className='hotel-name'>{experience?.experience.name}</span>
					<span>Total para {experience?.adults + experience?.childs} pessoas</span>
				</div>
			</S.BlankCardOnly>

			<Separator className='solid thin' />

			<S.BlankCardOnly>
				<S.PagamentoLabel>Pagamento</S.PagamentoLabel>
				<Separator className='solid thin' margin={15} />
				<Separator className='solid' margin={15} />
				<S.Total className='wrap'>
					{signed && !user?.guest && !user?.campaign?.disablePointActivity && (
						<div>
							<span className='bold total'>Valor do Saldo</span>
							<span className='bold total'>
								{signed ? shortenCoinAlias : 'R$'} {total}
							</span>
						</div>
					)}
					{/* <div>
            <span className='bold'>Meus Pontos</span>
            <span className='bold'>{userPoints.toLocaleString('pt-BR')}</span>
          </div> */}
				</S.Total>
				{discountPoints > 0 && (
					<S.Total>
						<span className='bold total'>Desconto aplicado em {coinAlias}</span>
						<span className='bold total'>
							{shortenCoinAlias} {discountPoints}
						</span>
					</S.Total>
				)}
				{discountCash > 0 && (
					<S.Total>
						<span className='bold total'>Desconto aplicado em R$</span>
						<span className='bold total'>
							{discountCash.toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL',
							})}
						</span>
					</S.Total>
				)}
				{user?.campaign?.acceptCashPayment && (
					<S.Total>
						<span className='bold total'>Total em R$</span>
						<span className='bold total'>
							{totalApagar.toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL',
							})}
						</span>
					</S.Total>
				)}

				{/* <S.Subtext>
          <p>
            * Tarifas adicionais da companhia aérea e do cartão de crédito podem
            ser aplicadas na emissão da passagem. Veja a fatura final
          </p>
        </S.Subtext> */}
				{signed && userPoints < minimumPointsToBuy && (
					<S.AlertStyle>
						<Alert variant='filled' severity='warning'>
							<span className='text'>
								Você deve possuir ao menos <br />
								<span className='value'>
									{minimumPointsToBuy} {coinAlias}
								</span>{' '}
								para realizar esta compra!
							</span>
						</Alert>
					</S.AlertStyle>
				)}
			</S.BlankCardOnly>
		</div>
	);
};

export default ExperienceSideSummary;
