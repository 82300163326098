import styled, { css } from 'styled-components';
import DialogActions from '@material-ui/core/DialogActions';

export const Modal = styled.div`
	.enabled {
		color: green;
	}

	.disabled {
		color: red;
	}
`;

export const BlankCard = styled.div`
	${({ theme }) => css`
		height: auto;
		width: 100%;
		background: #ffffff 0% 0% no-repeat padding-box;
		box-shadow: 0px 1px 4px #15223214;
		border-radius: 6px;
		opacity: 1;
		padding: 12px;
		padding-top: 9px;
		margin-bottom: 40px;
	`}
`;

export const CreditCard = styled.div`
	${({ theme }) => css``}
`;
export const TitleWrapper = styled.div`
	${({ theme }) => css`
		width: 100vw;
		display: flex;
		justify-content: center;
		flex-direction: row;
	`}
`;

export const Title = styled.div`
	${({ theme }) => css`
		&.active {
			background-color: ${theme.palette.primary.main};
			color: ${theme.palette.secondary.white};
		}
		&.inactive {
			background-color: #ccc;
			color: ${theme.palette.secondary.white};
			cursor: pointer;
		}
		padding-top: 1.8vh;
		width: 100%;
		height: 7vh;
		text-align: center;
		font: normal normal bold 18px Poppins;
		letter-spacing: 0px;
		opacity: 1;
	`}
`;
export const CustomDialogActions = styled(DialogActions)`
	padding: 0 !important;
`;

export const ForgotPass = styled.button`
	${({ theme }) => css`
		background: none;
		color: #1166bb;
		border: none;
		padding: 0;
		font: inherit;
		cursor: pointer;
		outline: inherit;
	`}
`;