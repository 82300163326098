import cardFlag from "./getFlagCard";

const createCard64 = (name, number, date, ccv) => {
	const cardInfo = `${name?.toUpperCase()?.replace(/[^a-zA-Z ]+/g, "")}:${number?.toUpperCase()?.replace(/\D/g, "")}:${date
		?.replace(/\D/g, "")
		?.substring(2, 6)
		?.toUpperCase()}:${date?.replace(/\D/g, "")?.substring(0, 2)?.toUpperCase()}:${ccv?.toUpperCase()?.replace(/\D/g, "")}:${cardFlag
		?.getCardFlag(number)
		?.toUpperCase()
		?.replace(/[^a-zA-Z]+/g, "")}`;
	return btoa(cardInfo);
};

export default createCard64;
