import React, { useState } from "react";

import { MenuItem, TextField, InputAdornment, makeStyles, Box, Popper } from "@material-ui/core/";

import BusActive from "../../../assets/icons/BusActive.svg";
import BusTravelingActive from "../../../assets/icons/BusTravelingActive.svg";
import Calendar from "../../../assets/icons/Calendar.svg";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";

import * as S from "./styles";
import { Container } from "@material-ui/core";
import IconedInput from "../../IconedInput";
import InputDateRange from "../../InputDateRange";
import Grid from "@material-ui/core/Grid";
import { counter2 } from "../../../constants/constants";
import ThemeButton from "../../ThemeButton";
import { useEffect } from "react";
import cleanStorageBeforeSearch from "../../../utils/cleanStorageBeforeSearch";
import { Autocomplete } from "@material-ui/lab";
import InputCalendar from "../../InputDate";
import { useGlobal } from "../../../contexts/globalContext";
import { useHistory } from "react-router-dom";

import { getSessionStorage } from "../../../utils/getSessionStorage";
import BusRepository from "../../../repositories/busRepository";

const styles = (theme) => ({
	popper: {
		width: "fit-content",
	},
});

const PopperMy = function (props) {
	return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiFormHelperText-root.Mui-error": {
			color: "white",
		},
		opacity: 1,
		"& .MuiInputBase-input": {
			color: `#FFF`,
		},
		"& label.Mui-focused": {
			color: `#FFF`,
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: `#FFF`,
			color: `#FFF`,
		},
		"& .MuiFormLabel-root": {
			color: "#FFF",
			opacity: 0.45,
		},
		"& .MuiSelect-icon": {
			color: "#FFF",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: `#FFF`,
				color: `#FFF`,
			},
			"&:hover fieldset": {
				borderColor: `#FFF`,
				color: `#FFF`,
				borderWidth: 2,
			},
			"&.Mui-focused fieldset": {
				borderColor: `#FFF`,
				color: `#FFF`,
			},
			"&.Mui-error fieldset": {
				borderColor: "#FFF",
			},
		},
	},
}));

const BusSearchInputs = () => {
	const { showAlert /** , signed **/ } = useGlobal();
	let history = useHistory();

	const classes = useStyles();

	const busRepository = new BusRepository();

	const initialState = {
		origem: null,
		destino: null,
		datas: "",
		idavolta: true,
		passageiros: 1,
		nacionalidade: "Brasil",
	};

	const [state, setState] = useState(initialState);
	const [send, setSend] = useState(false);
	const [optionsDepart, setOptionsDepart] = useState([]);
	const [optionsArrival, setOptionsArrival] = useState([]);

	const numberOfPeople = () => {
		return counter2.map((count, index) => (
			<MenuItem key={index} value={count}>
				{count}
			</MenuItem>
		));
	};

	const changeOptionsDeparture = async (value) => {
		if (value.length >= 3) {
			const res = await busRepository.getBusStations(value);
			const data = await res.data;
			setOptionsDepart(data || []);
		}
	};

	const changeOptionsArrival = async (value) => {
		if (value.length >= 3) {
			const res = await busRepository.getBusStations(value);
			const data = await res.data;
			setOptionsArrival(data || []);
		}
	};

	const handleSubmit = () => {
		setSend(true);
		if (state.origem === null || state.destino === null || state.datas === "") {
			showAlert("error", "Por favor, preencha todos os campos");
		} else {
			cleanStorageBeforeSearch();
			sessionStorage.setItem("busSearch", JSON.stringify(state));
			sessionStorage.setItem("buscaAtual", "Rodoviário");
			history.push("/busca/resultado-pesquisa");
		}
	};

	useEffect(() => {
		if (getSessionStorage("busSearch")) setState(getSessionStorage("busSearch"));
	}, []);

	return (
		<S.Wrapper>
			<Container>
				<div className="idavolta-bus-control">
					<ThemeButton className={`go-and-back ${state.idavolta && "active"}`} onClick={() => setState({ ...state, idavolta: true })}>
						Ida e volta
					</ThemeButton>
					<ThemeButton className={`go-and-back ${!state.idavolta && "active"}`} onClick={() => setState({ ...state, idavolta: false })}>
						Somente ida
					</ThemeButton>
				</div>
				<div className="origem-destino-bus-control">
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12} md={3}>
							<Autocomplete
								PopperComponent={PopperMy}
								renderOption={(props, option) => {
									return (
										<li {...props}>
											<Grid container alignItems="center">
												<Grid item>
													<Box
														component={DirectionsBusIcon}
														sx={{
															bgColor: (theme) => theme.palette.secondary.main,
															mr: 3,
														}}
													/>
												</Grid>
												<Grid item xs>
													<span>{`${props.name}`}</span>
													<S.CustomTypography variant="body2">
														<b>{props.iata}</b>
													</S.CustomTypography>{" "}
												</Grid>
											</Grid>
										</li>
									);
								}}
								freeSolo
								className={classes.root}
								noOptionsText="Não encontrado"
								options={optionsDepart}
								getOptionLabel={(option) => `${option.name}`}
								onInputChange={(event, value, reason) => {
									if (reason === "input") {
										changeOptionsDeparture(value);
									}
								}}
								onChange={(event, newValue) => {
									setState({ ...state, origem: newValue });
								}}
								filterOptions={(x) => x}
								renderInput={(params) => (
									<TextField
										{...params}
										placeholder="Origem"
										variant="outlined"
										fullWidth
										className={classes.root}
										InputProps={{
											...params.InputProps,
											startAdornment: (
												<InputAdornment position="start">
													<img alt="icon" src={BusTravelingActive} />
												</InputAdornment>
											),
										}}
										error={send && state.origem === initialState.origem}
										helperText={send && state.origem === initialState.origem ? "Origem não pode ser vazio" : ""}
									/>
								)}
								value={state.origem}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={3}>
							<Autocomplete
								PopperComponent={PopperMy}
								renderOption={(props, option) => {
									return (
										<li {...props}>
											<Grid container alignItems="center">
												<Grid item>
													<Box
														component={DirectionsBusIcon}
														sx={{
															bgColor: (theme) => theme.palette.secondary.main,
															mr: 3,
														}}
													/>
												</Grid>

												<Grid item xs>
													<span>{`${props.name}`}</span>
													<S.CustomTypography variant="body2">
														<b>{props.iata}</b>
													</S.CustomTypography>{" "}
												</Grid>
											</Grid>
										</li>
									);
								}}
								freeSolo
								className={classes.root}
								noOptionsText="Não encontrado"
								options={optionsArrival}
								getOptionLabel={(option) => `${option.name}`}
								onInputChange={(event, value, reason) => {
									if (reason === "input") {
										changeOptionsArrival(value);
									}
								}}
								onChange={(event, newValue) => {
									setState({ ...state, destino: newValue });
								}}
								filterOptions={(x) => x}
								renderInput={(params) => (
									<TextField
										{...params}
										placeholder="Destino"
										variant="outlined"
										fullWidth
										className={classes.root}
										error={send && state.destino === initialState.destino}
										helperText={send && state.destino === initialState.destino ? "Destino não pode ser vazio" : ""}
										InputProps={{
											...params.InputProps,
											startAdornment: (
												<InputAdornment position="start">
													<img alt="icon" src={BusActive} />
												</InputAdornment>
											),
										}}
									/>
								)}
								value={state.destino}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={3}>
							{state.idavolta ? (
								<InputDateRange
									icon={Calendar}
									label="Data ida/volta"
									width={190}
									value={state.datas.abreviada}
									onChange={(e) => setState({ ...state, datas: e })}
									error={send && state.datas === initialState.datas}
									helperText={send && state.datas === initialState.datas ? "Data não pode ser vazia" : ""}
								/>
							) : (
								<InputCalendar
									icon={Calendar}
									label="Data da viagem"
									width={190}
									value={state.datas.dataIda}
									onChange={(e) => setState({ ...state, datas: e })}
									error={send && state.datas === initialState.datas}
									helperText={send && state.datas === initialState.datas ? "Data não pode ser vazia" : ""}
									minStartDays={7}
								/>
							)}
						</Grid>
						<Grid item xs={12} sm={12} md={3}>
							<Grid container spacing={0}>
								<Grid item xs={12} sm={5} md={5}>
									<IconedInput
										value={state.passageiros}
										select
										label="Passageiros"
										width={150}
										onChange={(e) => setState({ ...state, passageiros: e.target.value })}
										error={send && state.passageiros === 0}
										helperText={send && state.passageiros === 0 ? "Selecione pelo menos um tipo de passageiro" : ""}
									>
										{numberOfPeople()}
									</IconedInput>
								</Grid>
								<Grid item xs={12} sm={5} md={5}>
									<ThemeButton className="submit1" onClick={handleSubmit}>
										Buscar
									</ThemeButton>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</Container>
		</S.Wrapper>
	);
};

export default BusSearchInputs;
