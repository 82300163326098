/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import { useTheme, useMediaQuery, IconButton } from '@material-ui/core';
import { parseISO, format } from 'date-fns';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AirplaneGoing from '../../../../assets/icons/AirplaneGoing.svg';
import AirplaneReturn from '../../../../assets/icons/AirplaneReturn.svg';

import { ThemeButton } from '../../../ThemeButton/styles';
import { useGlobal } from '../../../../contexts/globalContext';
import FareDetails from '../../../FareDetails';
import FlightSegmentOption from './FlightSegmentOption/';
import getCompanyName from '../../../../mock/companyNames';
import { addFeeByDestinoFerias } from '../../../../utils/addFeeByDestinoFerias';
import RegisterModal from '../../../RegisterModal';
import { discountTypeEnum } from '../../../../utils/enum';

const FlightCard = ({
	filter,
	idaVolta,
	recommendation,
	adults = 1,
	childs = 1,
	babies = 1,
	flightGoingSelected,
	flightReturnSelected,
	onChangeGoingSelected,
	onChangeReturnSelected,
	buscaAtual,
}) => {
	const { showAlert, user, signed, login } = useGlobal();
	let history = useHistory();

	const { journeys, validatingcarrier, sourceid } = recommendation;
	const [isOpenRegisterModal, setIsOpenRegisterModal] = useState(false);
	const [shortenCoinAlias, setShortenCoinAlias] = useState('Pts');
	const handleRegisterModal = () => setIsOpenRegisterModal((prev) => !prev);

	const getValuesByUser = (val) => {
		let value = signed
			? val * user.catalog.catalogCurrencyConfig.conversionRate
			: val;

		if (user.campaign) value = addFeeByDestinoFerias(value, buscaAtual);

		return signed
			? Math.ceil(
					// Math.round(
					value
					// )
			  )
			: value;
	};

	const adultsTotal = getValuesByUser(
		recommendation.fares.find((x) => x.paxtype === 'ADT')?.fareamount
	);
	const childsTotal = getValuesByUser(
		recommendation.fares.find((x) => x.paxtype === 'CHD')?.fareamount
	);
	const babiesTotal = getValuesByUser(
		recommendation.fares.find((x) => x.paxtype === 'INF')?.fareamount
	);
	const fees = getValuesByUser(
		recommendation.totalfare.totaldu + recommendation.totalfare.totaltaxes
	);
	const discount = user?.campaign?.coupon?.value;

	const total = signed
		? Math.ceil(
				// Math.round(
				(adultsTotal ? adultsTotal : 0) +
					(childsTotal ? childsTotal : 0) +
					(babiesTotal ? babiesTotal : 0) +
					fees
				// )
		  )
		: (adultsTotal ? adultsTotal : 0) +
		  (childsTotal ? childsTotal : 0) +
		  (babiesTotal ? babiesTotal : 0) +
		  fees;

	const [ida, setIda] = useState([]);
	const [volta, setVolta] = useState([]);
	const [verTarifa, setVerTarifa] = useState(false);

	const openTarifaDetails = (open) => setVerTarifa(open);

	const theme = useTheme();
	const downOfXs = useMediaQuery(theme.breakpoints.down('xs'));

	const filterParadas = (segments) => {
		return segments?.filter(function (x) {
			if (
				filter?.stopsDirect ||
				filter?.stopsOneStop ||
				filter?.stopsOneMoreStop
			) {
				let tester;
				if (filter?.stopsOneMoreStop) {
					tester = x.flights.length > 2;
					if (tester === true) return tester;
				}
				if (filter?.stopsOneStop) {
					tester = x.flights.length === 2;
					if (tester === true) return tester;
				}
				if (filter?.stopsDirect) {
					tester = x.flights.length === 1;
					if (tester === true) return tester;
				}

				return tester;
			}
			return true;
		});
	};
	const filterCabins = (segments) => {
		return segments?.filter(function (s) {
			if (filter?.cabin?.length > 0) {
				let tester;
				if (
					s.flights?.filter(
						(f) => filter?.cabin?.indexOf(f.fareoption?.cabin) !== -1
					).length > 0
				) {
					tester = true;
				} else tester = false;

				return tester;
			} else {
				return true;
			}
		});
	};
	const filterHorariosIda = (segments) => {
		return segments?.filter(function (s) {
			let tester;
			if (
				format(parseISO(s.departuredatetime), 'HH:mm') >=
					filter?.horarioIda?.inicio &&
				format(parseISO(s.departuredatetime), 'HH:mm') <= filter?.horarioIda?.fim
			) {
				tester = true;
			} else {
				tester = false;
			}
			return tester;
		});
	};
	const filterHorariosVolta = (segments) => {
		return segments?.filter(function (s) {
			let tester;
			if (
				format(parseISO(s.departuredatetime), 'HH:mm') >=
					filter?.horarioVolta?.inicio &&
				format(parseISO(s.departuredatetime), 'HH:mm') <= filter?.horarioVolta?.fim
			) {
				tester = true;
			} else {
				tester = false;
			}
			return tester;
		});
	};
	const filterDuration = (segments) => {
		return segments?.filter(function (s) {
			let tester;
			if (
				s.totalDuration >= filter?.flightDuration?.inicio &&
				s.totalDuration <= filter?.flightDuration?.fim
			) {
				tester = true;
			} else {
				tester = false;
			}
			return tester;
		});
	};
	const filterConnectionAirports = (segments) => {
		return segments?.filter(function (s) {
			if (filter?.connectionAirports?.length > 0) {
				let tester;
				if (
					s.flights?.filter(
						(f) => filter?.connectionAirports?.indexOf(f?.arrivalstation) !== -1
					).length > 0
				) {
					tester = true;
				} else tester = false;

				return tester;
			} else {
				return true;
			}
		});
	};
	const filterSameAirportGoing = (segments) => {
		return segments?.filter(function (s) {
			if (filter?.sameAirport) {
				if (
					journeys[1]?.segments?.filter((f) => {
						if (
							f?.arrivalstation === s?.departurestation &&
							f?.departurestation === s?.arrivalstation
						) {
							return true;
						} else {
							return false;
						}
					}).length > 0
				) {
					return true;
				} else return false;
			} else {
				return true;
			}
		});
	};
	const filterSameAirportReturn = (segments) => {
		return segments?.filter(function (s) {
			if (filter?.sameAirport) {
				if (
					journeys[0]?.segments.filter((f) => {
						if (
							f?.arrivalstation === s?.departurestation &&
							f?.departurestation === s?.arrivalstation
						) {
							return true;
						} else {
							return false;
						}
					}).length > 0
				) {
					return true;
				} else return false;
			} else {
				return true;
			}
		});
	};
	const filterCompanies = (segments) => {
		return segments?.filter(function (s) {
			if (filter?.cias?.length > 0) {
				let tester;
				if (filter?.cias?.indexOf(s?.sourcecode) !== -1) {
					tester = true;
				} else tester = false;

				return tester;
			} else {
				return true;
			}
		});
	};
	const filterFlightNumber = (segments) =>
		segments?.filter(
			(s) =>
				`${s?.validatingcarrier}${s?.flights[0]?.flightnumber}`
					.toLowerCase()
					.replace(' ', '')
					.indexOf(filter?.flightNumber?.toLowerCase()?.replace(' ', '')) > -1
		);

	useEffect(() => {
		setIda(
			filterSameAirportGoing(
				filterFlightNumber(
					filterCompanies(
						filterConnectionAirports(
							filterDuration(
								filterHorariosIda(filterCabins(filterParadas(journeys[0].segments)))
							)
						)
					)
				)
			)
		);

		if (idaVolta) {
			setVolta(
				filterSameAirportReturn(
					filterFlightNumber(
						filterCompanies(
							filterConnectionAirports(
								filterDuration(
									filterHorariosVolta(filterCabins(filterParadas(journeys[1].segments)))
								)
							)
						)
					)
				)
			);
		}
	}, [filter, journeys]);

	const [seeDetails, setSeeDetails] = useState(false);

	useEffect(() => {
		setShortenCoinAlias(
			user?.campaign?.campaignConfig?.shortenCoinAlias || 'Pts'
		);
	}, [user?.campaign?.campaignConfig?.shortenCoinAlias]);

	const Content = () => {
		return (
			<S.Wrapper>
				<S.CardContent>
					<S.SegmentHeader>
						<img className='airplane-badge' src={AirplaneGoing} alt='ida' />
						<span>
							IDA - {format(parseISO(journeys[0].departuredate), 'dd/MM/yyyy')}
						</span>
					</S.SegmentHeader>
					{ida.length !== 0
						? ida.map((flight, index) => (
								<FlightSegmentOption
									key={index}
									flight={flight}
									sourceid={sourceid}
									validatingcarrier={validatingcarrier}
									selected={flightGoingSelected}
									setSelected={onChangeGoingSelected}
									recomendationId={recommendation.recommendationid}
									sourcecode={getCompanyName(validatingcarrier)}
									buscaAtual={buscaAtual}
								/>
						  ))
						: 'Nenhum voo de Ida, atende os filtros selecionados neste valor'}

					{idaVolta && (
						<>
							<S.SegmentHeader>
								<img className='airplane-badge' src={AirplaneReturn} alt='volta' />
								<span>
									VOLTA - {format(parseISO(journeys[1].departuredate), 'dd/MM/yyyy')}
								</span>
							</S.SegmentHeader>
							{volta.length !== 0
								? volta.map((flight, index) => (
										<FlightSegmentOption
											key={index}
											flight={flight}
											sourceid={sourceid}
											validatingcarrier={validatingcarrier}
											selected={flightReturnSelected}
											setSelected={onChangeReturnSelected}
											recomendationId={recommendation.recommendationid}
											sourcecode={getCompanyName(validatingcarrier)}
										/>
								  ))
								: 'Nenhum voo de Volta, atende os filtros selecionados neste valor'}
						</>
					)}
					{downOfXs && (
						<div className='card-actions-mobile'>
							<div className='total-points'>
								{signed && !user?.guest && !user?.campaign?.disablePointActivity && (
									<span className='label'>{shortenCoinAlias}</span>
								)}
								<span className='points'>
									{signed && !user?.guest
										? total
										: `${
												signed && !user?.guest && !user?.campaign?.disablePointActivity
													? ''
													: 'R$'
										  } ${total.toLocaleString('pt-BR', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
										  })}`}
								</span>
							</div>
							{/* {signed && (
                  <div className='total-points'>
                    <span className='label'>
                      ou R$ {totalPriceInPoints.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </div>
                )} */}
							<IconButton
								className='ver-detalhes'
								onClick={(e) => setSeeDetails(!seeDetails)}
								aria-label='delete'
							>
								{seeDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />} Detalhes
							</IconButton>

							<ThemeButton
								className='button-select'
								onClick={() => {
									if (
										idaVolta &&
										flightGoingSelected === undefined &&
										flightReturnSelected === undefined
									) {
										showAlert('error', 'Por favor, selecione algum voo!');
									} else {
										if (
											idaVolta &&
											flightGoingSelected?.split('-')[0] !==
												flightReturnSelected?.split('-')[0]
										) {
											showAlert('error', 'Por favor selecione a ida e volta');
										} else if (
											flightGoingSelected?.split('-')[0] !==
											recommendation.recommendationid.toString()
										) {
											showAlert('error', 'Por favor selecione um voo neste segmento');
										} else {
											sessionStorage.setItem(
												'@FlightSelected',
												JSON.stringify({
													recommendation: recommendation,
													going: flightGoingSelected,
													return: flightReturnSelected,
												})
											);
											login();

											history.push('/busca/resumo');
										}
									}
								}}
							>
								Selecionar
							</ThemeButton>
						</div>
					)}
				</S.CardContent>
				{!downOfXs && (
					<>
						<S.SideContent>
							<div className='content'>
								<div className='total-points'>
									{signed && !user?.guest && !user?.campaign?.disablePointActivity && (
										<span className='label'>{shortenCoinAlias}</span>
									)}
									<span className='points'>
										{signed && !user?.guest && !user?.campaign?.disablePointActivity
											? total
											: `${
													signed && !user?.guest && !user?.campaign?.disablePointActivity
														? ''
														: 'R$'
											  } ${total.toLocaleString('pt-BR', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
											  })}`}
									</span>
								</div>
								{/* {signed && (
                    <div className='total-points'>
                      <span className='label'>
                        ou R$ {totalPriceInPoints.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </span>
                    </div>
                  )} */}

								{adults !== 0 && (
									<div className='subtotais'>
										<span className='label'>{`${adults} Adulto(s): `}</span>
										<span className='points'>
											{signed && !user?.guest && !user?.campaign?.disablePointActivity
												? adultsTotal
												: `R$ ${adultsTotal.toLocaleString(
														'pt-BR',
														{ minimumFractionDigits: 2, maximumFractionDigits: 2 },
														{ minimumFractionDigits: 2, maximumFractionDigits: 2 }
												  )}`}
											{signed && !user?.guest && !user?.campaign?.disablePointActivity && (
												<span className='label2'> {shortenCoinAlias}</span>
											)}
										</span>
									</div>
								)}
								{childs !== 0 && (
									<div className='subtotais'>
										<span className='label'>{`${childs} Criança(s): `}</span>
										<span className='points'>
											{signed && !user?.guest && !user?.campaign?.disablePointActivity
												? childsTotal
												: `R$ ${childsTotal.toLocaleString('pt-BR', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
												  })}`}
											{signed && !user?.guest && !user?.campaign?.disablePointActivity && (
												<span className='label2'> {shortenCoinAlias}</span>
											)}
										</span>
									</div>
								)}
								{babies !== 0 && (
									<div className='subtotais'>
										<span className='label'>{`${babies} Bebê(s): `}</span>
										<span className='points'>
											{signed
												? babiesTotal
												: `R$ ${babiesTotal.toLocaleString('pt-BR', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
												  })}`}
											{signed && !user?.guest && !user?.campaign?.disablePointActivity && (
												<span className='label2'> {shortenCoinAlias}</span>
											)}
										</span>
									</div>
								)}
								{fees > 0 && (
									<div className='subtotais'>
										<span className='label'>{`Taxas: `}</span>
										<span className='points'>
											{signed && !user?.guest && !user?.campaign?.disablePointActivity
												? fees
												: `R$ ${fees.toLocaleString('pt-BR', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
												  })}`}
											{signed && !user?.guest && !user?.campaign?.disablePointActivity && (
												<span className='label2'> {shortenCoinAlias}</span>
											)}
										</span>
									</div>
								)}
								{discount > 0 && (
									<div className='subtotais'>
										<span className='label'>{`Desconto: `}</span>
										<span className='points'>
											{signed && !user?.guest && !user?.campaign?.disablePointActivity
												? discount
												: `R$ ${fees.toLocaleString('pt-BR', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
												  })}`}
											{signed && !user?.guest && !user?.campaign?.disablePointActivity && (
												<span className='label2'>
													{user?.campaign?.coupon?.discountType === discountTypeEnum.POINTS
														? ` ` + shortenCoinAlias
														: `%`}
												</span>
											)}
										</span>
									</div>
								)}
								{/* {signed
                    ? user.pointsBalance > 0 && (
                        <div className='subtotais meus-pontos'>
                          <span className='label'>{`Meus pontos: `}</span>
                          <span className='points'>
                            {user.pointsBalance.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            <span className='label2'> Pts</span>
                          </span>
                        </div>
                      )
                    : ''} */}
								{/* {signed &&
                    totalPriceInPoints *
                      user.catalog.catalogCurrencyConfig.conversionRate >=
                      user.pointsBalance && (
                      <div className='subtotais resgate-valor'>
                        <span className='label'>
                          Use seus pontos e mais R${' '}
                          <span>
                            {(
                              (totalPriceInPoints *
                                user.catalog.catalogCurrencyConfig
                                  .conversionRate -
                                user.pointsBalance) /
                              user.catalog.catalogCurrencyConfig.conversionRate
                            ).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}
                          </span>
                          para resgatar.
                        </span>
                      </div>
                    )} */}
							</div>

							<div className='card-actions'>
								<ThemeButton
									className='button-select'
									onClick={() => {
										if (
											idaVolta &&
											flightGoingSelected === undefined &&
											flightReturnSelected === undefined
										) {
											showAlert('error', 'Por favor, selecione algum voo!');
										} else {
											if (
												idaVolta &&
												flightGoingSelected?.split('-')[0] !==
													flightReturnSelected?.split('-')[0]
											) {
												showAlert('error', 'Por favor selecione a ida e volta');
											} else if (
												flightGoingSelected?.split('-')[0] !==
												recommendation.recommendationid.toString()
											) {
												showAlert('error', 'Por favor selecione um voo neste segmento');
											} else {
												sessionStorage.setItem(
													'@FlightSelected',
													JSON.stringify({
														recommendation: recommendation,
														going: flightGoingSelected,
														return: flightReturnSelected,
													})
												);
												if (user?.guest || user?.travelVoucher) {
													return handleRegisterModal();
												}

												history.push('/busca/resumo');
											}
										}
									}}
								>
									Selecionar
								</ThemeButton>
							</div>
						</S.SideContent>
					</>
				)}
				{downOfXs && seeDetails && (
					<>
						<S.SideContent>
							<div className='content'>
								{adults !== 0 && (
									<div className='subtotais'>
										<span className='label'>{`${adults} Adulto(s): `}</span>
										<span className='points'>
											{signed && !user?.guest && !user?.campaign?.disablePointActivity
												? adultsTotal
												: `R$ ${adultsTotal.toLocaleString('pt-BR', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
												  })}`}
											{signed && <span className='label2'> {shortenCoinAlias}</span>}
										</span>
									</div>
								)}
								{childs !== 0 && (
									<div className='subtotais'>
										<span className='label'>{`${childs} Criança(s): `}</span>
										<span className='points'>
											{signed && !user?.guest && !user?.campaign?.disablePointActivity
												? childsTotal
												: `R$ ${childsTotal.toLocaleString('pt-BR', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
												  })}`}
											{signed && !user?.guest && !user?.campaign?.disablePointActivity && (
												<span className='label2'> {shortenCoinAlias}</span>
											)}
										</span>
									</div>
								)}
								{babies !== 0 && (
									<div className='subtotais'>
										<span className='label'>{`${babies} Bebê(s): `}</span>
										<span className='points'>
											{signed && !user?.guest
												? babiesTotal
												: `R$ ${babiesTotal.toLocaleString('pt-BR', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
												  })}`}
											{signed && !user?.guest && !user?.campaign?.disablePointActivity && (
												<span className='label2'> {shortenCoinAlias}</span>
											)}
										</span>
									</div>
								)}
								{fees !== 0 && (
									<div className='subtotais'>
										<span className='label'>{`Taxas: `}</span>
										<span className='points'>
											{signed && !user?.guest
												? fees
												: `R$ ${fees.toLocaleString('pt-BR', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
												  })}`}
											{signed && !user?.guest && !user?.campaign?.disablePointActivity && (
												<span className='label2'> {shortenCoinAlias}</span>
											)}
										</span>
									</div>
								)}
								{/* {signed
                    ? user.pointsBalance > 0 && (
                        <div className='subtotais meus-pontos'>
                          <span className='label'>{`Meus pontos: `}</span>
                          <span className='points'>
                            {user.pointsBalance.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            <span className='label2'> Pts</span>
                          </span>
                        </div>
                      )
                    : ''} */}
								{/* {signed &&
                    totalPriceInPoints *
                      user.catalog.catalogCurrencyConfig.conversionRate >=
                      user.pointsBalance && (
                      <div className='subtotais resgate-valor'>
                        <span className='label'>
                          Use seus pontos e mais R${' '}
                          <span>
                            {(
                              (totalPriceInPoints *
                                user.catalog.catalogCurrencyConfig
                                  .conversionRate -
                                user.pointsBalance) /
                              user.catalog.catalogCurrencyConfig.conversionRate
                            ).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}
                          </span>
                          para resgatar.
                        </span>
                      </div>
                    )} */}
							</div>
						</S.SideContent>
					</>
				)}
			</S.Wrapper>
		);
	};

	return (
		<div>
			<RegisterModal
				open={isOpenRegisterModal}
				onClose={handleRegisterModal}
				travelVoucherUser={user?.travelVoucher}
			/>
			<FareDetails
				open={verTarifa}
				cia={recommendation?.validatingcarrier}
				onClose={openTarifaDetails}
				farecode={journeys[0].segments[0].flights[0].classofservices[0].farefamily}
			/>
			{idaVolta === true ? (
				<>{ida.length === 0 || volta.length === 0 ? <></> : <Content />}</>
			) : (
				<>{ida.length === 0 ? <></> : <Content />}</>
			)}
		</div>
	);
};

export default FlightCard;
