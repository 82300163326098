import styled, { css } from 'styled-components';

export const Modal = styled.div`
  .enabled {
    color: green;
  }

  .disabled {
    color: red;
  }
`;

export const BlankCard = styled.div`
  ${({ theme }) => css`
    height: auto;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
    padding: 12px;
    padding-top: 9px;
    margin-bottom: 40px;
  `}
`;

export const CreditCard = styled.div`
  ${({ theme }) => css``}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    text-align: left;
    font: normal normal bold 15px Poppins;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;

    .subtitle {
      font-size: 11px;
      font-weight: 400;
      margin-left: 10px;
    }

    .subtitle-locator {
      font-size: 12px;
      font-weight: 500;
    }
  `}
`;
