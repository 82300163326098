import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: auto;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #adadad;
    border-radius: 0px;
    padding: 10px;
    margin-bottom: 25px;
    font-family: 'Poppins';

    &:hover {
      border: 1px solid ${theme.palette.primary.main};
      border-radius: 0px;
    }

    &.selected {
      border: 3px solid ${theme.palette.primary.main};
      border-radius: 0px;
    }
  `}
`;

export const RatesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  p {
    font-size: 12px;
    margin: 3px 0;
    color: #404040;
  }
`;

export const LinkToTooltip = styled.span`
  color: green;
  cursor: pointer;
  width: 100%;
  /* text-decoration: underline; */
  margin-top: 5px;
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
