import React from 'react';
import * as S from '../styles';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import {
  parseISO,
  format,
  differenceInCalendarDays,
} from 'date-fns';
import getCompanyLogos from '../../../../../mock/companyLogos';
import FlightStep from '../FlightStep';
import Baggage from '../../../../Baggage';

export const calcDuration = (hours) => {
  var h = String(Math.trunc(hours)).padStart(2, '0');
  var m = String(Math.abs(Math.round((hours - h) * 60))).padStart(2, '0');
  return h + 'h' + m + 'm';
}

const FlightSegmentOption = ({
  flight,
  sourceid,
  validatingcarrier,
  selected,
  setSelected,
  recomendationId,
  sourcecode,
}) => {
  const theme = useTheme();
  const downOfXs = useMediaQuery(theme.breakpoints.down('xs'));
  const code = `${recomendationId}-${flight.segmentid}`;

  const diffDays = differenceInCalendarDays(
    new Date(flight?.arrivaldatetime),
    new Date(flight?.departuredatetime)
  );

  return (
    <S.FlightSegmentOption>
      {downOfXs ? (
        <Grid container>
          <S.GridItemFlex item xs={2}>
            <div className='company-logo'>
              <img src={getCompanyLogos(validatingcarrier)} alt='cia' />
            </div>
            <span className='company-name'> {sourcecode}</span>
            <span className='flight-number'>{`${validatingcarrier} ${flight.flights[0].flightnumber}`}</span>
          </S.GridItemFlex>
          <S.GridItemFlex item xs={2}>
            <span className='departure-date'>
              {format(parseISO(flight.departuredatetime), 'HH:mm')}
            </span>
          </S.GridItemFlex>
          <S.GridItemFlex item xs={6}>
            <FlightStep steps={flight.flights} />
          </S.GridItemFlex>
          <S.GridItemFlex className='select-option' item xs={2}>
            <span className='arrival-date'>
              {format(parseISO(flight.arrivaldatetime), 'HH:mm')}
              <span className='plus-day'>
                {diffDays > 0 ? `+${diffDays}` : ''}
              </span>
            </span>

            <input
              type='radio'
              checked={selected === code ? true : false}
              onChange={() => setSelected(code)}
            />
          </S.GridItemFlex>
        </Grid>
      ) : (
        <Grid container>
          <S.GridItemFlex item lg={1} md={1} sm={1} xs={1}>
            <div className='company-logo'>
              <img src={getCompanyLogos(validatingcarrier)} alt='cia' />
            </div>
          </S.GridItemFlex>
          <S.GridItemFlex item lg={1} md={1} sm={1} xs={1}>
            <span className='company-name'> {sourcecode}</span>
            <span className='flight-number'>{`${validatingcarrier} ${flight.flights[0].flightnumber}`}</span>
          </S.GridItemFlex>
          <S.GridItemFlex item lg={2} md={2} sm={2} xs={2}>
            <span className='departure-date'>
              {format(parseISO(flight.departuredatetime), 'HH:mm')}
            </span>
          </S.GridItemFlex>
          <S.GridItemFlex item lg={3} md={3} sm={3} xs={3}>
            <span className='duracao'>
              {' '}
              {calcDuration(flight.totalDuration)}
            </span>
            <FlightStep steps={flight.flights} />
          </S.GridItemFlex>
          <S.GridItemFlex item lg={2} md={2} sm={2} xs={2}>
            <span className='arrival-date'>
              {format(parseISO(flight.arrivaldatetime), 'HH:mm')}
              <span className='plus-day'>
                {diffDays > 0 ? `+${diffDays}` : ''}
              </span>
            </span>
          </S.GridItemFlex>
          <S.GridItemFlex className='baggage' item lg={2} md={1} sm={1} xs={1}>
            <Baggage baggage={flight.flights[0].fareoption?.baggageallowance} />
          </S.GridItemFlex>
          <S.GridItemFlex
            className='select-option'
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
          >
            <input
              type='radio'
              checked={selected === code ? true : false}
              onChange={() => setSelected(code)}
            />
          </S.GridItemFlex>
        </Grid>
      )}
    </S.FlightSegmentOption>
  );
};

export default FlightSegmentOption;