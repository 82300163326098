// src/components/Legend.js
import React from "react";
import { LegendContainer, LegendItem, LegendColor, LegendColorBusy } from "../styles";

const Legend = () => {
  return (
    <LegendContainer>
      <LegendItem>
        <LegendColor color="#1256CC" /> Livre
      </LegendItem>
      <LegendItem>
        <LegendColor color="#EACE3B" /> Selecionado
      </LegendItem>
      <LegendItem>
        <LegendColorBusy color="#999999" /> Ocupado
      </LegendItem>
    </LegendContainer>
  );
};

export default Legend;
