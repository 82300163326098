import { Container, FormControl } from "@material-ui/core";
import React, { useState } from "react";
import { useGlobal } from "../../../contexts/globalContext";

import { useMediaQuery, useTheme } from "@material-ui/core";

import { Link } from "@mui/material";
import { useHistory } from "react-router";
import { getCampaignColors } from "../../../utils/getCampaignColors";
import { ThemeButton } from "../../ThemeButton/styles";
import Loader from "../../../components/Loader";
import LeadManagementRepository from "../../../repositories/leadRepository";

import { getSessionStorage } from "../../../utils/getSessionStorage";

import FormInput from "../../FormInput";

const NotFoundTrip = () => {
	let history = useHistory();
	const theme = useTheme();
	const downOfSm = useMediaQuery(theme.breakpoints.down("sm"));
	const campaignColors = getCampaignColors();
	const S = require("../styles");
	const linkStyle = campaignColors ? { color: campaignColors.searchHeaderColor || campaignColors.searchColor } : {};
	const inputStyle = {
		width: "auto",
		marginRight: "10px",
	};

	const { showAlert, setLoading, sleep } = useGlobal();

	const [send, setSend] = useState(false);
	const [openLoader, setOpenLoader] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [details, setDetails] = useState("");

	const onSend = async () => {
		setSend(true);

		if (!name || name === "" || !email || email === "" || !phone || phone === "" || !details || details === "") {
			showAlert("error", "Preencha todos os campos obrigatórios", 2000);
			return;
		}

		setLoading(true);
		setOpenLoader(true);

		try {
			const repository = new LeadManagementRepository();
			await repository.create({
				name,
				email,
				phone,
				details,
				channel: "searchResult",
			});
		} catch (error) {
			console.error(error);
			showAlert(
				"error",
				"Ops! Ocorreu um erro ao enviar sua solicitação. Tente novamente mais tarde ou entre em contato com o e-mail abaixo.",
				3000
			);
			setLoading(false);
			setOpenLoader(false);
			return;
		}

		setLoading(false);
		setOpenLoader(false);
		setSend(false);
		showAlert("info", "Sua solicitação foi enviada com sucesso! Em breve entraremos em contato.", 3000);
		await sleep(3000);
		history.push("/");
	};

	React.useEffect(() => {
		const storagedUser = getSessionStorage("@App:user");

		if (storagedUser) {
			setName(storagedUser.name);
			setEmail(storagedUser.email);
		}
	}, []);

	return (
		<Container>
			<Loader openLoader={openLoader} isSaving />
			<S.NotFoundTrip>
				<span>
					Ops! Parece que o destino que você está procurando está em alta e não encontramos disponibilidade no momento. Você pode{" "}
					<Link
						sx={{
							cursor: "pointer",
						}}
						style={linkStyle}
						onClick={() => history.push("/")}
						className="pesquisa"
					>
						refazer a pesquisa
					</Link>
					, mas se preferir, compartilhe um pouco mais sobre suas preferências abaixo, e nossos consultores irão encontrar a opção perfeita
					para tornar a sua viagem inesquecível.
				</span>
			</S.NotFoundTrip>
			<div style={{ backgroundColor: "#ffffff", padding: "5px 20px 5px 20px", borderRadius: 10, marginTop: 20, paddingBottom: 20 }}>
				<S.NotFoundContainerFormTrip>
					<FormControl variant="standard" fullWidth>
						<FormInput
							margin="normal"
							required
							fullWidth
							id="name"
							label="Nome completo"
							name="name"
							placeholder="Preencha com seu nome completo"
							autoFocus
							value={name}
							onChange={(e) => setName(e.target.value)}
							error={send && (!name || name === "")}
							helperText={send && (!name || name === "") ? "Nome não pode ser vazio" : ""}
							style={inputStyle}
						/>
					</FormControl>
					<FormControl variant="standard" fullWidth>
						<FormInput
							mask="(99) 99999-9999"
							margin="normal"
							required
							fullWidth
							id="phone"
							label="Celular"
							name="phone"
							placeholder="Preencha com seu telefone celular"
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
							error={send && (!phone || phone === "")}
							helperText={send && (!phone || phone === "") ? "Celular não pode ser vazio" : ""}
							style={inputStyle}
						/>
					</FormControl>
					<FormControl variant="standard" fullWidth>
						<FormInput
							margin="normal"
							required
							fullWidth
							id="email"
							label="E-mail"
							name="email"
							placeholder="Preencha com seu e-mail"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							error={send && (!email || email === "")}
							helperText={send && (!email || email === "") ? "E-mail não pode ser vazio" : ""}
							style={inputStyle}
						/>
					</FormControl>
				</S.NotFoundContainerFormTrip>
				<S.NotFoundContainerFormTrip
					style={{
						flexDirection: "column",
					}}
				>
					<FormInput
						margin="normal"
						required
						fullWidth
						multiline
						rows="7"
						id="details"
						label="Conte-nos um pouco sobre o que deseja"
						name="details"
						placeholder="Informe sua cidade de origem, destino, data, quantidade de passageiros, entre outro itens."
						value={details}
						onChange={(e) => setDetails(e.target.value)}
						error={send && (!details || details === "")}
						helperText={send && (!details || details === "") ? "Detalhes da viagem não pode ser vazio" : ""}
					/>
					<ThemeButton
						className="pesquisa"
						style={{
							width: downOfSm ? "50%" : "30%",
							height: "100%",
							marginTop: "10px",
						}}
						onClick={onSend}
					>
						Enviar
					</ThemeButton>
				</S.NotFoundContainerFormTrip>
			</div>
		</Container>
	);
};

export default NotFoundTrip;
