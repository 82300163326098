import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

import Home from "../pages/Home";
import Account from "../pages/Account";
import Search from "../pages/Search";
import Auth from "../pages/Auth";
import Guest from "../pages/Guest";
import SessionExpiration from "../pages/SessionExpiration";
import Header from "../components/Header";
import LinkHeader from "../components/LinkHeader";
import Footer from "../components/Footer";
import FooterPorto from "../components/FooterPorto";
import { getBrandStorage } from "../utils/getBrand";
import Login from "../pages/Login";
import { useGlobal } from "../contexts/globalContext";
import PlanYourTrip from "../pages/PlanYourTrip";

const OtherRoutes = () => {
	const { signed, user } = useGlobal();
	const isGuestPath = useLocation().pathname.toLowerCase() === "/guest";

	const getSignedRoutes = (signed, user) => {
		if (signed && user.campaign?.campaignConfigLayout.headers) {
			return (
				<>
					{user.campaign?.campaignConfigLayout.headers?.map((menu) => {
						return (
							menu.link.substring(0, 4) !== "http" && (
								<Route exact path={"/" + menu.link} key={menu.id}>
									<div style={{ backgroundColor: "#FFF" }}>
										<Header />
										<LinkHeader img={menu.link} alt={menu.nome} />
										{getBrandStorage() === "portoplus" ? <FooterPorto /> : <Footer />}
									</div>
								</Route>
							)
						);
					})}
					<Route path="/conta" component={Account} />
					<Route path="/busca" component={Search} />
					<Route path="/plantrip" component={PlanYourTrip} />
				</>
			);
		} else {
			return <></>;
		}
	};

	const getGuestRoutes = (signed, isGuestPath) => {
		if (!signed && isGuestPath) {
			return <Redirect exact to="/guest" />;
		} else {
			return <></>;
		}
	};

	const getLoginRedirect = (signed, isGuestPath) => {
		if (!signed && !isGuestPath) {
			return <Redirect exact to="/login" />;
		} else {
			return <></>;
		}
	};

	return (
		<>
			<Route path="/login" component={Login} />
			<Route path="/session-expires" component={SessionExpiration} />
			<Route path="/auth/callback" component={Auth} />
			<Route path="/guest" component={Guest} />
			<Route exact path="/" component={Home} />
			{getSignedRoutes(signed, user)}
			{getGuestRoutes(signed, isGuestPath)}
			{getLoginRedirect(signed, isGuestPath)}
		</>
	);
};

export default OtherRoutes;
