import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import TicketActivity from "./TicketActivity";
import TourActivity from "./TourActivity";
import TransferActivity from "./TransferActivity";
import { getSessionStorage } from "../../utils/getSessionStorage";

function EntertainmentDetails() {
    const [activity, setActivity] = useState({});

    useEffect(() => {
        const selectedActivity = getSessionStorage("@EntertainmentSelected");
        if (selectedActivity && selectedActivity.activity) {
            setActivity(selectedActivity.activity);
        } else {
            console.error("Nenhuma atividade encontrada");
        }
    }, []);

    const isActivityValid = (activityId) => {
        return activityId && !activityId.endsWith("_0");
    };

    return (
        <div>
            {activity ? (
                <>
                    {activity.ticket &&
                        isActivityValid(activity.ticket.activityId) && (
                            <TicketActivity
                                activity={activity.ticket}
                            />
                        )}
                    {activity.tour &&
                        isActivityValid(activity.tour.activityId) && (
                            <TourActivity
                                activity={activity.tour}
                            />
                        )}
                    {activity.transfer &&
                        isActivityValid(activity.transfer.activityId) && (
                            <TransferActivity
                                activity={activity.transfer}
                            />
                        )}
                </>
            ) : (
                <div>Nenhuma atividade encontrada</div>
            )}
        </div>
    );
}

export default withRouter(EntertainmentDetails);
