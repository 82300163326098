import React, { useEffect, useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
// import { useHistory } from 'react-router-dom';

import * as S from "./styles";
import {
	// Checkbox,
	// FormControlLabel,
	Grid,
	Typography,
} from "@material-ui/core";
import FormInput from "../FormInput";

import { useGlobal } from "../../contexts/globalContext";

// import Loader from '../Loader';
import UserRepository from "../../repositories/userRepository";

function ResetPasswordFirstAccessModal({ user }) {
	const { showAlert, logout, login } = useGlobal();
	// let history = useHistory();

	const userRepository = new UserRepository();
	const [state, setState] = useState("");
	const [error, setError] = useState("");
	// const [openLoader, setOpenLoader] = useState(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (user?.firstAccess) {
			setOpen(true);
		}
	}, [user?.firstAccess]);

	useEffect(() => {
		if (state.newPassword !== "" && state.newPasswordConfirm !== "") {
			if (state.newPassword !== state.newPasswordConfirm) {
				setError("Senhas não conferem, verifique!");
			} else setError("");
		} else setError("");
	}, [state]);

	const confirmChangePassword = () => {
		userRepository
			.changePasswordFirstAccess(state.newPassword)
			.then((res) => {
				showAlert("success", "Senha alterada com sucesso!");
				setOpen(false);
				login();
			})
			.catch((err) => {
				console.error("err", err?.toJSON()?.message);
				if (err?.toJSON()?.message) setError(err?.toJSON()?.message);
			});
	};

	const onChangeField = (e) => {
		setState({ ...state, [e.target.name]: e.target.value });
		setError("");
	};

	return user?.firstAccess ? (
		<S.Modal>
			<Dialog
				open={open}
				// onClose={() => onClose(false)}
			>
				<DialogTitle
					id="customized-dialog-title"
					// onClose={() => setOpen(false)}
				>
					Primeiro acesso
				</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Typography variant="span">Por segurança, é necessário alterar sua senha no primeiro acesso, digite abaixo:</Typography>
						</Grid>

						<Grid item xs={12}>
							<FormInput placeholder="Nova senha" type="password" onChange={onChangeField} name="newPassword" />
						</Grid>
						<Grid item xs={12}>
							<FormInput placeholder="Confirma nova senha" onChange={onChangeField} type="password" name="newPasswordConfirm" />
						</Grid>
					</Grid>
					{error && (
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography variant="span" color="error">
									{error}
								</Typography>
							</Grid>
						</Grid>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							logout();
						}}
						color="primary"
					>
						Cancelar
					</Button>

					<Button
						onClick={() => {
							confirmChangePassword();
						}}
						color="primary"
						disabled={error !== "" || state.newPassword === "" || state.newPasswordConfirm === ""}
					>
						Alterar senha
					</Button>
				</DialogActions>
			</Dialog>
		</S.Modal>
	) : (
		<></>
	);
}

export default ResetPasswordFirstAccessModal;
