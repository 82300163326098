import { Typography, ButtonBase } from "@material-ui/core";
import * as S from "./styles";
import { RightArrow } from "../../../../assets/icons";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useGlobal } from "../../../../contexts/globalContext";
import cleanStorageBeforeSearch from "../../../../utils/cleanStorageBeforeSearch";

const SearchResults = ({
    activities,
    locations,
    submit,
    showResults,
    handleCheckLocationAvailability,
    loading,
}) => {
    let history = useHistory();
    const { setLoading } = useGlobal();
    const [adultPrice, setAdultPrice] = useState({});

    const calculatePrices = (activities) => {
        const prices = {};

        activities.forEach((activity) => {
            let adultPrice = null;

            if (activity.ticketPassengerRate) {
                adultPrice = activity.pointsPrice;
            } else if (activity.tourPassengerRate) {
                adultPrice = activity.pointsPrice;
            } else if (activity.transferPassengerRate) {
                adultPrice = activity.pointsPrice;
            }

            if (adultPrice !== null) {
                prices[activity.activityId] = adultPrice;
            }
        });

        return prices;
    };

    useEffect(() => {
        if (activities && activities.length > 0) {
            const calculatedPrices = calculatePrices(activities);
            setAdultPrice(calculatedPrices);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activities]);

    useEffect(() => {
        setLoading(loading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    if (!showResults && !loading) {
        return null;
    }

    const handleSelectActivity = (activity) => {
        let passengerRate = null;

        if (activity.ticketPassengerRate) {
            passengerRate = activity.ticketPassengerRate;
        } else if (activity.tourPassengerRate) {
            passengerRate = activity.tourPassengerRate;
        } else if (activity.transferPassengerRate) {
            passengerRate = activity.transferPassengerRate;
        }

        cleanStorageBeforeSearch();
        sessionStorage.setItem(
            "entertainmentSearch",
            JSON.stringify({
                selectedCity: activity.locationFull,
                activities: activities,
                locations,
            })
        );
        sessionStorage.setItem(
            "@EntertainmentSelected",
            JSON.stringify({
                activity,
                passengerRate,
            })
        );
        sessionStorage.setItem("buscaAtual", "Entretenimento");
        history.push("/busca/entertainment-details", {
            activity,
            passengerRate,
        });
    };

    return loading ? (
        <S.Box>
            <S.CustomTypography className="searchTitle" variant="h6">
                Carregando as opções...
            </S.CustomTypography>
        </S.Box>
    ) : (
        <>
            <S.Box>
                {!locations?.length && !activities?.length ? (
                    <S.CustomTypography className="searchTitle" variant="h6">
                        Nenhuma opção encontrada
                    </S.CustomTypography>
                ) : (
                    ""
                )}

                {locations?.length ? (
                    <>
                        <div
                            className="allResults"
                            onClick={() => submit("Todos os resultados")}
                        >
                            <div>
                                <Typography>Ver todos os resultados</Typography>
                            </div>
                            <S.StyledArrowForwardIcon className="allResultsIcon" />
                        </div>
                    </>
                ) : activities?.length ? (
                    <>
                        <div
                            className="allResults"
                            onClick={() => submit("Todos os resultados")}
                        >
                            <div>
                                <Typography>Ver todos os resultados</Typography>
                            </div>
                            <S.StyledArrowForwardIcon className="allResultsIcon" />
                        </div>
                    </>
                ) : (
                    <></>
                )}

                {locations?.length ? (
                    <>
                        <Typography className="searchTitle">Locais</Typography>
                        {[
                            ...new Map(
                                locations?.map((location) => [
                                    location.location,
                                    location,
                                ])
                            ).values(),
                        ].map((location) => (
                            <div key={location.id}>
                                <Typography
                                    onClick={() => {
                                        handleCheckLocationAvailability(
                                            location.routeDestiny,
                                            location.name
                                        );
                                    }}
                                    className="foundCities"
                                >
                                    <RightArrow className="rightArrow" />{" "}
                                    {location.location}
                                </Typography>
                            </div>
                        ))}
                    </>
                ) : activities?.length ? (
                    <>
                        <Typography className="searchTitle">Locais</Typography>
                        {[
                            ...new Map(
                                activities?.map((activity) => [
                                    activity.locationFull,
                                    activity,
                                ])
                            ).values(),
                        ].map((activity) => (
                            <div key={activity.activityId}>
                                <Typography
                                    onClick={() => {
                                        handleCheckLocationAvailability(
                                            activity.routeCities,
                                            activity.locationFull
                                        );
                                    }}
                                    className="foundCities"
                                >
                                    <RightArrow className="rightArrow" />{" "}
                                    {activity.locationFull}
                                </Typography>
                            </div>
                        ))}
                    </>
                ) : (
                    <></>
                )}

                {activities?.length > 0 && (
                    <>
                        <Typography className="searchTitle">
                            Atividades
                        </Typography>
                        {activities.slice(0, 4).map((activity) => (
                            <div
                                className="card"
                                key={activity.activityId}
                                onClick={() => handleSelectActivity(activity)}
                            >
                                <ButtonBase className="cardMedia">
                                    <img
                                        alt={activity.name}
                                        src={activity.pictureUrl}
                                        className="cardImage"
                                    />
                                </ButtonBase>
                                <div className="cardContent">
                                    <Typography className="cardTitle">
                                        {activity.name.length > 90
                                            ? `${activity.name.substring(
                                                0,
                                                80
                                            )}...`
                                            : activity.name}
                                    </Typography>
                                    <Typography className="cardCity">
                                        {activity.location}
                                    </Typography>
                                    <Typography className="cardPrice">
                                        <span className="text">
                                            A partir de&nbsp;
                                        </span>
                                        {adultPrice[activity.activityId] ||
                                            "N/A"}
                                        {" pts"}
                                    </Typography>
                                </div>
                                <S.StyledArrowForwardIcon />
                            </div>
                        ))}
                    </>
                )}
            </S.Box>
        </>
    );
};

export default SearchResults;
