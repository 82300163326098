import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import * as S from "./styles";

const ControllableCounter = ({ count, onIncrement, onDecrement, isAdult }) => {
  const handleDecrement = () => {
    if (!isAdult || count > 1) {
      onDecrement();
    }
  };

  return (
    <ButtonGroup size="small" aria-label="small outlined button group">
      <S.DecrementButton onClick={handleDecrement} disabled={count === 0}>
        -
      </S.DecrementButton>
      <S.CounterDisplay disabled>{count}</S.CounterDisplay>
      <S.IncrementButton onClick={onIncrement}>+</S.IncrementButton>
    </ButtonGroup>
  );
};

export default ControllableCounter;
