import React from 'react';
import { useEffect, useState } from 'react';
import * as S from './styles';
// import CheckboxFilter from '../../CheckboxFilter';
// import { Slider } from '@material-ui/core';
// import CurrencyFormat from 'react-currency-format';
import { Skeleton } from '@material-ui/lab';

const ExperienceFilter = ({
  changeFilter,
  searchState,
  results,
  loading,
  error,
  buscaAtual,
}) => {
  const [state, setState] = useState({
    carType: [],
    rentalCias: [],
  });
  // const [value, setValue] = useState([0, 1000]);
  // const [amount, setAmount] = useState([0, 500]);
  // const [rentalCias, setRentalCias] = useState([]);
  // const [carTypes, setCarTypes] = useState([]);

  // const onChangeFilters = (e) => {
  //   setState({ ...state, [e.target.name]: e.target.value });
  // };

  useEffect(() => {
    changeFilter(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, setState]);

  // useEffect(() => {
  //   setAmount([0, Math.max.apply(null, [100, 400, 500])]);
  //   setValue([0, Math.max.apply(null, [100, 400, 500])]);
  // }, [results]);

  // useEffect(() => {
  //   setState({
  //     ...state,
  //     amount: {
  //       valorInicial: amount[0],
  //       valorFinal: amount[1],
  //     },
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [amount]);

  return !results ? (
    loading && <Skeleton variant='rect' width={'100%'} height={1000} />
  ) : (
    <div>
      {!error && (
        <S.Wrapper>
          {/* <div className='filter-group'>
          <S.FilterTitle>Locadoras</S.FilterTitle>
          {rentalCias.map((cbx, index) => (
            <CheckboxFilter
              key={index}
              label={cbx.label}
              name={cbx.name}
              disabled={cbx.disabled}
              valueLabel={cbx.value}
              onChange={(obj) => changeRentalCias(obj)}
            />
          ))}
        </div>
        <div className='filter-group'>
          <S.FilterTitle>Tipos de Carros</S.FilterTitle>
          {carTypes.map((cbx, index) => (
            <CheckboxFilter
              key={index}
              label={cbx.label}
              disabled={cbx.disabled}
              name={cbx.name}
              valueLabel={cbx.value}
              onChange={(obj) => changeCarTypes(obj)}
            />
          ))}
        </div> */}
          <div className='filter-group'>
            {/* <S.FilterTitle>Filtrar valor</S.FilterTitle>
            <S.ThinText>
              <CurrencyFormat
                value={amount[0]}
                displayType={'text'}
                decimalSeparator=','
                decimalScale={2}
                prefix={'R$ '}
              />
              -
              <CurrencyFormat
                value={amount[1]}
                displayType={'text'}
                decimalSeparator=','
                decimalScale={2}
                prefix={'R$ '}
              />
            </S.ThinText>

            <Slider
              value={amount}
              onChange={(event, newValue) => setAmount(newValue)}
              valueLabelDisplay='off'
              aria-labelledby='range-slider'
              min={0}
              max={value[1]}
            /> */}
          </div>
        </S.Wrapper>
      )}
    </div>
  );
};

export default ExperienceFilter;
