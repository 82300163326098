import api from "../services/apiHub";
import getHeaders from "../utils/returnHeadersHub";
import deburr from "lodash/deburr";

export default class BusRepository {
	getBusStations = async (term) => {
		const header = getHeaders();
		const findResult = deburr(term).toLowerCase();

		const body = {
			filter: {
				name: findResult,
			},
		};
		return api.post(`/api/services/bus/auto-complete`, JSON.stringify(body), header);
	};

	getBus = async ({ DataIda, DataVolta, Origem, Destino, passageiros }) => {
		const headers = getHeaders().headers;
		const origemId = Origem.id;
		const destinoId = Destino.id;

		const body = {
			filter: {
				start: DataIda,
				end: DataVolta,
				origin: origemId,
				destination: destinoId,
				occupancy: passageiros,
			},
		};

		const response = await api.post("/api/services/bus/search", body, { headers });

		const busAvailability = {
			busAvailability: response.data,
		};

		return busAvailability;
	};

	checkBusRate = async (data) => {
		const header = getHeaders();

		const body = {
			filter: {
				goingBus: data.goingBus,
				goingBusRefToken: data.goingBusRefToken,
				returnBus: data.returnBus,
				returnBusRefToken: data.returnBusRefToken,
				occupancy: data.occupancy,
			},
		};

		return api.post("/api/services/bus/get", body, header);
	};

	postBusBooking = async (data) => {
		const header = getHeaders();
		const body = { data };

		return api.post("/api/services/bus/booking", body, header);
	};

	getBooking = async (data) => {
		const header = getHeaders();
		return api.get(`/api/bus/find`, JSON.stringify(data), header);
	};
}
