import { Button } from "@material-ui/core";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import { getBrandStorage } from "../../utils/getBrand";
import { isDiferentiatedBrands } from "../../themes/themes";
import { getCampaignColors } from "../../utils/getCampaignColors";

const brand = getBrandStorage();
const campaignColors = getCampaignColors();

let ResultButton = styled(Button)`
	${({ theme }) => css`
  font-family: 'Poppins';
  text-transform: initial;

  &.button-unstyled {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  &.go-and-back {
    color: ${
					isDiferentiatedBrands(brand) ? theme.palette.secondary.white : '#FFF'
				};
    height: auto;
    width: 130px;
    margin-right: 20px;

    ${theme.breakpoints.down('sm')} {
      font-size: 13px;
    }

    &.active {
      background-color: ${rgba(
							isDiferentiatedBrands(brand) ? theme.palette.secondary.white : '#FFF',
							0.29
						)} !important;
      font-weight: 600;
    }
  }

  &.reprocessar {
    background-color: none;
    color: #002696;
    height: 25px;
  }

  &.submit1 {
    background-color: ${
					isDiferentiatedBrands(brand) ? theme.palette.secondary.white : '#FFF'
				};
    color: ${
					campaignColors.searchColor
						? campaignColors.searchColor
						: theme.palette.secondary.main
				};};
    height: 57px;
    width: 100%;
  }

  &.pesquisa {
    /* background-color: ${theme.palette.primary.main}; */
    background-color: ${
					campaignColors.searchColor
						? campaignColors.searchColor
						: theme.palette.primary.main
				};};
    color: #fff;
    height: 70px;
    width: 300px;
  }

  &.login {
    margin: 20px 0px;
    background-color: ${
					campaignColors.searchColor
						? campaignColors.searchColor
						: theme.palette.primary.main
				};
    color: #fff;
    height: 50px;
    width: 100%;
  }

  &.button-select {
    position: unset;
    background-color: #1256cc;
    color: ${
					isDiferentiatedBrands(brand) ? theme.palette.secondary.white : '#FFF'
				};
    height: 33px;
    width: 100px;
    font-size: 10px;
    margin: 10px;
  }

  &.payment {
    background-color: ${
					campaignColors.searchColor
						? campaignColors.searchColor
						: theme.palette.primary.main
				};
    color: ${
					isDiferentiatedBrands(brand) ? theme.palette.secondary.white : '#FFF'
				};
    height: 57px;
    width: 100%;
    border-radius: 6px;

    &.disabled {
      background-color: #707070;
    }

    &.coupon {
      height: 100%;
    }
  }

  &.close {
    background-color: ${
					isDiferentiatedBrands(brand) ? theme.palette.secondary.white : '#FFF'
				};
    color:${
					campaignColors.searchColor
						? campaignColors.searchColor
						: theme.palette.primary.main
				}; 
    height: 57px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid;
    border-color: ${
					campaignColors.searchColor
						? campaignColors.searchColor
						: theme.palette.primary.main
				};
    &.disabled {
      background-color: #707070;
    }

    &.coupon {
      height: 100%;
    }
  }

  &.choose-hotel {
    background-color: #18acf2;
    color: ${
					isDiferentiatedBrands(brand) ? theme.palette.secondary.white : '#FFF'
				};
    height: 57px;
    width: 100%;
    border-radius: 6px;
    margin-top: 15px;
    &.disabled {
      background-color: #707070;
    }
    :hover {
      opacity: 90%;
    }
  }

  &.select-hotel-rate {
    background-color: #fafafa;
    color: #1256cc;
    height: 30px;
    width: 100%;
    border-radius: 6px;

    &.disabled {
      background-color: #707070;
    }
    :hover {
      opacity: 90%;
    }
  }

  &.payment-hotel {
    background-color: #18acf2;
    color: ${
					isDiferentiatedBrands(brand) ? theme.palette.secondary.white : '#FFF'
				};
    height: 57px;
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &.choose-entertainment {
    background-color: ${
					campaignColors.searchColor
						? campaignColors.searchColor
						: theme.palette.primary.main
				};
    color: ${
					isDiferentiatedBrands(brand) ? theme.palette.secondary.white : '#FFF'
				};
    height: 57px;
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    &.disabled {
      background-color: #707070;
    }
    :hover {
      opacity: 90%;
    }
  }
  
  &.choose-experience {
  background-color: #18acf2;
  color: ${
			isDiferentiatedBrands(brand) ? theme.palette.secondary.white : '#FFF'
		};
  height: 57px;
  width: 100%;
  border-radius: 6px;
  margin-top: 15px;
  &.disabled {
    background-color: #707070;
  }
  :hover {
    opacity: 90%;
  }
  }
`}
`;


export function ThemeButton(props) {
	return <ResultButton {...props} />;
}

// export const ThemeButton = () => {
// 	return styled(Button)`
// 		${({ theme }) => css`
//     font-family: 'Poppins';
//     text-transform: initial;

//     &.button-unstyled {
//       background: none;
//       color: inherit;
//       border: none;
//       padding: 0;
//       font: inherit;
//       cursor: pointer;
//       outline: inherit;
//     }

//     &.go-and-back {
//       color: ${isDiferentiatedBrands(brand) ? theme.palette.secondary.white : "#FFF"};
//       height: auto;
//       width: 130px;
//       margin-right: 20px;

//       ${theme.breakpoints.down("sm")} {
//         font-size: 13px;
//       }

//       &.active {
//         background-color: ${rgba(isDiferentiatedBrands(brand) ? theme.palette.secondary.white : "#FFF", 0.29)} !important;
//         font-weight: 600;
//       }
//     }

//     &.reprocessar {
//       background-color: none;
//       color: #002696;
//       height: 25px;
//     }

//     &.submit1 {
//       background-color: ${isDiferentiatedBrands(brand) ? theme.palette.secondary.white : "#FFF"};
//       color: ${campaignColors.searchColor ? campaignColors.searchColor : theme.palette.secondary.main};};
//       height: 57px;
//       width: 100%;
//     }

//     &.pesquisa {
//       /* background-color: ${theme.palette.primary.main}; */
//       background-color: ${campaignColors.searchColor ? campaignColors.searchColor : theme.palette.primary.main};};
//       color: #fff;
//       height: 70px;
//       width: 300px;
//     }

//     &.login {
//       margin: 20px 0px;
//       background-color: ${campaignColors.searchColor ? campaignColors.searchColor : theme.palette.primary.main};
//       color: #fff;
//       height: 50px;
//       width: 100%;
//     }

//     &.button-select {
//       position: unset;
//       background-color: #1256cc;
//       color: ${isDiferentiatedBrands(brand) ? theme.palette.secondary.white : "#FFF"};
//       height: 33px;
//       width: 100px;
//       font-size: 10px;
//       margin: 10px;
//     }

//     &.payment {
//       background-color: ${campaignColors.searchColor ? campaignColors.searchColor : theme.palette.primary.main};
//       color: ${isDiferentiatedBrands(brand) ? theme.palette.secondary.white : "#FFF"};
//       height: 57px;
//       width: 100%;
//       border-radius: 6px;

//       &.disabled {
//         background-color: #707070;
//       }

//       &.coupon {
//         height: 100%;
//       }
//     }

//     &.choose-hotel {
//       background-color: #18acf2;
//       color: ${isDiferentiatedBrands(brand) ? theme.palette.secondary.white : "#FFF"};
//       height: 57px;
//       width: 100%;
//       border-radius: 6px;
//       margin-top: 15px;
//       &.disabled {
//         background-color: #707070;
//       }
//       :hover {
//         opacity: 90%;
//       }
//     }

//     &.select-hotel-rate {
//       background-color: #fafafa;
//       color: #1256cc;
//       height: 30px;
//       width: 100%;
//       border-radius: 6px;

//       &.disabled {
//         background-color: #707070;
//       }
//       :hover {
//         opacity: 90%;
//       }
//     }

//     &.payment-hotel {
//       background-color: #18acf2;
//       color: ${isDiferentiatedBrands(brand) ? theme.palette.secondary.white : "#FFF"};
//       height: 57px;
//       width: 100%;
//       border-top-left-radius: 0px;
//       border-top-right-radius: 0px;
//       border-bottom-left-radius: 6px;
//       border-bottom-right-radius: 6px;
//     }
//   `}
// 	`;
// };
