import React from "react";
import Lottie from "lottie-react";
import { replaceColor } from "lottie-colorify";
import busLoader from "../../../../../../assets/animations/bus-loader.json";
import { getCampaignColors } from "../../../../../../utils/getCampaignColors";

const BusLoader = () => {
	const [loaderData, setLoaderData] = React.useState(null);

	React.useEffect(() => {
		const loaderFixedColor1 = "#BBBBBB";
		const loaderFixedColor2 = "#333333";

		const campaignColors = getCampaignColors();
		let loaderColor1 = campaignColors.searchHeaderColor;
		let loaderColor2 = campaignColors.searchColor;
		if (!loaderColor1 || loaderColor1.length < 7) {
			loaderColor1 = loaderFixedColor1;
		} else if (loaderColor1.length > 7) {
			loaderColor1 = loaderColor1.slice(0, 7);
		}

		if (!loaderColor2 || loaderColor2.length < 7) {
			loaderColor2 = loaderFixedColor2;
		} else if (loaderColor2.length > 7) {
			loaderColor2 = loaderColor2.slice(0, 7);
		}

		let loader = replaceColor(loaderFixedColor1, loaderColor1, busLoader);
		loader = replaceColor(loaderFixedColor2, loaderColor2, loader);

		setLoaderData(loader);
	}, []);

	return (
		<>
			{loaderData && (
				<Lottie
					animationData={loaderData}
					loop={true}
					background="transparent"
					speed="1"
					style={{ width: "100%", height: "100%" }}
					autoplay
				></Lottie>
			)}
		</>
	);
};

export default BusLoader;
